/* eslint-disable jsx-a11y/img-redundant-alt */
import { Link } from "react-router-dom";

import { toAbsoluteUrl } from "../../../helpers";

import Services from "../../../../Services";
import { useEffect, useState } from "react";
import Constant from "../../../../Constant";
import { useIntl } from "react-intl";

/* eslint-disable jsx-a11y/anchor-is-valid */
const AsideToolbar = () => {
  const intl = useIntl();
  const [profile, setProfile] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const GET_USER = async () => {
    try {
      const response: any = await Services.User.GET_USER();

      setFirstName(response.data.data.firstName);
      setLastName(response.data.data.lastName);

      setProfile(response.data.data.profile);
    } catch (error) {}
  };

  useEffect(() => {
    GET_USER();
  }, []);

  return (
    <>
      <div className="aside-user d-flex align-items-sm-center justify-content-center py-5">
        <div className="symbol symbol-50px">
          {profile ? (
            <img
              crossOrigin="anonymous"
              src={`${Constant.BASE_URL_UPLOADS}${profile}`}
              alt="Gold Send"
            />
          ) : (
            <img
              src={toAbsoluteUrl("/media/logos/userimage.jpeg")}
              alt="Default Profile Image"
            />
          )}
        </div>

        <div className="aside-user-info flex-row-fluid flex-wrap ms-5">
          <div className="d-flex">
            <div className="flex-grow-1 me-2 mt-3">
              <Link
                to="#"
                className="text-white text-hover-primary fs-6 fw-bold "
              >
                <p>
                  {intl.formatMessage({ id: "hey" })},&nbsp;
                  {firstName.length > 15
                    ? firstName.substring(0, 15) + ".."
                    : firstName}{" "}
                  &nbsp;
                  {lastName.length > 15
                    ? lastName.substring(0, 15) + ".."
                    : lastName}{" "}
                  {intl.formatMessage({ id: "welcome" })}{" "}
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AsideToolbar };
