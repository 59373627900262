/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import Services from "../../../Services";
import moment from "moment";
import { Link } from "react-router-dom";

export default function BankDetails() {
  const [search, setSearch] = useState("");
  const [statuss, setStatus] = useState<any>("");
  const [updatedAt, setUpdatedAt] = useState<any>("");
  const [id, setID] = useState("");

  const getbankdetails = async () => {
    try {
      const response: any = await Services.Wallet.GET_ADMIN_BANKDETAILS();

      setStatus(response?.data?.data?.status);
      setUpdatedAt(response?.data?.data?.updatedAt);
      setID(response?.data?.data?.id);
    } catch(error) {console.log(error);}
  };
  useEffect(() => {
    getbankdetails();
  }, []);

  return (
    <div>
      <div className="p-5  bg-white rounded container col-lg-12">
        <div className=" w-100">
          <h2 className="text-center p-2">Bank Details</h2>
          <div>
            <table className="table-hover table table-striped  table align-middle  fs-6 gy-7 gx-8  ">
              <thead data-aos="thead-dark ">
                <tr className="text-center text-muted fw-bold fs-7 gs-0 ">
                  <th className="min-w-150px text-dark">Type</th>
                  <th className="min-w-150px text-dark">Created At </th>
                  <th className="min-w-150px text-dark ">Action</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-semibold ">
                <tr className="text-center">
                  <td>
                    {(statuss === true && (
                      <span className="text-success">Active</span>
                    )) ||
                      (statuss === false && (
                        <span className="text-danger">Deactive</span>
                      ))}
                  </td>
                  <td>
                    <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                      {moment(updatedAt).format("MMM DD, YYYY hh:mm A")}
                    </span>
                  </td>
                  <td>
                    <div className="me-5">
                      <Link to="/viewbankdetails">
                        <i className="cursor fs-2 fa-solid fa-eye text-warning"></i>
                      </Link>
                      <Link to={`/editbankdetails/${id}`}>
                        <i className="ms-2 position-fixed text-success fs-3 text-center bi bi-pencil-square"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>
    </div>
  );
}
