import { Link } from "react-router-dom";
import styles from "../../scss/Card.module.scss";
import { useIntl } from "react-intl";

export default function ConfirmExchange() {
  const intl = useIntl();

  return (
    <div
      className=""
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <div className="d-flex">
        <div>
          <Link to="/move">
            <i className="fs-1 bi bi-arrow-left-circle-fill"></i>
          </Link>
        </div>
        <h2 className={styles.head}>
          {" "}
          {intl.formatMessage({ id: "confirm" })}
        </h2>
      </div>

      <div className="mt-10 bg-white shadow-sm p-4 mb-5 bg-body-tertiary rounded">
        <p className="text-center mt-10 fs-3">
          {" "}
          {intl.formatMessage({
            id: "pleaseReviewTheDetailSandConfirmYourExchange",
          })}
        </p>
        <div className="container col-lg-12">
          <div className="d-flex justify-content-between align-items-start mt-20">
            <div className="">
              {" "}
              <span>From</span>
            </div>

            <div className="">
              {" "}
              <span className="fw-bolder">Debit Card</span> <br />
              <span> 452145*********5464</span>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-start mt-10">
            <div className="">
              {" "}
              <span>To</span>
            </div>

            <div className="">
              {" "}
              <span className="fw-bolder">
                {intl.formatMessage({ id: "gsGoldAccount" })}{" "}
              </span>{" "}
              <br />
              <span> 452145*********5464</span>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-start mt-10">
            <div className="">
              {" "}
              <span>{intl.formatMessage({ id: "AMOUNT" })}</span>
            </div>

            <div className="">
              {" "}
              <span className="fw-bolder">00.00</span> <br />
              <span> 00.00</span>
            </div>
          </div>

          <div className="mt-10 mb-10 d-flex justify-content-between">
            <Link className="col-4" to="/move">
              {" "}
              <button className="btn btn-warning ">Cancel Exchange</button>
            </Link>
            <Link className="" to="/Transactionsuccessfull">
              {" "}
              <button className="btn btn-warning ">Finish Exchange</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
