import React from "react";

function Spinner(props) {
  return (
    <div className="spinnerWrapper position-fixed w-100 h-100 top-50 start-50 end-50 z-index-3">
      <div className="spinner-border  text-primary" role="status">
        <span className="visually-hidden">
          {props.text ? props.text : " Loading..."}
        </span>
      </div>
    </div>
  );
}

export default Spinner;