/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Services from "../Services";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { useIntl } from "react-intl";


export default function MakeWithdraw() {
  const intl = useIntl()
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [total_list, setTotal_list] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1); 
 
  const [totalCount, setTotalCount] = useState(0);





  const handlePageClick = (event) => {
    const crrPage = event.selected + 1;
    setCurrentPage(crrPage);
    setPage(crrPage);
};

  const getPrimaryData = async () => {
    let object: any = {
      page: page,
      limit: rows,
      search: search
    };
    let payload: any = new URLSearchParams(object).toString();
    try {
      const response: any =
        await Services.Transaction.GET_USER_WITHDRAW_TRANSACTIONS(payload);
     setData(response?.data?.data?.rows);
      setTotalCount(response?.data?.data?.count / rows);
      setTotal_list(response?.data?.data?.count);


    } catch (error) {
      console.log(error)

    }
  };

  useEffect(() => {
    getPrimaryData();

  }, [search, page, rows]);

  return (
    <>

      <div className="card " >
        <h1 className='text-center mt-5'>{intl.formatMessage({id: 'withdrawalList'})}</h1>
        <div className="card-title">

        </div>

        <div className="card-body ">
          <div className='card-toolbar ' >
            <Link to="/withdrawalpopup">
              <button type="button" className="btn btn-sm  btn-warning float-end">

              {intl.formatMessage({id: 'withdrawal'})}
              </button>
            </Link>
          </div>
          <table
            className=" bg-white table align-middle table-row-dashed fs-6 gy-7 gx-8 table-responsive responsive  bg-body-tertiary rounded"
            id="kt_table_users"
          >
            <div className="card-title">
              <div className="d-flex align-items-center position-relative my-1"></div>
            </div>

            <thead data-aos="fade-left thead-dark">
              <tr className="text-center text-muted fw-bold fs-7 text-uppercase gs-0 ">
                <th className="min-w-200px text-dark">{intl.formatMessage({id: 'AMOUNT'})}</th>
                <th className="min-w-300px text-dark ">{intl.formatMessage({id: 'notes'})} </th>
                <th className="min-w-300px text-dark ">{intl.formatMessage({id: 'adminNotes'})}</th>
                <th className="min-w-200px text-dark ">{intl.formatMessage({id: 'date&Time'})}</th>
                <th className="min-w-175px text-dark ">{intl.formatMessage({id: 'status'})}</th>
              </tr>
          
            </thead>
            <tbody className="text-gray-600" data-aos="fade-right  ">
              {data && data?.map((item: any, index) => {
                return (
                  <tr className="text-center" key={index}>

                    <td>{item.amount}</td>

                    <td  style={{
                                whiteSpace: "pre-wrap", 
                                wordWrap: "break-word", 
                                overflowWrap: "break-word",
                                maxWidth: "10ch",
                              }}>
                      {item.userNotes.length > 100 ? item.userNotes.substring(0, 100) + ".." : item.userNotes}

                    </td>
                    <td  style={{
                                whiteSpace: "pre-wrap", 
                                wordWrap: "break-word", 
                                overflowWrap: "break-word",
                                maxWidth: "10ch",
                              }}>{item.adminNotes ? item.adminNotes.substring(0, 100) : "-"}</td>
                    <td>  {moment(item.createdAt).format('MMM DD, YYYY hh:mm A')}</td>
                    <td>
                      {(item.requestStatus === "Requested" && (
                        <span style={{ color: "blue" }}>{intl.formatMessage({id: 'requested'})}</span>
                      )) ||
                        (item.requestStatus === "Accepted" && (
                          <span className="text-success">{intl.formatMessage({id: 'accepted'})}</span>
                        )) ||
                        (item.requestStatus === "Declined" && (
                          <span className="text-danger">{intl.formatMessage({id: 'declined'})}</span>
                        )) ||
                        ""}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {data && totalCount > 1 ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={first}
            pageCount={totalCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        ) : null}
        </div>
     
      </div>
    </>
  );
}
