/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import Constant from "../Constant";
import api from "../api";


export default {
  GET_USERS: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
  
        const response = await Axios.get(
          Constant.BASE_URL + api.User.GET_USERS(query)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_BY_ID: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.User.GET_USER_BY_ID(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  GET_USER_BY_ID: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.User.GET_ALL_USER()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_USER: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.User.GET_USER()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  // UPDATE_USER
  UPDATE_USER_BY_ID: ( data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          Constant.BASE_URL + api.User.UPDATE_USER_BY_ID(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  
  UPDATE_USER: ( data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          Constant.BASE_URL + api.User.UPDATE_USER(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  UPDATE_GS_TAG: ( data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          Constant.BASE_URL + api.User.UPDATE_GS_TAG(),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  GET_USER_BY_DOCUMENT_ID: (documentid = null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const queryParam = {};
        // if (documentid) queryParam.documentid = documentid;

        const query = new URLSearchParams({
          ...queryParam,
        }).toString();
        const response = await Axios.get(
          Constant.BASE_URL + api.User.GET_USER_BY_DOCUMENT_ID(query)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  UPDATE_VERIFY_BY_ID: (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          Constant.BASE_URL + api.User.UPDATE_VERIFY_BY_ID(id),
          data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_USER_BY_EMAIL: (email = null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const queryParam = {};
        // if (emai) queryParam.emai = emai;

        const query = new URLSearchParams({
          ...queryParam,
        }).toString();
        const response = await Axios.get(
          Constant.BASE_URL + api.User.GET_USER_BY_EMAIL(query)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_USER_BY_GSTAG: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
       
        const response = await Axios.get(
          Constant.BASE_URL + api.User.GET_USER_BY_GSTAG(query)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  EXCHANGE_GS_TO_GS: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
       
        const response = await Axios.put(
          Constant.BASE_URL + api.User.EXCHANGE_GS_TO_GS(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
};
