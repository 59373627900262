import React, { useState } from "react";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

function Verification() {
  const intl = useIntl();
  const [OTP, setOTP] = useState("");
  return (
    <div
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <div>
        <div className="conatiner d-flex justify-content-between col-12 mt-5">
          <Link to="/info">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
              height={20}
              width={30}
              alt=""
            />
          </Link>
        </div>
      </div>

      <div className=" bg-white col-6 mt-20  container shadow p-3 mb-5 bg-body-tertiary rounded p-20">
        <div className="">
          <h2 className="text-secondary-emphasis">
            {intl.formatMessage({ id: "enterYour4DigitPin" })}
          </h2>
          <p className="fs-5">
            {intl.formatMessage({
              id: "ensureYouDontThareThisPinWithAnyoneElse",
            })}
          </p>
        </div>
        <div className="mt-5 ">
          {" "}
          <OTPInput
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={4}
            otpType="number"
            disabled={false}
            secure
          />
          <p className="mt-5"> Having trouble?</p>
          <Link to="">
            {" "}
            <ResendOTP />
          </Link>
        </div>
        <div className="mt-15">
          {" "}
          <Link to="/businessinfo">
            {" "}
            <button className="btn btn-warning col-12">
              {" "}
              {intl.formatMessage({ id: "continuebtn" })}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Verification;
