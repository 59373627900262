/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import Toaster from "../../../../Components/Toster";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import Services from "../../../../Services";
import Spinner from "../../../../Components/Spinner";
import { useIntl } from "react-intl";
import axios from "axios";

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

export function Login() {
  const intl = useIntl();
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const GoToSignup = () => {
    localStorage.removeItem("currentStep");
    localStorage.clear();
    navigate("/auth/registration");
  };

  const exceptThisSymbols = ["+", "-", " "];
  const [isLoaded, setIsLoaded] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [isDisabled, setIsDisabled] = useState(false); ///set--true

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleLogin = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let isValid = true;
    setEmailError("");
    setPasswordError("");
    if (email.trim() === "") {
      setIsDisabled(false);
      setEmailError("Required");
      isValid = false;
    } else if (!validateEmail(email.trim())) {
      setIsDisabled(false);
      setEmailError("Enter a valid email address");
      isValid = false;
    }
    if (password.trim() === "") {
      setIsDisabled(false);
      setPasswordError("Please enter password");
      isValid = false;
    }
    if (isValid) {
      try {
        let data = {
          email: email.trim().toLocaleLowerCase(),
          password: password.trim(),
        };
        setIsDisabled(true);
        const response: any = await Services.Auth.LOGIN_USER(data);
        if (response.data.user.role === "User") {
          // setIsLoaded(true);
          if (
            response.data.user.isEmailVerify == false ||
            response.data.user.isEmailVerify == null
          ) {
           
           
            //api call
            try {
              setToaster({
                type: "success",
                title: "Email Sent",
                text: "Please check your mail",
                visiblity: "show",
              });
              await Services.Auth.SENDEMAILVERIFY()
            
            } catch (error: any) {
              setIsDisabled(false);
              setTimeout(() => {
                setToaster((prevState) => ({
                  ...prevState,
                  visiblity: "hide",
                }));
              }, 1500);
      
              setToaster({
                type: "danger",
                title: "Error Occured",
                text: error?.response?.data?.message
                  ? error?.response?.data?.message
                  : "An unknown error occured",
                visiblity: "show",
              });
            } finally {
              // setLoading(false);
              localStorage.clear();
              sessionStorage.clear();
              window.location.reload();
              navigate("/auth/login");
            }
          }
          // ///////////////
          // console.log(response.data.user.isEmailVerify);

          setTimeout(() => {
            navigate("/wallet");
          }, 1000);
          setToaster({
            type: "success",
            title: "Successfull",
            text: "Login successfully",
            visiblity: "show",
          });
        } else {
          setIsLoaded(true);
          setTimeout(() => {
            navigate("/user");
          }, 1000);
          setToaster({
            type: "success",
            title: "Successfull",
            text: "Login successfully",
            visiblity: "show",
          });
        }
      } catch (error: any) {
        setIsDisabled(false);
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        setToaster({
          type: "danger",
          title: "Error Occured",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : "An unknown error occured",
          visiblity: "show",
        });
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.value.trim() === "") {
      setIsDisabled(true);
      setEmailError("Required");
    } else {
      setIsDisabled(false);
      setEmail(e.target.value);
      setEmailError("");
    }
    setEmail(e.target.value);
  };

  const handleKey = (e) => {
    exceptThisSymbols.includes(e.key) && e.preventDefault();
  };

  const handlePsswordChange = (e) => {
    if (e.target.value.trim() === "") {
      setIsDisabled(true);
      setPasswordError("Required");
    } else {
      setIsDisabled(false);
      setPassword(e.target.value);
      setPasswordError("");
    }

    setPassword(e.target.value);
  };

  return (
    <form className="form w-100" noValidate id="kt_login_signin_form">
      <Toaster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      {!isLoaded && <Spinner />}
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">
          {intl.formatMessage({ id: "signIn" })}
        </h1>
      </div>

      <div className="fv-row mb-8">
        <label className="form-label fs-6 fw-bolder text-dark">
          {intl.formatMessage({ id: "email" })}
        </label>
        <input
          placeholder={intl.formatMessage({ id: "email" })}
          className={clsx("form-control bg-transparent")}
          type="email"
          name="email"
          autoComplete="off"
          value={email}
          onChange={handleChange}
          onKeyDown={handleKey}
        />
        {emailError ? (
          <small className=" position-absolute  mt-1" style={{ color: "red" }}>
            {emailError}
          </small>
        ) : null}
      </div>

      <div className="position-relative mb-3">
        <input
          type={passwordType}
          id="password"
          placeholder={intl.formatMessage({ id: "password" })}
          name="password"
          autoComplete="off"
          className="form-control bg-transparent"
          onChange={handlePsswordChange}
          value={password}
          onKeyDown={(e) =>
            exceptThisSymbols.includes(e.key) && e.preventDefault()
          }
          // onKeyDown={(e) => {
          //   e.key === "Enter"
          //     ? !isDisabled
          //       ? handleLogin()
          //       : e.preventDefault()
          //     : setPassword(e.target.value);
          // }}
          // Placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
          // aria-describedby="password"
        />
        {passwordError ? (
          <small className=" position-absolute  mt-1" style={{ color: "red" }}>
            {passwordError}
          </small>
        ) : null}
        <span
          className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
          data-kt-password-meter-control="visibility"
          onClick={() => {
            passwordType === "password"
              ? setPasswordType("text")
              : setPasswordType("password");
          }}
        >
          {passwordType === "password" ? (
            <i className="bi bi-eye fs-2"></i>
          ) : (
            <i className="bi bi-eye-slash fs-2"></i>
          )}
        </span>
      </div>

      <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-6 mt-6">
        <Link to="/auth/forgotpassword">
          {" "}
          {intl.formatMessage({ id: "forgotPassword?" })}
        </Link>
      </div>

      <Link to="">
        <div className="d-grid mb-10">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-primary"
            disabled={isDisabled}
            onClick={handleLogin}
          >
            {intl.formatMessage({ id: "logIn" })}
          </button>
        </div>
      </Link>
      {/* end::Action */}

      <div className="text-gray-500 text-center fw-semibold fs-6">
        {intl.formatMessage({ id: "notMemberYet?" })}{" "}
        <div className="text-primary "></div>
        <span className="text-primary user-select-none" onClick={GoToSignup}>
          {intl.formatMessage({ id: "signup" })}
        </span>
      </div>
    </form>
  );
}
