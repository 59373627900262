/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import Services from "../../../Services";
import Toster from "../../../Components/Toster";
import Spinner from "../../../Components/Spinner";
import { useIntl } from "react-intl";

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

export default function SetupPinForTransaction() {
  var currentUrl = window.location.href;


  const intl = useIntl();

  let navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(true);
  const [verify, setVerify] = useState("");
  const [isDisabled, setIsDisabled] = useState(true); ///set--true
  const [verifyError, setVerifyError] = useState("");

  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const exceptThisSymbols = ["+", "-", "*", "/", " "];

  const IdentityVerifySubmit = async () => {
    try {
      setIsDisabled(true);
      let data = {
        pin: verify,
      };
      let data1 = {
        isPinVerify: true,
      };

      const response: any = await Services.Auth.USER_SET_PIN(data);
      const response1 = await Services.User.UPDATE_USER(data1);
      if (response) {
        setIsLoaded(true);

        setTimeout(() => {
          navigate("/wallet");
        }, 700);

        setToaster({
          type: "success",
          title: "Successfull",
          text: "Pin setup successfully",
          visiblity: "show",
        });
      }
    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };
  const [OTP, setOTP] = useState("");
  useEffect(() => {}, []);
  return (
    <div
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      {!isLoaded && <Spinner />}
      <div>
        <div className="conatiner d-flex justify-content-between col-12 mt-5">
          <Link to="/security">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
              height={20}
              width={30}
              alt=""
            />
          </Link>
        </div>
      </div>

      <div className="bg-white col-6   container shadow p-3 mb-5 bg-body-tertiary rounded p-20">
        <div className="">
          {currentUrl.includes("/setuptransaction?type=setup") ? (
            <h2 className="text-secondary-emphasis">{intl.formatMessage({id :"SetupYour4DigitPin"})}</h2>
          ) : (
            <h2 className="text-secondary-emphasis">
              {intl.formatMessage({ id: "enterYour4DigitPin" })}
            </h2>
          )}

          <p>
            {intl.formatMessage({
              id: "ensureYouDontThareThisPinWithAnyoneElse",
            })}
          </p>
        </div>
        <div className="">
          {" "}
          <input
            className="form-control "
            type="number"
            name=""
            id=""
            value={verify}
            onChange={(e) => {
              const enteredNumber: any = e.target.value;

              if (enteredNumber >= 0 || enteredNumber === "") {
                setVerify(enteredNumber);
                if (enteredNumber.length > 4 && enteredNumber.length < 4) {
                  setIsDisabled(true);
                  setVerifyError("Value Must Be 4 Digits");
                } else if (enteredNumber.length === 0) {
                  setIsDisabled(true);
                  setVerifyError("Required");
                } else if (enteredNumber.length === 1) {
                  setIsDisabled(true);
                  setVerifyError("");
                } else if (enteredNumber.length === 2) {
                  setIsDisabled(true);
                } else if (enteredNumber.length === 3) {
                  setIsDisabled(true);
                  setVerifyError("");
                } else if (
                  enteredNumber.length > 3 &&
                  enteredNumber.length < 5
                ) {
                  setIsDisabled(false);
                  setVerifyError("");
                }
              }
            }}
            onKeyDown={(e: any) => {
              exceptThisSymbols.includes(e.key) && e.preventDefault();
              if (
                e.target.value.length >= 4 &&
                e.key !== "Backspace" &&
                e.key !== "Delete"
              ) {
                e.preventDefault();
                setVerifyError("Value Must Be 4 Digits");
              }
            }}
          />
          {verifyError ? (
            <p
              className="text-start mt-2 position-absolute"
              style={{ color: "red" }}
            >
              {verifyError}
            </p>
          ) : null}
        </div>
        <div className="mt-15">
          <button
            className="btn btn-warning col-12"
            disabled={isDisabled}
            onClick={IdentityVerifySubmit}
          >
            {intl.formatMessage({ id: "savePinbtn" })}
          </button>
        </div>
      </div>
    </div>
  );
}
