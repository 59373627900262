/* eslint-disable import/no-anonymous-default-export */
export default {
 
  GET_TRANSACTIONS: () => `/transaction/`,
  GET_USER_TRANSACTIONS: () => `/transaction/getByUserId`,
  GET_TRANSACTION_HISTORY: (id) => `/transactionHistory/${id}`,
  GET_TRANSACTIONS_BY_ID: (id,query) => `/gsTransitionHistory/gs/user/${id}?typeOfTxn=${query}`,
  GET_ALL_TRANSACTIONS_SEND: (query) => `/gsTransitionHistory/getAllReceivedTracnsaction?${query}`,
  GET_ALL_TRANSACTIONS_MOVE: (query) => `/gsTransitionHistory/getAllReceivedTracnsaction?${query}`,
  GET_TRANSACTIONS_BY_ID_Fund: (id,query) => `/primaryTransitionAccount/user/${id}?typeOfTxn=${query}`,
  GET_TRANSACTIONS_ADMIN_RECEIVED: (id,query) => `/gsTransitionHistory/getAllReceivedTracnsaction?typeOfTxn=${query}&id=${id}`,
  GET_TRANSACTIONS_USER: (query) => `/gsTransitionHistory/me?${query ? query : ""}`,
  GET_TRANSACTIONS_USER_RECIEVED: (query) => `/gsTransitionHistory/getReceivedTracnsactionMe?${query ? query : ""}`,
  GET_TRANSACTIONS_USER_FUND: (query) => `/primaryTransitionAccount/me?${query ? query : ""}`,
  //Shubham sir change start
  GET_USER_WITHDRAW_TRANSACTIONS: (query) => `/withdrawalRequest/me?${query ? query : ""}`,
  //Shubham sir change end
  GET_ALL_USER_WITHDRAW_TRANSACTIONS: (query) => `/withdrawalRequest?${query ? query : ""}`,
  GET_USER_WITHDRAW_TRANSACTIONS_BY_ID:(id)=>`/withdrawalRequest/${id}`,
  GET_RECENT_SEND_TRANSACTION:()=>`/gsTransitionHistory/getRecentTransaction/me`,
};
// /gsTransitionHistory/getRecentTransaction/me

