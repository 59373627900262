/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Services from "../Services";
import { useIntl } from "react-intl";
export default function Transact() {
  const intl = useIntl();
  const [cardData, setCardData] = useState<any>([]);

  const GET_CARD = async () => {
    try {
      const response: any = await Services.Card.GET_CARD();

      setCardData(response.data.data);
    } catch(error) {console.log(error);}
  };
  useEffect(() => {
    GET_CARD();
  }, []);

  return (
    <div className="container bg-white p-5 ">
      <div>
        <h1 className="text-center">Transact component</h1>

        <div>
          <div className="container mt-8 p-2 ">
            <Link to="/fundaccount">
              <div className="bg-white d-flex justify-content-between shadow p-3 mb-5 bg-body-tertiary rounded p-5">
                <Link to="/fundaccount">
                  <div>
                    <h3 className=" mt-2 ms-5">Deposit</h3>
                  </div>
                </Link>
                <Link to="/fundaccount">
                  <div>
                    <span className="svg-icon svg-icon-primary svg-icon-3x">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            transform="translate(11.646447, 12.853553) rotate(-315.000000) translate(-11.646447, -12.853553) "
                            x="10.6464466"
                            y="5.85355339"
                            width="2"
                            height="14"
                            rx="1"
                          />
                          <path
                            d="M8.1109127,8.90380592 C7.55862795,8.90380592 7.1109127,8.45609067 7.1109127,7.90380592 C7.1109127,7.35152117 7.55862795,6.90380592 8.1109127,6.90380592 L16.5961941,6.90380592 C17.1315855,6.90380592 17.5719943,7.32548256 17.5952502,7.8603687 L17.9488036,15.9920967 C17.9727933,16.5438602 17.5449482,17.0106003 16.9931847,17.0345901 C16.4414212,17.0585798 15.974681,16.6307346 15.9506913,16.0789711 L15.6387276,8.90380592 L8.1109127,8.90380592 Z"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
            </Link>

            <Link to="/makeawithdraw">
              <div className="bg-white d-flex justify-content-between shadow p-3 mb-5 bg-body-tertiary rounded p-5">
                <div>
                  <Link to="/makeawithdraw">
                    <h3 className=" mt-2 ms-5">
                      {intl.formatMessage({ id: "withdrawal" })}
                    </h3>
                  </Link>
                </div>
                <div>
                  <Link to="/makeawithdraw">
                    <span className="svg-icon svg-icon-primary svg-icon-3x">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            transform="translate(12.353553, 12.146447) rotate(-135.000000) translate(-12.353553, -12.146447) "
                            x="11.3535534"
                            y="5.14644661"
                            width="2"
                            height="14"
                            rx="1"
                          />
                          <path
                            d="M15.8890873,16.0961941 C16.441372,16.0961941 16.8890873,16.5439093 16.8890873,17.0961941 C16.8890873,17.6484788 16.441372,18.0961941 15.8890873,18.0961941 L7.40380592,18.0961941 C6.86841446,18.0961941 6.42800568,17.6745174 6.40474976,17.1396313 L6.05119637,9.00790332 C6.02720666,8.45613984 6.45505183,7.98939965 7.00681531,7.96540994 C7.55857879,7.94142022 8.02531897,8.36926539 8.04930869,8.92102887 L8.36127239,16.0961941 L15.8890873,16.0961941 Z"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                        </g>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </Link>

            <div
              className=""
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <Link to="/addcard">
                <button className="btn btn-warning col-lg-12 mt-4 ">
                  Add new card
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-start ms-4"
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className=" mt-8 col-12  ">
            {cardData && cardData?.map((item: any, key) => {
              return (
                <div
                  className=" d-flex justify-content-between align-items-center  "
                  key={key}
                >
                  <div>
                    <span className="fw-bolder"> {item.cardName}</span>
                    <br />
                    <span> {item.cardNumber}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
