/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Services from "../../Services";
import Toster from "../../Components/Toster";
import Spinner from "../../Components/Spinner";

import { useIntl } from "react-intl";

const initialState = { acc: "", description: "" };
interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

export default function FundAccount() {
  const intl = useIntl();

  const [data, setData] = useState(initialState);
  const [description, setdescription] = useState("");
  const [Bankdetails, setBankdetails] = useState<any>([]);
  const [PrimaryAc, setPrimaryAc] = useState<any>([]);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [balance, setBalance] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);

  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const getPrimaryData = async () => {
    try {
      const response: any = await Services.WithdrawalAc.GET_PRIMARY_AC();

      setBalance(response?.data?.data.balance);
      setPrimaryAc(response?.data?.data.accountNumber);
    } catch (error) {console.log(error);}
  };
  const getGSAccount = async () => {
    try {
      const response: any = await Services.GsAccount.GET_GS_ACCOUNT();
      const balance = response.data.primaryAccount.balance;
      setBalance(balance);
    } catch (error) {console.log(error);}
  };

  useEffect(() => {
    getPrimaryData();
    getGSAccount();
    GET_BANK_DETAILS();
  }, []);

  const dataSubmit = async () => {
    const data2 = {
      balance: amount.val,
      userNotes: description,
    };
    if (PrimaryAc && amount.val && description) {
      try {
        setIsDisabled(true);
        const response = await Services.GsAccount.ADD_FUND(data2);

        localStorage.removeItem("currentStep");
        setData(initialState);
        setTimeout(() => {
          navigate("/accountfundding1");
        }, 2000);
        setToaster({
          type: "success",
          title: "Successful",
          text: "Add Fund Request successfully",
          visiblity: "show",
        });
        setTimeout(() => {
          setToaster({
            type: "",
            title: "",
            text: "",
            visiblity: "hide",
          });
        }, 1000);
      } catch (error: any) {console.log(error);}
    } else {
    }
  };

  const [currentStep, setCurrentStep] = useState<number>(1);
  const goToNextStep = () => {
    localStorage.setItem("currentStep", (currentStep + 1).toString());
    setCurrentStep(currentStep + 1);
  };
  const goToPreviousStep = () => {
    localStorage.setItem("currentStep", (currentStep - 1).toString());
    setCurrentStep(currentStep - 1);
  };

  const isBackspace = (e) => e.key === "Backspace";
  const exceptThisSymbols = ["e", "E", "+", "-"];

  const [amount, setAmount] = useState<any>({
    val: null,
    isValid: true,
    err: "",
    validate: false,
  });
  function amountHandle(val: any) {
    if (!val.trim()) {
      setAmount({
        isValid: false,
        err: "Requeird",
        val: null,
        validate: true,
      });
    } else if (val.trim().length > 7) {
      setAmount({
        isValid: false,
        err: "Value Must Be 7 Digits",
        val: val.substr(0, 7),
        validate: true,
      });
    } else {
      setAmount({
        isValid: true,
        err: "",
        val: val,
        validate: true,
      });
    }
  }

  const GET_BANK_DETAILS = async () => {
    try {
      const response: any = await Services.Wallet.GET_BANK_DETAILS();
      setBankdetails(response?.data?.data?.html);
    } catch (error) {console.log(error);}
  };
  const handleAmountChange = (e) => {
    let val = e.target.value;
    if (val === "") {
      setAmount({
        isValid: false,
        err: "Required",
        val: "",
        validate: true,
      });
    } else if (val.trim()?.length > 7) {
      setAmount({
        isValid: false,
        err: "Value Must Be 7 Digits",
        val: val.substr(0, 7),
        validate: true,
      });
    } else {
      setAmount({
        isValid: true,
        err: "",
        val: val,
        validate: true,
      });
    }
  };

  return (
    <div className=" col-12 " data-aos="fade-left">
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      {!isLoaded && <Spinner />}
      {currentStep === 1 && (
        <div
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
          className=" bg-white shadow p-5 mb-5 bg-body-tertiary rounded  "
        >
          <div className="p-2">
            <div className="container  ">
              <h2 className="text-center ">
                {" "}
                {intl.formatMessage({ id: "addFund" })}
              </h2><br></br>
              <div className="ms-7">
                <h2>{intl.formatMessage({id:"Pleasesendyourtransferto:"})} </h2>
                <p className="ms-4">{intl.formatMessage({id:"funding@ongodsend.com"})}</p>
                <h2>{intl.formatMessage({id:"Importantinstructions:"})}</h2>
                <h3>{intl.formatMessage({id:"1.Useyourusernameasthesecurityquestion:"})}</h3>
                     <p className="ms-4">{intl.formatMessage({id:"GSTAGlocatedinprofile"})}</p>
                     <h3>{intl.formatMessage({id:"2.Usethiscodeastheecurityanswer:"})}</h3>
                     <p className="ms-4">{intl.formatMessage({id:"MZGE5BRY"})}</p>
              </div>
              <div className=" p-3  container p-2 ">
                {/* <div className="col-lg-12 d-flex justify-content-between p-1">
                  <div className="col-md-4 fv-row">
                    <label className=" d-flex align-items-center form-label mb-3 fw-bold">
                      {intl.formatMessage({ id: "accountNumber" })}
                    </label>
                    <span className="text-body-secondary">
                      {Bankdetails.AccountNumber}
                    </span>
                  </div>
                  <div className="col-md-4 fv-row">
                    <label className="d-flex align-items-center form-label mb-3 fw-bold">
                      {intl.formatMessage({ id: "bankName" })}
                    </label>
                    <span
                      className="text-body-secondary"
                      style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        maxWidth: "10ch",
                      }}
                    >
                      {Bankdetails.BankName}
                    </span>
                  </div>
                  <div className="col-md-4 fv-row">
                    <label className="d-flex align-items-center form-label mb-3 fw-bold">
                      {intl.formatMessage({ id: "bankAddress" })}
                    </label>
                    <span
                      className="text-body-secondary"
                      style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        maxWidth: "10ch",
                      }}
                    >
                      {Bankdetails.BankAddress}
                    </span>
                  </div>
                </div> */}

                <div className="col-lg-12 d-flex justify-content-between p-1">
                  {/* <div className="col-md-4 fv-row ">
                    <label className=" d-flex align-items-center form-label mb-3 fw-bold">
                      {intl.formatMessage({ id: "aBARoutingNumber" })}
                    </label>
                    <span className="text-body-secondary">
                      {Bankdetails.ABARoutingNumber}
                    </span>
                  </div> */}
                  {/* <div className="col-md-4 fv-row ">
                    <label className=" d-flex align-items-center form-label mb-3 fw-bold">
                      {intl.formatMessage({ id: "beneficaryName" })}
                    </label>
                    <span
                      className="text-body-secondary"
                      style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        maxWidth: "10ch",
                      }}
                    >
                      {Bankdetails.BeneficaryName}
                    </span>
                  </div> */}

                  <div className="col-4">
                    <label className="d-flex align-items-center form-label mb-3 fw-bold">
                      {intl.formatMessage({ id: "enterAmount" })}
                    </label>

                    <input
                      type="number"
                      min="0"
                      className="form-control p-6 bg-white"
                      name="account_name"
                      value={amount.val}
                      placeholder={intl.formatMessage({ id: "enterAmount" })}
                      onKeyDown={(e: any) => {
                        if (!isBackspace) {
                          exceptThisSymbols.includes(e.key) &&
                            e.preventDefault();
                        }
                        if (e.target.value.length >= 7 && !isBackspace) {
                          e.preventDefault();
                          setAmount({
                            isValid: false,
                            err: "Value Must Be 7 Digits",
                            val: amount.val,
                            validate: true,
                          });
                        }
                      }}
                      onChange={handleAmountChange}
                    />

                    {amount.err && (
                      <span
                        className="mt-2"
                        style={{
                          color: "red",
                          position: "absolute",
                        }}
                      >
                        {amount.err}
                      </span>
                    )}
                  </div>
                  <span className="fw-semibold"></span>
                </div>
              </div>
              <div className=" fv-row p-8">
                <label className="d-flex align-items-center form-label mb-3 fw-bold">
                  {intl.formatMessage({ id: "chooseAmmount" })}
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="Provide your team size to help us setup your billing"
                  ></i>
                </label>
                <div className="row mb-2" data-kt-buttons="true">
                  <div className="col">
                    <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                      <input
                        type="radio"
                        className="btn-check"
                        name="account_team_size"
                        onClick={(e: any) => amountHandle(e.target.value)}
                        value="100"
                      />
                      <span className="fw-bold fs-3">100</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                      <input
                        type="radio"
                        className="btn-check"
                        name="account_team_size"
                        onClick={(e: any) => amountHandle(e.target.value)}
                        value="500"
                      />
                      <span className="fw-bold fs-3">500</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                      <input
                        type="radio"
                        className="btn-check"
                        name="account_team_size"
                        onClick={(e: any) => amountHandle(e.target.value)}
                        value="1000"
                      />
                      <span className="fw-bold fs-3">1000</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                      <input
                        type="radio"
                        className="btn-check"
                        name="account_team_size"
                        onClick={(e: any) => amountHandle(e.target.value)}
                        value="3000"
                      />
                      <span className="fw-bold fs-3">3000</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className=" container  p-2 ">
                <div className="d-flex align-content-start ">
                  <div className="col-lg-12">
                    <label className="d-flex align-items-center form-label mb-3 fw-bold">
                      {intl.formatMessage({ id: "description" })}
                    </label>

                    <div className="">
                      <textarea
                        id="exampleFormControlTextarea1"
                        name={intl.formatMessage({ id: "description" })}
                        placeholder={intl.formatMessage({
                          id: "enterDescription",
                        })}
                        value={description}
                        onChange={(e) => {
                          if (e.target.value == "") {
                            setDescriptionError("Required");
                          } else {
                            setDescriptionError("");
                            setdescription(e.target.value.trimStart());
                          }
                          setdescription(e.target.value.trimStart());
                        }}
                        className="form-control py-6  p-3 mb-2 text-gray row-5"
                      ></textarea>
                      {descriptionError ? (
                        <p
                          className="text-start  position-absolute mt-2"
                          style={{ color: "red" }}
                        >
                          {descriptionError}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-7">
                <Link to="">
                  <button
                    className="btn btn-warning col-lg-12 p-5"
                    onClick={goToNextStep}
                    disabled={!(PrimaryAc && description && amount.val)}
                  >
                    {intl.formatMessage({ id: "continuebtn" })}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentStep === 2 && (
        <div
          className=""
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className="">
            <div className=" p-10 container col-lg-12 bg-white shadow p-5 mb-5 bg-body-tertiary rounded ">
              <h2 className="text-center">
                {intl.formatMessage({ id: "confirm" })}
              </h2>
              <p className="text-center mt-10 fs-3">
                {intl.formatMessage({
                  id: "pleaseReviewTheDetailsAndConfirmYourAddFund",
                })}
              </p>

              <div className="container col-lg-12">
                <div className="p-3">
                

                  <div className="d-flex justify-content-between align-items-start mt-10">
                    <div className="">
                      {" "}
                      <span>{intl.formatMessage({ id: "AMOUNT" })}</span>
                    </div>

                    <div className="">
                      {" "}
                      <span className="fw-bolder">${amount.val}</span>
                    </div>
                  </div>
                </div>
                <div className="mt-10  d-flex justify-content-between">
                  {" "}
                  <button
                    className="btn btn-warning "
                    onClick={() => goToPreviousStep()}
                  >
                    {intl.formatMessage({ id: "cancelfund" })}
                  </button>{" "}
                  <button
                    className="btn btn-warning "
                    disabled={isDisabled}
                    onClick={() => {
                      dataSubmit();
                    }}
                  >
                    {intl.formatMessage({ id: "finishfund" })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
