import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../scss/Card.module.scss";
import { useIntl } from "react-intl";

function Reviewinfo() {
  const intl = useIntl();

  const [Name, setName] = useState("");
  const [Address, setAddress] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  return (
    <div
      className=""
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <div className="d-flex">
        <div>
          <Link to="/businessaddress">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
              height={20}
              width={30}
              alt=""
            />
          </Link>
        </div>
        <div></div>
        <h2 className={styles.head1}>
          {intl.formatMessage({ id: "reviewYourInformation" })}
        </h2>
      </div>
      <div className="bg-white mt-8 p-2 shadow-sm p-10 mb-5  rounded">
        <div className=" text-black-50 mb-5 fs-3 ms-4">
          <p>
            Your information will be used to verify your identity, kindly
            confirm everything is correct to avoid delays.
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <div className="mt-5">
            <h3 className="ms-5">Name</h3>
            <div className="mt-3 ">
              <input
                className="form-control m-5 mt-2"
                type="text"
                placeholder="First name , Last name"
                value={Name}
                onChange={handleNameChange}
              />
            </div>
          </div>
          <div className="mt-5">
            <h3 className="ms-5">{intl.formatMessage({ id: "address" })}</h3>
            <div className="mt-3">
              <input
                className="form-control m-5 mt-2"
                type="text"
                placeholder={intl.formatMessage({ id: "address" })}
                value={Address}
                onChange={handleAddressChange}
              />
            </div>
          </div>
          <div className="mt-5">
            <h3 className="ms-5">
              {intl.formatMessage({ id: "dateofBirth" })}
            </h3>
            <div className="mt-3">
              <input
                className="form-control m-5 mt-2"
                type="date"
                placeholder="DD/MM/YY"
              />
            </div>
          </div>
        </div>
        <Link to="/reviewbusinessinfo">
          <button className="btn btn-warning m-6 col-lg-12" type="submit">
            {" "}
            {intl.formatMessage({ id: "continuebtn" })}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Reviewinfo;
