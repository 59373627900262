/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Services from "../../../../Services";
import Toster from "../../../../Components/Toster";
import { useIntl } from "react-intl";

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}
export default function ChangePassword() {
  const intl = useIntl()
  let navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [newpasswordError, setNewPasswordError] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const [isDisabled, setIsDisabled] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [isLoaded, setIsLoaded] = useState(true);
  const exceptThisSymbols = ["+", "-", "*", "/", " "];


  const IdentityVerifySubmit = async () => {
    setIsDisabled(true)
    if (isDisabled) {
      setIsDisabled(true)
     
    }
    let isValid = true;
    setPasswordError("");
    if (password === "") {
      setPasswordError("Please enter password");
      isValid = false;
    }
    const hasLetter = /[a-zA-Z]/;
const hasNumber = /[0-9]/;

if (newpassword !== confirmPassword) {
  setConfirmPasswordError("Password does not match");
} else if (!hasLetter.test(newpassword) || !hasNumber.test(newpassword)) {
  isValid = false;
  setNewPasswordError("Password must contain at least one letter and one number");
  
}
    
    
    else {
      try {
        setIsDisabled(true)
        let data = {
          oldPassword: password,
          newPassword: confirmPassword,
        };
        const response: any = await Services.Auth.CHANGE_PASSWORD(data);

        if (response) {
          setIsLoaded(true);

          setTimeout(() => {
            navigate("/passwordsuccessfull");
          },50);

          setToaster({
            type: "success",
            title: "Successfull",
            text: "Update password successfully",
            visiblity: "show",
          });
        }
        
      } catch (error: any) {
        setIsDisabled(false)
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        setToaster({
          type: "danger",
          title: "Error Occured",
          text: error?.response?.data?.message
            ? error?.response?.data?.message 
            : "An unknown error occured",
          visiblity: "show",
        });
      }
    }
  };

  return (
    <div data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500">
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      <div>
     </div>
      <div className='p-10 container bg-white shadow-sm  mb-5 col-12 bg-body-tertiary rounded mt-10'>
      <h2 className="text-center">{intl.formatMessage({id: 'changePassword'})}</h2>
        <div className='container m-5 '>

          <div className="position-relative  mt-12 mb-5">
            <h6 className="text-start ">{intl.formatMessage({id: 'currentPassword'})}</h6>

            <input
              type={passwordType}
              id="password"
              placeholder={intl.formatMessage({id: 'password'})}
              name="password"
              autoComplete="off"
              className="form-control bg-transparent"
              onChange={(e) => {
                if (e.target.value.trim() === "") {
                  setIsDisabled(true);
                  setPasswordError("Required");
                 
                }
               else {
                  setPassword(e.target.value.trimStart());
                  setPasswordError("");
                  setIsDisabled(false);
                }
            
                setPassword(e.target.value.trimStart());
              }}
              value={password}
              onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()
              }

            />

            {passwordError ? (
              <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                {passwordError}
              </p>
            ) : null}

            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 mt-4"
              data-kt-password-meter-control="visibility"
              onClick={() => {
                passwordType === "password"
                  ? setPasswordType("text")
                  : setPasswordType("password");
              }}
            >
              {passwordType === "password" ? (
                <i className="bi bi-eye fs-2"></i>
              ) : (
                <i className="bi bi-eye-slash fs-2"></i>
              )}
            </span>
          </div>

          <div className="position-relative  mt-12 mb-5">
            <h6 className="text-start ">{intl.formatMessage({id: 'newPassword'})}</h6>
                     <input
                      type={newPasswordType}
                      id="password"
                      placeholder={intl.formatMessage({ id: "password" })}
                      name="password"
                      autoComplete="off"
                      className="form-control bg-transparent"
                      onPaste={(e:any) => {
                        const passwordValue = e.target.value.trimStart();
                        setNewPassword(passwordValue);

                        if (passwordValue === "") {
                          setIsDisabled(true);
                          setNewPasswordError("Required");
                        } else if (
                          passwordValue.length < 8 ||
                          passwordValue.length > 32
                        ) {
                          setIsDisabled(true);
                          setNewPasswordError(
                            "Password must be between 8 and 32 characters"
                          );
                        } else if (
                          !/[a-zA-Z]/.test(passwordValue) ||
                          !/\d/.test(passwordValue)
                        ) {
                          setIsDisabled(true);
                          setNewPasswordError(
                            "Password must contain at least one letter and one number"
                          );
                          
                        } else {
                          setNewPasswordError("");
                          setIsDisabled(false);
                        }
                      }}
                      onChange={(e) => {
                        const passwordValue = e.target.value.trimStart();
                        setNewPassword(passwordValue);

                        if (passwordValue === "") {
                          setIsDisabled(true);
                          setNewPasswordError("Required");
                        } else if (
                          passwordValue.length < 8 ||
                          passwordValue.length > 32
                        ) {
                          setIsDisabled(true);
                          setNewPasswordError(
                            "Password must be between 8 and 32 characters"
                          );
                        } else if (
                          !/[a-zA-Z]/.test(passwordValue) ||
                          !/\d/.test(passwordValue)
                        ) {
                          setIsDisabled(false);
                          setNewPasswordError(
                            "Password must contain at least one letter and one number"
                          );
                        } else {
                          setNewPasswordError("");
                          setIsDisabled(false);
                        }
                      }}
                      value={newpassword}
                      onKeyDown={(e: any) => {
                        exceptThisSymbols.includes(e.key) && e.preventDefault();
                        
                        
                      }}
                    />

            {newpasswordError ? (
              <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                {newpasswordError}
              </p>
            ) : null}

            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 mt-4"
              data-kt-password-meter-control="visibility"
              onClick={() => {
                newPasswordType === "password"
                  ? setNewPasswordType("text")
                  : setNewPasswordType("password");
              }}
            >
              {newPasswordType === "password" ? (
                <i className="bi bi-eye fs-2"></i>
              ) : (
                <i className="bi bi-eye-slash fs-2"></i>
              )}
            </span>
          </div>

          <div className="position-relative  mt-12 mb-5">
            <h6 className="text-start ">{intl.formatMessage({id: 'confirmPassword'})}</h6>

            <input
              type={confirmPasswordType}
              id="password"
              placeholder={intl.formatMessage({id: 'password'})}
              name="password"
              autoComplete="off"
              className="form-control bg-transparent"
              onChange={(e) => {
                if (e.target.value.trim() === "") {
                  setIsDisabled(true);

                  setConfirmPasswordError("Required");
                }
                else if (e.target.value.trim() !== newpassword) {
                  setIsDisabled(true);

                  setConfirmPasswordError("Password does not match");
                }
               else {
                  setIsDisabled(false)
                  setConfirmPassword(e.target.value.trimStart());
                  setConfirmPasswordError("");
                }

                setConfirmPassword(e.target.value.trimStart());
              }}
              value={confirmPassword}
              onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()

              }

            />

            {confirmPasswordError ? (
              <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                {confirmPasswordError}
              </p>
            ) : null}

            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 mt-4"
              data-kt-password-meter-control="visibility"
              onClick={() => {
                confirmPasswordType === "password"
                  ? setConfirmPasswordType("text")
                  : setConfirmPasswordType("password");
              }}
            >
              {confirmPasswordType === "password" ? (
                <i className="bi bi-eye fs-2"></i>
              ) : (
                <i className="bi bi-eye-slash fs-2"></i>
              )}
            </span>
          </div>


          <div className="mt-20  d-flex justify-content-between">
            {" "}
            <Link to='/security' className=''>
              <button className="btn btn-secondary ">
              {intl.formatMessage({id: 'cancelBtn'})}  </button>
            </Link>
            
            <button className="btn btn-warning  " onClick={IdentityVerifySubmit} disabled={!(password&& !newpasswordError && newpassword.length>=8 && newpassword.length<=32  && !isDisabled)}>
            {intl.formatMessage({id: 'ChangePasswordbtn'})}</button>
             </div>
        </div>
      </div>
    </div>
  )
}