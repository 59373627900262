/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Toster from "../Components/AdminPanelToster";
import Services from "../Services";
import Constant from "../Constant";
import { useIntl } from "react-intl";

export default function Send() {
  const intl = useIntl();
  const navitage = useNavigate();
  const imageUrl = Constant.BASE_URL_UPLOADS;
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [toggle, settoggle] = useState(false);
  const [gsTagUsers, setGsTagUsers] = useState<any>();
  const [gsTagUsersAcount, setGsTagUsersgsTagUsersAcount] = useState<number>();
  const [gsTagUsersFirstName, setGsTagUsersFirstName] = useState<string>("");
  const [gsTagUsersLastName, setGsTagUsersLastName] = useState<string>("");
  const [amountError, setAmountError] = useState("");
  const [gsTagUsersError, setGsTagUsersError] = useState("");
  const [gsTagUsersId, setGsTagUsersId] = useState("");
  const [amount, setAmount] = useState<number>(0);
  const [imageUser, setimageUser] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [pinIsDisabled, setPinIsDisabled] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [verifyPinHandler, setVerifyPinHandler] = useState<any>("");
  const [isApiCallPending, setApiCallPending] = useState(false);
  const [toGsTag, setToGsTag] = useState("");
  const [gsAccountBalance, setGsAccountBalance] = useState<any>("");
  const [pinError, setPinError] = useState("");
  const [pinVerify, setPinVerify] = useState<any>("");
  const [isValidate, setIsValidate] = useState(false);
  const [recentTransactionData, setRecentTransactionData] = useState<any>();
  const [recentTransactionData1, setRecentTransactionData1] = useState<any>();
  const [recentTransactionData2, setRecentTransactionData2] = useState<any>();
  const [recentTransactionData3, setRecentTransactionData3] = useState<any>();
  const [recentTransactionData4, setRecentTransactionData4] = useState<any>();
  const selfGsTag = localStorage.getItem("GsTagNumber");

  interface ToasterState {
    type: string;
    title: string;
    text: string;
    visiblity: string;
  }

  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const exceptThisSymbols = ["+", "-", " "];
  const goToNextStep = () => {
    localStorage.setItem("currentStep", (currentStep + 1).toString());

    setCurrentStep(currentStep + 1);
  };
  const goToPreviousStep = () => {
    localStorage.setItem("currentStep", (currentStep - 1).toString());

    setCurrentStep(currentStep - 1);
  };

  const getUserByGsTag = async (query) => {
    try {
      const response: any = await Services.User.GET_USER_BY_GSTAG(query);

      if (response) {
        setGsTagUsersFirstName(response?.data?.gs?.firstName);

        setGsTagUsersLastName(response?.data?.gs?.lastName);
        setGsTagUsersgsTagUsersAcount(response?.data?.data?.accountNumber);
        setToGsTag(response?.data?.gs?.gsTag);

        setimageUser(response?.data?.gs?.profile);

        setGsTagUsersId(response?.data?.gs?.id);
        setGsTagUsersError("");
      }
    } catch (error) {
      // setGsTagUsersError(intl.formatMessage({ id: "userNotFound" }))
      setGsTagUsersFirstName("");
      setimageUser("");
    }
  };

  const GET_RECENT_SEND_TRANSACTION = async () => {
    try {
      const response: any =
        await Services.Transaction.GET_RECENT_SEND_TRANSACTION();
      setRecentTransactionData(response?.data?.data[0]?.rows[0]);
      setRecentTransactionData1(response?.data?.data[1]?.rows[0]);
      setRecentTransactionData2(response?.data?.data[2]?.rows[0]);
      setRecentTransactionData3(response?.data?.data[3]?.rows[0]);
      setRecentTransactionData4(response?.data?.data[4]?.rows[0]);
    } catch(error) {console.log(error);}
  };

  useEffect(() => {
    GET_RECENT_SEND_TRANSACTION();
    getuser();
    {
      gsTagUsers && getUserByGsTag(gsTagUsers);
    }
    const savedStep = localStorage.getItem("currentStep");

    if (savedStep) {
    }
  }, [gsTagUsers]);
  const getuser = async () => {
    try {
      const response: any = await Services.User.GET_USER();
      setPinVerify(response?.data?.data?.isPinVerify);
      setGsAccountBalance(response?.data?.data?.GsAccount?.balance);
    } catch(error) {console.log(error);}
  };

  const GsTagNumber = localStorage.getItem("GsTagNumber");
 

  const phoneNumber = localStorage.getItem("phoneNumber");
  const email = localStorage.getItem("Email");

  const handleInputChange = (e: any) => {
    setIsValidate(true);

    if (!e.target.value) setIsValidate(false);

    {
      <div className="card col-md-2  m-3 rounded ">
        <div className="  pt-4 rounded  bg-warning bg-opacity-25 d-flex  align-items-center flex-column">
          {imageUser && (
            <img
              className="rounded-circle"
              src={imageUrl + imageUser}
              crossOrigin=""
              height={70}
              width={70}
              alt=""
            />
          )}

          <span className="text-center mt-2   fw-bolder">
            {gsTagUsersFirstName.length > 5
              ? gsTagUsersFirstName.substring(0, 5) + ".."
              : gsTagUsersFirstName}{" "}
            &nbsp;
            {gsTagUsersLastName.length > 5
              ? gsTagUsersLastName.substring(0, 5) + ".."
              : gsTagUsersLastName}
          </span>
          <p className="text-center mt-2   fw-semibold">
            {intl.formatMessage({ id: "gSTag" })}
            <br />
            <Link to="" className="text-black">
              {toGsTag}
            </Link>
          </p>
        </div>
      </div>;
    }

    if (
      GsTagNumber?.trim() === e.target.value.trim() ||
      phoneNumber?.trim() === e.target.value?.trim() ||
      email?.trim() === e.target.value?.trim()
    ) {
      setGsTagUsersError(
        intl.formatMessage({ id: "youCannotearchOwnGSTagOrPhoneNumberOrEmail" })
      );
      setGsTagUsers(e.target.value.trim());
    } else {
      setGsTagUsersError(intl.formatMessage({ id: "userNotFound" }));
      setGsTagUsers(e.target.value);
      getUserByGsTag(e.target.value);
    }

    if (e.target.value.length !== "") {
    }
  };

  function amountHandle(val: any) {
    if (val.length !== "") {
      setAmount(val);
  
      if (val > gsAccountBalance) {
        setAmountError("Insufficient balance");
      } else if (val <= 0.0005) {
        setAmountError("Value must be more than 0.0005");
      } else {
        setAmountError("");
      }
    }
  }
  

  const handlefinish = async () => {
    const data = {
      balance: amount,
      toAccount: gsTagUsersId,
    };
    setIsDisabled(true);
    const response = await Services.User.EXCHANGE_GS_TO_GS(data);
    if (response) {
      if (response && pinVerify === true) {
        setPinError("");
        setToaster({
          type: "success",
          title: "Successful",
          text: "Exchange  successfully",
          visiblity: "show",
        });

        setTimeout(() => {
          setToaster({
            type: "",
            title: "",
            text: "",
            visiblity: "hide",
          });
        }, 1000);
        setTimeout(() => {
          navitage("/sendsuccessful");
          localStorage.removeItem("currentStep");
          window.location.reload();
        }, 500);
      } else {
        setPinError("");
        setToaster({
          type: "success",
          title: "Successful",
          text: "Exchange  successfully",
          visiblity: "show",
        });

        setTimeout(() => {
          setToaster({
            type: "",
            title: "",
            text: "",
            visiblity: "hide",
          });
        }, 1000);
        setTimeout(() => {
          navitage("/sendsuccessful");
          localStorage.removeItem("currentStep");
        }, 500);
      }
    }
  };

  const SendAmmountData = async () => {
    const data = {
      balance: amount,
      toAccount: gsTagUsersId,
    };
    setPinIsDisabled(true);
    setToggle2(true);
    try {
      if (pinVerify === true) {
        settoggle(true);
        if (verifyPinHandler !== "" && !pinError) {
          setIsDisabled(true);
          const data1: any = await Services.Auth.USER_PIN_VARIFY({
            pin: verifyPinHandler,
          });
          if (data1) {
            setPinError("");
            setToaster({
              type: "success",
              title: "Successful",
              text: "Pin Match successfully",
              visiblity: "show",
            });

            setTimeout(() => {
              setToaster({
                type: "",
                title: "",
                text: "",
                visiblity: "hide",
              });
            }, 1000);
            handlefinish();
          } else {
            setPinError("pin not match");
          }
        } else {
          if (toggle === true) {
            setPinError("Required");
          } else {
            setPinError("");
          }
        }
      } else {
        handlefinish();
      }
    } catch (error: any) {
      setPinIsDisabled(false);
      if (verifyPinHandler === "") {
      } else {
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        setToaster({
          type: "danger",
          title: "Error Occured",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : "An unknown error occured",
          visiblity: "show",
        });
      }
      setIsDisabled(false);
    } finally {
      setApiCallPending(false);
    }
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      setVerifyPinHandler("");
      setPinError("");
    }
  };
  const handle = () => {
    setIsValidate(false);
  };

  const handleVerifyPin = (e) => {
    if (e.target.value.length <= 0) {
      setPinIsDisabled(true);
      setPinError("Required");
    } else if (e.target.value.length < 4) {
      setPinIsDisabled(true);
      setPinError("");
    } else {
      setPinError("");
      setPinIsDisabled(false);
    }
    setVerifyPinHandler(e.target.value.trim());
  };
  const handleAmount = (e) => {
    const inputValue = parseFloat(e.target.value);
    if (inputValue > gsAccountBalance) {
      setAmountError("Insufficient balance");
    } else {
      setAmountError("");
    }
    // Check conditions related to gsAccountBalance
    if (inputValue > gsAccountBalance) {
      setAmountError("Value exceeds account balance");
    } else {
      // Check conditions for inputValue
      if (inputValue > 0.0005) {
        setAmountError("");
      } else {
        setAmountError("Value must be greater than 0.0005");
      }
    }
  
    // Empty string check
    if (e.target.value === "") {
      setAmountError("Required");
    }
  
    // Update state using amountHandle
    amountHandle(e.target.value);
  };
  

  return (
    <div>
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />

      <>
        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          onClick={handleOverlayClick}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {intl.formatMessage({ id: "verifyyourpin" })}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setVerifyPinHandler("");
                    setPinError("");
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="p-5 d-flex align-items-center position-relative my-1">
                    <input
                      type="number"
                      data-kt-user-table-filter="search"
                      className="form-control form-control-solid w-250px ps-14"
                      placeholder={intl.formatMessage({ id: "enteryourpin" })}
                      max={4}
                      min={0}
                      value={verifyPinHandler}
                      onChange={handleVerifyPin}
                      onKeyDown={(e: any) => {
                        exceptThisSymbols.includes(e.key) && e.preventDefault();
                        if (
                          e.target.value.length >= 4 &&
                          e.key !== "Backspace" &&
                          e.key !== "Delete"
                        ) {
                          e.preventDefault();
                          setPinError("");
                        }
                      }}
                    />
                    {pinError && (
                      <span
                        className="mt-20 "
                        style={{ color: "red", position: "absolute" }}
                      >
                        {pinError}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    setIsDisabled(false);
                    setVerifyPinHandler("");
                    setPinError("");
                  }}
                >
                  {intl.formatMessage({ id: "close" })}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={pinIsDisabled}
                  onClick={SendAmmountData}
                >
                  {intl.formatMessage({ id: "finishSend" })}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>

      {currentStep === 1 && (
        <div className="bg-white   ">
          <div className="p-10 container col-lg-12 shadow-sm p-4 mb-5 bg-body-tertiary rounded bg-white">
            <h2 className="text-center mb-6">
              {intl.formatMessage({ id: "sendMoney" })}
            </h2>
            <div
              className="input-group mb-3 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <input
                type="text"
                min="0"
                className="form-control"
                placeholder={intl.formatMessage({
                  id: "gSTagEmailOrPhoneNumber",
                })}
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={gsTagUsers}
                onPaste={handle}
                onKeyDown={(e: any) => {
                  exceptThisSymbols.includes(e.key) && e.preventDefault();
                }}
                onChange={handleInputChange}
              />
            </div>

            <div
              className="text-center fs-5 p-5"
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              {" "}
              <span className="">
                {" "}
                {intl.formatMessage({ id: "SendToAnyoneAndAnywhere" })}
              </span>
              <br />
              <span className="mt-10">
                {" "}
                {intl.formatMessage({ id: "sendAgainTo" })} :
              </span>
            </div>

            {gsTagUsersError && gsTagUsers ? (
              <>
                <div className="text-center  p-5">
                  <h5 className="" style={{ color: "red" }}>
                    {gsTagUsersError}
                  </h5>
                  <br />
                </div>
              </>
            ) : (
              <>
                {isValidate === false ? (
                  <div className="container overflow-scroll m-1 p-2 row g-6 g-xl-8 col-lg-12  d-flex justify-content-center align-items-center">
                    {recentTransactionData && (
                      <div
                        className="col-md-4 col-lg-3 col-xl-2 m-3"
                        data-aos="zoom-out"
                      >
                        <div
                          className={`pt-4 rounded bg-warning bg-opacity-25 d-flex align-items-center flex-column ${
                            gsTagUsers ===
                            recentTransactionData?.receivedByUser?.gsTag
                              ? "border border-3 border-warning bg-warning  bg-opacity-10 "
                              : ""
                          }`}
                          onClick={() => {
                            setGsTagUsers(
                              recentTransactionData?.receivedByUser?.gsTag
                            );
                          }}
                        >
                          <img
                            className="rounded-circle"
                            src={
                              imageUrl +
                              recentTransactionData?.receivedByUser?.profile
                            }
                            crossOrigin=""
                            height={80}
                            width={80}
                            alt=""
                          />
                          <span className="text-center mt-2 fw-bolder">
                            {recentTransactionData?.receivedByUser?.firstName
                              ?.length > 5
                              ? recentTransactionData?.receivedByUser?.firstName.substring(
                                  0,
                                  5
                                ) + ".."
                              : recentTransactionData?.receivedByUser
                                  ?.firstName}{" "}
                            &nbsp;
                            {recentTransactionData?.receivedByUser?.lastName
                              ?.length > 5
                              ? recentTransactionData?.receivedByUser?.lastName.substring(
                                  0,
                                  5
                                ) + ".."
                              : recentTransactionData?.receivedByUser?.lastName}
                          </span>
                          <p className="text-center mt-2 fw-semibold">
                            {intl.formatMessage({ id: "gSTag" })}
                            <br />
                            <Link to="" className="text-black">
                              {recentTransactionData.receivedByUser.gsTag}
                            </Link>
                          </p>
                        </div>
                      </div>
                    )}

                    {recentTransactionData1 && (
                      <div
                        className="col-md-4 col-lg-3 col-xl-2 m-3"
                        data-aos="zoom-out"
                      >
                        <div
                          className={`pt-4 rounded bg-warning bg-opacity-25 d-flex align-items-center flex-column ${
                            gsTagUsers ===
                            recentTransactionData1?.receivedByUser?.gsTag
                              ? "border border-3 border-warning bg-warning  bg-opacity-10 "
                              : ""
                          }`}
                          onClick={() => {
                            setGsTagUsers(
                              recentTransactionData1?.receivedByUser?.gsTag
                            );
                          }}
                        >
                          <img
                            className="rounded-circle"
                            src={
                              imageUrl +
                              recentTransactionData1?.receivedByUser?.profile
                            }
                            crossOrigin=""
                            height={80}
                            width={80}
                            alt=""
                          />
                          <span className="text-center mt-2 fw-bolder">
                            {recentTransactionData1?.receivedByUser?.firstName
                              ?.length > 5
                              ? recentTransactionData1?.receivedByUser?.firstName.substring(
                                  0,
                                  5
                                ) + ".."
                              : recentTransactionData1?.receivedByUser
                                  ?.firstName}{" "}
                            &nbsp;
                            {recentTransactionData1?.receivedByUser?.lastName
                              ?.length > 5
                              ? recentTransactionData1?.receivedByUser?.lastName.substring(
                                  0,
                                  5
                                ) + ".."
                              : recentTransactionData1?.receivedByUser
                                  ?.lastName}
                          </span>
                          <p className="text-center mt-2 fw-semibold">
                            {intl.formatMessage({ id: "gSTag" })}
                            <br />
                            <Link to="" className="text-black">
                              {recentTransactionData1?.receivedByUser.gsTag}
                            </Link>
                          </p>
                        </div>
                      </div>
                    )}
                    {recentTransactionData2 && (
                      <div
                        className="col-md-4 col-lg-3 col-xl-2 m-3"
                        data-aos="zoom-out"
                      >
                        <div
                          className={`pt-4 rounded bg-warning bg-opacity-25 d-flex align-items-center flex-column ${
                            gsTagUsers ===
                            recentTransactionData2?.receivedByUser?.gsTag
                              ? "border border-3 border-warning bg-warning  bg-opacity-10 "
                              : ""
                          }`}
                          onClick={() => {
                            setGsTagUsers(
                              recentTransactionData2?.receivedByUser?.gsTag
                            );
                          }}
                        >
                          <img
                            className="rounded-circle"
                            src={
                              imageUrl +
                              recentTransactionData2?.receivedByUser?.profile
                            }
                            crossOrigin=""
                            height={80}
                            width={80}
                            alt=""
                          />
                          <span className="text-center mt-2 fw-bolder">
                            {recentTransactionData2?.receivedByUser?.firstName
                              ?.length > 5
                              ? recentTransactionData2?.receivedByUser?.firstName.substring(
                                  0,
                                  5
                                ) + ".."
                              : recentTransactionData2?.receivedByUser
                                  ?.firstName}{" "}
                            &nbsp;
                            {recentTransactionData2?.receivedByUser?.lastName
                              ?.length > 5
                              ? recentTransactionData2?.receivedByUser?.lastName.substring(
                                  0,
                                  5
                                ) + ".."
                              : recentTransactionData2?.receivedByUser
                                  ?.lastName}
                          </span>
                          <p className="text-center mt-2 fw-semibold">
                            {intl.formatMessage({ id: "gSTag" })}
                            <br />
                            <Link to="" className="text-black">
                              {recentTransactionData2.receivedByUser.gsTag}
                            </Link>
                          </p>
                        </div>
                      </div>
                    )}
                    {recentTransactionData3 && (
                      <div
                        className="col-md-4 col-lg-3 col-xl-2 m-3"
                        data-aos="zoom-out"
                      >
                        <div
                          className={`pt-4 rounded bg-warning bg-opacity-25 d-flex align-items-center flex-column ${
                            gsTagUsers ===
                            recentTransactionData3?.receivedByUser?.gsTag
                              ? "border border-3 border-warning bg-warning  bg-opacity-10 "
                              : ""
                          }`}
                          onClick={() => {
                            setGsTagUsers(
                              recentTransactionData3?.receivedByUser?.gsTag
                            );
                          }}
                        >
                          <img
                            className="rounded-circle"
                            src={
                              imageUrl +
                              recentTransactionData3?.receivedByUser?.profile
                            }
                            crossOrigin=""
                            height={80}
                            width={80}
                            alt=""
                          />
                          <span className="text-center mt-2 fw-bolder">
                            {recentTransactionData3?.receivedByUser?.firstName
                              ?.length > 5
                              ? recentTransactionData3?.receivedByUser?.firstName.substring(
                                  0,
                                  5
                                ) + ".."
                              : recentTransactionData3?.receivedByUser
                                  ?.firstName}{" "}
                            &nbsp;
                            {recentTransactionData3?.receivedByUser?.lastName
                              ?.length > 5
                              ? recentTransactionData3?.receivedByUser?.lastName.substring(
                                  0,
                                  5
                                ) + ".."
                              : recentTransactionData3?.receivedByUser
                                  ?.lastName}
                          </span>
                          <p className="text-center mt-2 fw-semibold">
                            {intl.formatMessage({ id: "gSTag" })}
                            <br />
                            <Link to="" className="text-black">
                              {recentTransactionData3.receivedByUser.gsTag}
                            </Link>
                          </p>
                        </div>
                      </div>
                    )}
                    {recentTransactionData4 && (
                      <div
                        className="col-md-4 col-lg-3 col-xl-2 m-3"
                        data-aos="zoom-out"
                      >
                        <div
                          className={`pt-4 rounded bg-warning bg-opacity-25 d-flex align-items-center flex-column ${
                            gsTagUsers ===
                            recentTransactionData4?.receivedByUser?.gsTag
                              ? "border border-3 border-warning bg-warning  bg-opacity-10 "
                              : ""
                          }`}
                          onClick={() => {
                            setGsTagUsers(
                              recentTransactionData4?.receivedByUser?.gsTag
                            );
                          }}
                        >
                          <img
                            className="rounded-circle"
                            src={
                              imageUrl +
                              recentTransactionData4?.receivedByUser?.profile
                            }
                            crossOrigin=""
                            height={80}
                            width={80}
                            alt=""
                          />
                          <span className="text-center mt-2 fw-bolder">
                            {recentTransactionData4?.receivedByUser?.firstName
                              ?.length > 5
                              ? recentTransactionData4?.receivedByUser?.firstName.substring(
                                  0,
                                  5
                                ) + ".."
                              : recentTransactionData4?.receivedByUser
                                  ?.firstName}{" "}
                            &nbsp;
                            {recentTransactionData4?.receivedByUser?.lastName
                              ?.length > 5
                              ? recentTransactionData4?.receivedByUser?.lastName.substring(
                                  0,
                                  5
                                ) + ".."
                              : recentTransactionData4?.receivedByUser
                                  ?.lastName}
                          </span>
                          <p className="text-center mt-2 fw-semibold">
                            {intl.formatMessage({ id: "gSTag" })}
                            <br />
                            <Link to="" className="text-black">
                              {recentTransactionData4.receivedByUser.gsTag}
                            </Link>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="card col-md-2  m-3 rounded ">
                    <div className="  pt-4 rounded  bg-warning bg-opacity-25 d-flex  align-items-center flex-column">
                      <img
                        className="rounded-circle"
                        src={imageUrl + imageUser}
                        crossOrigin=""
                        height={80}
                        width={80}
                        alt=""
                      />

                      <span className="text-center mt-2   fw-bolder">
                        {gsTagUsersFirstName.length > 5
                          ? gsTagUsersFirstName.substring(0, 5) + ".."
                          : gsTagUsersFirstName}{" "}
                        &nbsp;
                        {gsTagUsersLastName.length > 5
                          ? gsTagUsersLastName.substring(0, 5) + ".."
                          : gsTagUsersLastName}
                      </span>
                      <p className="text-center mt-2   fw-semibold">
                        {intl.formatMessage({ id: "gSTag" })}
                        <br />
                        <Link to="" className="text-black">
                          {toGsTag}
                        </Link>
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
            <div
              className=""
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <button
                className="btn btn-warning col-lg-12 "
                disabled={
                  !(
                    gsTagUsersAcount &&
                    gsTagUsersFirstName &&
                    gsTagUsersLastName
                  )
                }
                onClick={goToNextStep}
              >
                {intl.formatMessage({ id: "continuebtn" })}
              </button>
            </div>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div data-aos="fade-left">
          <div className=" p-6 bg-white container  mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded">
            <div className="d-flex justify-content-between ">
              <i
                className="fs-1 bi bi-arrow-left-circle-fill"
                onClick={goToPreviousStep}
              ></i>
              <div className="text-center">
                <h3 className="" style={{ marginLeft: "200px" }}>
                  {" "}
                  {intl.formatMessage({ id: "sendamount" })}
                </h3>
              </div>
              <span className="fw-semibold">
                <div className="fv-row" style={{ marginTop: "4rem" }}>
                  <label className="form-label mb-3 p-3">
                    {intl.formatMessage({ id: "enterAmount" })}
                  </label>
                  <input
                    type="number"
                    min="0"
                    className="mb-3 form-control form-control-lg form-control-solid"
                    name="account_name"
                    placeholder={intl.formatMessage({ id: "AMOUNT" })}
                    value={amount}
                    onKeyDown={(e: any) => {
                      exceptThisSymbols.includes(e.key) && e.preventDefault();

                      if (
                        e.target.value.length >= 15 &&
                        e.key !== "Backspace" &&
                        e.key !== "Delete"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={handleAmount
                    
                    }
                  />
                  {amountError && (
                    <>
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                        }}
                      >
                        {amountError}
                      </span>
                    </>
                  )}
                </div>
                <br />
              </span>
            </div>

            <div className=" fv-row ">
              <div className="row mb-2" data-kt-buttons="true">
                <span style={{ marginTop: "-4rem" }}>
                  {intl.formatMessage({ id: "balanceis" })} :{" "}
                  {intl.formatMessage({ id: "xau" })}{" "}
                  {gsAccountBalance > 0
                    ? gsAccountBalance + ""
                    : gsAccountBalance}
                </span>
                <div className="d-flex justify-content-between mt-10">
                  <span>
                    {" "}
                    {intl.formatMessage({ id: "transactionFees" })} :{" "}
                  </span>
                  <div className="fv-row">
                    <input
                      type="number"
                      className="mb-3 form-control form-control-lg form-control-solid"
                      name="account_name"
                      disabled={true}
                      // placeholder={intl.formatMessage({ id: "AMOUNT" })}
                      value={0.0005}
                      // onKeyDown={(e: any) => {
                      //   exceptThisSymbols.includes(e.key) && e.preventDefault();

                      //   if (
                      //     e.target.value.length >= 7 &&
                      //     e.key !== "Backspace" &&
                      //     e.key !== "Delete"
                      //   ) {
                      //     e.preventDefault();
                      //   }
                      // }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-10">
                  <span>
                    {" "}
                    {intl.formatMessage({ id: "remainingAmount" })} :{" "}
                  </span>
                  <div className="fv-row">
                    <input
                      type="number"
                      className="mb-3 form-control form-control-lg form-control-solid"
                      name="account_name"
                      // placeholder={intl.formatMessage({ id: "AMOUNT" })}
                      disabled={true}
                      // value={amount< 0.0005?amount-0.0005:0}
                      value={amount > 0.0005 ? amount - 0.0005 : 0}
                      // onKeyDown={(e: any) => {
                      //   exceptThisSymbols.includes(e.key) && e.preventDefault();

                      //   if (
                      //     e.target.value.length >= 7 &&
                      //     e.key !== "Backspace" &&
                      //     e.key !== "Delete"
                      //   ) {
                      //     e.preventDefault();
                      //   }
                      // }}
                    />
                  </div>
                </div>
                <label className="d-flex align-items-center form-label mb-3 mt-10">
                  {intl.formatMessage({ id: "choosecoin" })}
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="Provide your team size to help us setup your billing"
                  ></i>
                </label>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="5"
                    />
                    <span className="fw-bold fs-3">5</span>
                  </label>
                </div>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="10"
                    />
                    <span className="fw-bold fs-3">10</span>
                  </label>
                </div>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="20"
                    />
                    <span className="fw-bold fs-3">20</span>
                  </label>
                </div>
                <div className="col">
                  <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                    <input
                      type="radio"
                      className="btn-check"
                      name="account_team_size"
                      onClick={(e: any) => amountHandle(e.target.value)}
                      value="50"
                    />
                    <span className="fw-bold fs-3">50</span>
                  </label>
                </div>
                <div className="">
                  <button
                    className="btn btn-warning col-lg-12 mt-10  "
                    disabled={
                      !(amount > 0) ||
                      amount > gsAccountBalance ||
                      amount <= 0.0005
                    }
                    onClick={() => {
                      goToNextStep();
                    }}
                  >
                    {intl.formatMessage({ id: "continuebtn" })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {currentStep === 3 && (
        <div
          className=""
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className="bg-white mt-10  ">
            <div className=" p-10 container col-lg-12 shadow-sm p-4 mb-5 bg-body-tertiary rounded bg-white">
              <h2 className="text-center">
                {intl.formatMessage({ id: "confirm" })}
              </h2>
              <p className="text-center mt-10 fs-3">
                {intl.formatMessage({
                  id: "pleaseReviewTheDetailSandConfirmYourTransfer",
                })}
              </p>

              <div className="container col-lg-12">
                <div className="d-flex justify-content-between align-items-start mt-20">
                  <div className="">
                    {" "}
                    <span>{intl.formatMessage({ id: "from" })}</span>
                  </div>

                  <div className="">
                    {" "}
                    <span className="fw-bolder"> {selfGsTag}</span>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-start mt-10">
                  <div className="">
                    {" "}
                    <span>{intl.formatMessage({ id: "to" })}</span>
                  </div>

                  <div className="">
                    {" "}
                    <span className="fw-bolder">{toGsTag}</span> <br />
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-start mt-10">
                  <div className="">
                    {" "}
                    <span>{intl.formatMessage({ id: "AMOUNT" })}</span>
                  </div>

                  <div className="">
                    {" "}
                    <span className="fw-bolder">
                      {" "}
                      {intl.formatMessage({ id: "xau" })} {amount}
                    </span>{" "}
                    <br />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-start mt-10">
                  <div className="">
                    <span>
                      {" "}
                      {intl.formatMessage({ id: "transactionFees" })}
                    </span>
                  </div>

                  <div className="">
                    <span className="fw-bolder">
                      {intl.formatMessage({ id: "xau" })} {0.0005}
                    </span>
                    <br />
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-start mt-10">
                  <div className="">
                    <span>{intl.formatMessage({ id: "remainingAmount" })}</span>
                  </div>

                  <div className="">
                    <span className="fw-bolder">
                      {intl.formatMessage({ id: "xau" })} {amount - 0.0005}
                    </span>
                    <br />
                  </div>
                </div>

                <div className="mt-10  d-flex justify-content-between">
                  {" "}
                  <button
                    className="btn btn-warning "
                    onClick={() => goToPreviousStep()}
                  >
                    {intl.formatMessage({ id: "cancelsend" })}
                  </button>{" "}
                  {pinVerify ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-warning"
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                        disabled={isDisabled}
                        onClick={SendAmmountData}
                      >
                        {intl.formatMessage({ id: "confirmpin" })}
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-warning "
                      disabled={toggle2}
                      onClick={SendAmmountData}
                    >
                      {intl.formatMessage({ id: "finishSend" })}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
