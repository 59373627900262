/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { Link } from "react-router-dom";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { useLayout } from "../../core";
import { HeaderToolbar } from "./HeaderToolbar";
import { AdminHeaderToolbar } from "./AdminHeader/AdminHeaderToolbar ";
import { useIntl } from "react-intl";

export function HeaderWrapper() {
  const intl = useIntl();
  const { config, classes, attributes } = useLayout();
  const { aside } = config;

  const role = sessionStorage.getItem("role");

  return (
    <div
      id="kt_header"
      className={clsx(
        "header",
        classes.header.join(" "),
        "align-items-stretch"
      )}
      {...attributes.headerMenu}
    >
      <div className="header-brand">
        {role === "User" ? (
          <Link to="/wallet" className="d-flex  mt-2">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logofilegsapp.png")}
              className="h-25px h-lg-25px"
            />
            <h3 className="text-white ms-2 mt-1">
              {intl.formatMessage({ id: "gsApp" })}
            </h3>
          </Link>
        ) : role === "Admin" ? (
          <Link to="/user" className="d-flex  mt-2">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/logofilegsapp.png")}
              className="h-25px h-lg-25px"
            />
            <h3 className="text-white ms-2 mt-1">
              {intl.formatMessage({ id: "gsApp" })}
            </h3>
          </Link>
        ) : null}

        {aside.minimize && (
          <div
            id="kt_aside_toggle"
            className="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
          >
            <KTIcon
              iconName="exit-left"
              className="fs-1 me-n1 minimize-default"
            />
            <KTIcon iconName="entrance-left" className="fs-1 minimize-active" />
          </div>
        )}

        <div
          className="d-flex align-items-center d-lg-none ms-n3 me-1"
          title="Show aside menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-30px h-30px"
            id="kt_aside_mobile_toggle"
          >
            <KTIcon iconName="abstract-14" className="fs-1" />
          </div>
        </div>
      </div>

      {role === "User" ? (
        <HeaderToolbar />
      ) : role === "Admin" ? (
        <AdminHeaderToolbar />
      ) : null}
    </div>
  );
}
