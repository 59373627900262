/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Services from "../../Services";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "../../scss/Card.module.scss";
import Toster from "../../Components/AdminPanelToster";
import Constant from "../../Constant";
import FundListAdmin from "./FundListAdmin";
import MoveListAdmin from "./MoveListAdmin";
import SendListAdmin from "./SendListAdmin";
import WithdrawalListAdmin from "./WithdrawalListAdmin";
import ReceivedTransaction from "./ReceivedTransaction";
interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

function ViewDetails() {
  const location = useLocation();
  const imagePic = Constant.BASE_URL_UPLOADS;
  const [Approve, setApprove] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [show, setshow] = useState(1);
  const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
  const { id } = useParams();

  let navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [gsTag, setGSTag] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dob, setDOB] = useState("");
  const [address, setAddress] = useState("");
  const [image, setImage] = useState("");
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [useridDetails, setuseridDetails] = useState({
    isIdentityVerify: 0,
    comment: 1,
  });

  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });
  const [comment, setComment] = useState<string>("");
  const [isSubmit, setIsSubmit] = useState(0);

  const updateApprove = async () => {
    setIsDisable(true);
    setComment("");
    setApprove("1");
    setShowApproveConfirmation(true);
    setIsSubmit(1);
    const data: any = { isIdentityVerify: 1 };
    try {
      const response: any = await Services.User.UPDATE_VERIFY_BY_ID(id, data);

      if (response.data) {
        setTimeout(() => {
          navigate("/user");
          window.location.reload();
        }, 700);
        setToaster({
          type: "success",
          title: "Successfull",
          text: "Approved successfully",
          visiblity: "show",
        });
      }
    } catch (error) {
      setIsDisable(false);
      setIsSubmit(0);

      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);
    }
  };
  const updateReject = async () => {
    setIsDisable(true);
    setIsSubmit(3);
    const data: any = { isIdentityVerify: 3, comment };

    try {
      const response: any = await Services.User.UPDATE_VERIFY_BY_ID(id, data);
      if (response.data) {
        setTimeout(() => {
          navigate("/user");
          window.location.reload();
        }, 700);
        setToaster({
          type: "success",
          title: "Successfull",
          text: "Rejected successfully",
          visiblity: "show",
        });
        setIsSubmit(3);
      }
    } catch (error) {
      setIsDisable(false);
      setIsSubmit(3);

      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);
    }
  };

  const getBYID = async () => {
    try {
      const response: any = await Services.User.GET_BY_ID(id);
      setuseridDetails(response.data.data);

      setGSTag(response.data.data.gsTag);
      setFirstName(response.data.data.firstName);
      setImage(response.data.data.image);
      setLastName(response.data.data.lastName);
      setEmail(response.data.data.email);
      setPhoneNumber(response.data.data.phoneNumber);
      setDOB(response.data.data.dob);
      setAddress(response.data.data.address);
    } catch(error) {console.log(error);}
  };

  const handlesubmit = (image) => {
    setSelectedImage(image);
    setIsImageVisible(true);
  };
  const handleCloseImage = () => {
    setSelectedImage(null);
    setIsImageVisible(false);
  };
  function handlecomment(e) {
    if (e.key === " " && e.target.value.trim() === "") {
      e.preventDefault();
    }
  }
  const handlefund = () => {
    setshow(1);
  };

  const handlemove = () => {
    setshow(2);
  };

  const handleSend = () => {
    setshow(3);
  };
  const handleWithdrawal = () => {
    setshow(4);
  };
  const handleReceived = () => {
    setshow(5);
  };

  const handleApproveClick = () => {
    setShowApproveConfirmation(true);
    setShowRejectConfirmation(false);
  };

  const handleRejectClick = () => {
    setShowRejectConfirmation(true);
    setShowApproveConfirmation(false);
  };
  const handleComment = (e)=>{
    setComment(e.target.value);
    setIsDisable(false);
  }

  useEffect(() => {
    getBYID();
    handlefund();
  }, []);

  return (
    <div>
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />

      <div className="" data-aos="fade-up-left">
        <div className={`d-flex justify-content-around `}>
          <div
            className={`col-lg-8  bg-white p-20 shadow p-3 mb-5 bg-body-tertiary rounded`}
          >
            <div className="">
              <div className="">
                <div>
                  <div className="d-flex justify-content-between gap-4">
                    <div className=" mt-6  col-6 rounded p-4 mb-2   bg-body-secondary text-emphasis-dark">
                      <label> GS Tag : </label>
                      <span className="ms-3">
                        {" "}
                        {gsTag.length > 10 ? `${gsTag.slice(0, 10)}...` : gsTag}
                      </span>
                    </div>

                    <div className=" d-flex justify-content-between mt-6  col-6 rounded p-3 mb-2   bg-body-secondary text-emphasis-dark">
                      <div className="">
                        <label> Image : </label>
                      </div>
                      <div className="ms-2">
                        <button
                          onClick={() => handlesubmit(image)}
                          className="btn btn-secondary"
                          disabled={!image}
                        >
                          See Document
                        </button>
                      </div>
                    </div>

                    {isImageVisible && (
                      <div className="image-modal">
                        <div className="image-container"></div>
                        <img
                          crossOrigin="anonymous"
                          src={imagePic + selectedImage}
                          alt="image"
                          className={styles.fullscreenimage}
                        />

                        <button
                          className={styles.closebutton}
                          onClick={handleCloseImage}
                        >
                          <i className="fs-1 bi bi-x-circle-fill"></i>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between gap-3">
                <div className="mt-6  col-6 rounded p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> First Name : </label>
                  <span
                    className="ms-3"
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      maxWidth: "10ch",
                    }}
                  >
                    {" "}
                    {firstName}
                  </span>
                </div>
                <div className="mt-6  col-6 rounded p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> Last Name : </label>
                  <span
                    className="ms-3"
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      maxWidth: "10ch",
                    }}
                  >
                    {" "}
                    {lastName}
                  </span>
                </div>
              </div>

              <div className="gap-3 d-flex justify-content-between">
                <div className="mt-6  col-lg-6 p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> email : </label>
                  <span
                    className="ms-3"
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      maxWidth: "10ch",
                    }}
                  >
                    {" "}
                    {email}
                  </span>
                </div>
                <div className="mt-6  col-lg-6 p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> Phone Number : </label>
                  <span className="ms-3">
                    {" "}
                    {phoneNumber.length > 10
                      ? `${phoneNumber.slice(0, 15)}`
                      : phoneNumber}
                  </span>
                </div>
              </div>

              <div className="gap-3 d-flex justify-content-between">
                <div className="mt-6  col-lg-6 p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> DOB : </label>
                  <span className="ms-3"> {dob}</span>
                </div>
                <div className="mt-6  col-lg-6 p-3 mb-2   bg-body-secondary text-emphasis-dark">
                  <label> Address : </label>
                  <span
                    className="ms-3"
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      maxWidth: "10ch",
                    }}
                  >
                    {" "}
                    {address}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={` ms-10  bg-white p-10 shadow p-3 mb-5 bg-body-tertiary rounded ${styles.box}`}
          >
            <div className="position-relative w-lg-250px ">
              <div>
                <div className=" flex-column p-3 d-flex justify-content-between ">
                  {useridDetails?.isIdentityVerify === 0 ||
                  useridDetails?.isIdentityVerify === 2 ? (
                    <>
                      <div
                        className="form-group mb-5 "
                        data-aos="fade-up-right"
                      >
                        <label className=" fs-6 fw-semibold mb-2">
                          Comment <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control form-control-solid"
                          id="exampleFormControlTextarea1"
                          placeholder="Enter comment"
                          rows={6}
                          value={comment}
                          onKeyDown={(e) => {
                            handlecomment(e);
                          }}
                          onChange={
                           handleComment
                          }
                        />
                        <div className="mt-10 ms-1">
                          <span
                            className={`badge badge-pill ${
                              comment && comment?.length < 251
                                ? "badge-success"
                                : "badge-danger"
                            }`}
                          >
                            {comment?.length ? comment?.length : 0}/250
                          </span>
                        </div>
                      </div>

                      <div
                        className="d-flex justify-content-between gap-5"
                        data-aos="fade-up-left"
                      >
                        <button
                          type="button"
                          className="mt-10 fs-3 btn btn-success "
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          disabled={!image}
                          onClick={handleApproveClick}
                        >
                          <span className=" svg-icon svg-icon-2 text-start"></span>
                          Approve
                        </button>

                        <div className="me-0">
                          <button
                            type="button"
                            className="mt-10 fs-3 btn btn-danger "
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            disabled={
                              !comment || comment?.length > 250 || !image
                            }
                            onClick={handleRejectClick}
                          >
                            <span className="svg-icon svg-icon-2 text-start"></span>
                            Reject
                          </button>
                        </div>
                      </div>
                    </>
                  ) : useridDetails?.isIdentityVerify === 1 ? (
                    <div
                      className="d-flex flex-column"
                      data-aos="fade-down-right"
                    >
                      Status:
                      <div className=" text-success">Approved</div>
                    </div>
                  ) : (
                    <div className="d-flex flex-column" data-aos="fade-up-left">
                      Comment :
                      <div
                        className=" text-muted mb-3"
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          maxWidth: "30ch",
                        }}
                      >
                        {useridDetails?.comment}
                      </div>
                      Status:
                      <div className=" text-danger">Rejected</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === "" && "active")
                }
                to=""
                onClick={handlefund}
              >
                Fund Transaction
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === "" && "active")
                }
                to=""
                onClick={handlemove}
              >
                Move Transaction
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === "" && "active")
                }
                to=""
                onClick={handleSend}
              >
                Send Transaction
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === "" && "active")
                }
                to=""
                onClick={handleWithdrawal}
              >
                Withdrawal Transaction
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === "" && "active")
                }
                to=""
                onClick={handleReceived}
              >
                Received Transaction
              </Link>
            </li>
          </ul>
        </div>
        {show === 1 && <FundListAdmin />}
        {show === 2 && <MoveListAdmin />}
        {show === 3 && <SendListAdmin />}
        {show === 4 && <WithdrawalListAdmin />}
        {show === 5 && <ReceivedTransaction />}
      </div>

      <>
        {showRejectConfirmation && (
          <div
            className="modal fade"
            id="exampleModalCenter"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="reject"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Confirmation
                  </h5>
                </div>
                <div className="modal-body">
                  Are you sure you want to Reject?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={isDisable}
                    onClick={() => updateReject()}
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>

      <>
        {showApproveConfirmation && (
          <div
            className="modal fade"
            id="exampleModalCenter"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="reject"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Confirmation
                  </h5>
                </div>
                <div className="modal-body">
                  Are you sure you want to approve?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={isDisable}
                    onClick={() => updateApprove()}
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
}

export default ViewDetails;
