import { FC, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import Wallet from "../../Views/Wallet";
import Card from "../../Views/Card";
import Transact from "../../Views/Transact";
import Move from "../../Views/Move";
import Send from "../../Views/Send";
import MakeWithdraw from "../../Views/MakeWithdraw";
import Setting from "../../Views/Setting";
import Profile from "../../Views/Profile/Profile";
import AddCard from "../../Views/AddCard";
import { AuthPage } from "../modules/auth";
import AddcardMessage from "../../Views/Card/AddcardMessage";
import FundAccount from "../../Views/Accounts/FundAccount";
import Withdraw from "../../Views/Accounts/Withdraw";
import ConfirmDetails from "../../Views/Accounts/ConfirmDetails";
import AccountSetting from "../../Views/Setting/Accountsetting/AccountSetting";
import VerifyIdentity from "../../Views/Setting/VerifyIdentity/VerifyIdentity";
import Confirm from "../../Views/Setting/VerifyIdentity/confirm";
import LanguageDisplaySetting from "../../Views/Setting/Accountsetting/LanguageDisplaySetting";
import Statment from "../../Views/Setting/Statment";
import Security from "../../Views/Setting/Accountsetting/Security";
import SetupPinForTransaction from "../../Views/Setting/Accountsetting/SetupPinForTransaction";
import PinSetup from "../../Views/Setting/Accountsetting/PinSetup";
import ChangePassword from "../../Views/Setting/Accountsetting/Changepassword/ChangePassword";
import ReviewBusinessinfo from "../../Views/Setting/VerifyIdentity/ReviewBusinessinfo";
import Reviewinfo from "../../Views/Setting/VerifyIdentity/Reviewinfo";
import PasswordSuccessfull from "../../Views/Setting/Accountsetting/Changepassword/PasswordSuccessfull";
import ConfirmExchange from "../../Views/Move&exchange/ConfirmExchange";
import TransactionSuccessfull from "../../Views/Move&exchange/TransactionSuccessfull";
import SendAmmount from "../../Views/Send/SendAmmount";
import ConfirmSendAmmount from "../../Views/Send/ConfirmSendAmmount";
import SendSuccessful from "../../Views/Send/SendSuccessful";
import BusinessAddress from "../../Views/Setting/VerifyIdentity/BusinessAddress";
import Businessinfo from "../../Views/Setting/VerifyIdentity/Businessinfo";
import Verification from "../../Views/Setting/VerifyIdentity/Verification";
import Info from "../../Views/Setting/VerifyIdentity/Info";
import CreateAccount from "../../Views/Setting/VerifyIdentity/CreateAccount";
import AccountFundding from "../../Views/Transact/AccountFundding";
import WithdrawSuccesfull from "../../Views/Transact/WithdrawSuccesfull";
import ConfirmWithdraw from "../../Views/Transact/ConfirmWithdraw";
import ConfirmWithdrawsuccessfull from "../../Views/Transact/ConfirmWithdrawsuccessfull";

import User from "../../Views/AdminPannel/User";
import TransactionList from "../../Views/AdminPannel/Transaction";
import ViewDetails from "../../Views/AdminPannel/ViewDetails";
import Support from "../../Views/Support";
import RecentTransactions from "../../Views/RecentTransactions";
import LogOut from "../../Views/LogOut";
import EditProfileDetials from "../../Views/Profile/EditProfileDetials";
import MyCard from "../../Views/Card/MyCard";
import SupportDetails from "../../Views/AdminPannel/SupportDetails";
import WIthdrawalPopUp from "../../Views/WIthdrawalPopUp/WIthdrawalPopUp";
import WithdrawalAppove from "../../Views/AdminPannel/WithdrawalAppove";
import ConfirmFundPrimary from "../../Views/Accounts/ConfirmFundPrimary";
import FundList from "../../Views/FundList";
import MoveList from "../../Views/MoveList";
import SendList from "../../Views/SendList";
import WithdrawalList from "../../Views/WithdrawalList";
import AddFundTransactions from "../../Views/AdminPannel/AddFundTransactions";
import WithdrawalTransaction from "../../Views/AdminPannel/WithdrawalTransaction";
import AddFundAppove from "../../Views/AdminPannel/AddFundApprove";
import AddFundList from "../../Views/Accounts/AddFundList";
import AddPayoutBankDetials from "../../Views/Accounts/AddPayoutBankDetials";
import ViewBankDetails from "../../Views/AdminPannel/BankDetails/ViewBankDetails";
import BankDetails from "../../Views/AdminPannel/BankDetails/BankDetails";
import EditBankDetails from "../../Views/AdminPannel/BankDetails/EditBankDetails";
import Received from "../../Views/Received";
import MoveTransactions from "../../Views/AdminPannel/MoveTransactions";
import SendTransacrtions from "../../Views/AdminPannel/SendTransacrtions";
import Country from "../../Views/AdminPannel/Country";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="/profile" element={<Profile />} />
        <Route path="/editprofiledetials" element={<EditProfileDetials />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/card" element={<Card />} />
        <Route path="/addcardmessage" element={<AddcardMessage />} />
        <Route path="/transact" element={<Transact />} />
        <Route path="/accountfundding1" element={<AccountFundding />} />
        <Route path="/withdrawsuccessfull" element={<WithdrawSuccesfull />} />
        <Route path="/confirmwithdraw" element={<ConfirmWithdraw />} />
        <Route
          path="/confirmwithdrawsuccessfull"
          element={<ConfirmWithdrawsuccessfull />}
        />
        <Route path="/fundaccount" element={<FundAccount />} />
        <Route path="/confirmfundprimary" element={<ConfirmFundPrimary />} />
        <Route path="/withdraw" element={<Withdraw />} />
        <Route path="/confirmdetails" element={<ConfirmDetails />} />
        <Route path="/move" element={<Move />} />
        <Route path="/confirmexchange" element={<ConfirmExchange />} />
        <Route
          path="/Transactionsuccessfull"
          element={<TransactionSuccessfull />}
        />
        <Route path="/send" element={<Send />} />
        <Route path="/sendammount" element={<SendAmmount />} />
        <Route path="/confirmsendammount" element={<ConfirmSendAmmount />} />
        <Route path="/sendsuccessful" element={<SendSuccessful />} />
        <Route path="/mycard" element={<MyCard />} />
        <Route path="/addcard" element={<AddCard />} />
        <Route path="/makeawithdraw" element={<MakeWithdraw />} />
        <Route path="/support" element={<Support />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/accountsetting" element={<AccountSetting />} />
        <Route path="/confirm" element={<Confirm />}></Route>
        <Route path="/verifyidentity" element={<VerifyIdentity />} />
        <Route path="/withdrawalappove" element={<WithdrawalAppove />} />
        <Route path="/addfundappove" element={<AddFundAppove />} />
        <Route path="/addfundlist" element={<AddFundList />} />
        <Route
          path="/addpayoutbankdetials"
          element={<AddPayoutBankDetials />}
        />

        {/* /addpayoutbankdetials */}
        <Route
          path="/reviewbusinessinfo"
          element={<ReviewBusinessinfo />}
        ></Route>
        <Route path="/reviewinfo" element={<Reviewinfo />}></Route>
        <Route path="/businessaddress" element={<BusinessAddress />}></Route>
        <Route path="/businessinfo" element={<Businessinfo />}></Route>
        <Route path="/verification" element={<Verification />}></Route>
        <Route path="/info" element={<Info />}></Route>
        <Route path="/createaccount" element={<CreateAccount />}></Route>
        <Route
          path="/languagedisplaysetting"
          element={<LanguageDisplaySetting />}
        />
        <Route path="/statments" element={<Statment />} />
        <Route path="/security" element={<Security />} />
        <Route path="/setuptransaction" element={<SetupPinForTransaction />} />
        <Route path="/pinsetup" element={<PinSetup />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/passwordsuccessfull" element={<PasswordSuccessfull />} />
        <Route path="/logout" element={<AuthPage />} />
        <Route path="/logoutgs" element={<LogOut />} />
       
        <Route path="/user" element={<User />} />
        <Route path="/transactionlist" element={<TransactionList />} />
        <Route path="/supportdetails" element={<SupportDetails />} />
        <Route path="/country" element={<Country />} />
        <Route path="/bankdetails" element={<BankDetails />} />
        <Route path="/viewbankdetails" element={<ViewBankDetails />} />
        <Route path="/editbankdetails/:id" element={<EditBankDetails />} />

        <Route path="/withdrawalpopup" element={<WIthdrawalPopUp />} />
        <Route
          path="MainMenu/addcard/*"
          element={
            <SuspensedView>
              <AddCard />
            </SuspensedView>
          }
        />

        {/* SHEETAL ROUTES START */}
        <Route
          path="/viewdetails/:id"
          element={
            <SuspensedView>
              <ViewDetails />
            </SuspensedView>
          }
        />
        <Route
          path="/transactionlist/1"
          element={
            <SuspensedView>
              <TransactionList />
              <AddFundTransactions />
            </SuspensedView>
          }
        />
        <Route
          path="/transactionlist/2"
          element={
            <SuspensedView>
              <TransactionList />
              <WithdrawalTransaction />
            </SuspensedView>
          }
        />
        <Route
          path="/transactionlist/3"
          element={
            <SuspensedView>
              <TransactionList />
              <MoveTransactions />
            </SuspensedView>
          }
        />
        <Route
          path="/transactionlist/4"
          element={
            <SuspensedView>
              <TransactionList />
              <SendTransacrtions />
            </SuspensedView>
          }
        />
        <Route
          path="/transactionlist/5"
          element={
            <SuspensedView>
              <TransactionList />
              <RecentTransactions />
            </SuspensedView>
          }
        />

        <Route
          path="/recenttransaction/1"
          element={
            <SuspensedView>
              <RecentTransactions />
              <FundList />
            </SuspensedView>
          }
        />
        <Route
          path="/recenttransaction/1"
          element={
            <SuspensedView>
              <RecentTransactions />
              <FundList />
            </SuspensedView>
          }
        />
        <Route
          path="/recenttransaction/2"
          element={
            <SuspensedView>
              <RecentTransactions />
              <MoveList />
            </SuspensedView>
          }
        />
        <Route
          path="/recenttransaction/3"
          element={
            <SuspensedView>
              <RecentTransactions />
              <SendList />
            </SuspensedView>
          }
        />
        <Route
          path="/recenttransaction/4"
          element={
            <SuspensedView>
              <RecentTransactions />
              <WithdrawalList />
            </SuspensedView>
          }
        />
        <Route
          path="/recenttransaction/getReceivedTracnsactionMe/3"
          element={
            <SuspensedView>
              <RecentTransactions />
              <Received />
            </SuspensedView>
          }
        />

        {/* SHEETAL ROUTES END */}

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
