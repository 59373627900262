/* eslint-disable react/jsx-no-target-blank */

import { AdminAsideMenuItem } from "./AdminAsideMenuItem";

export function AdminAsideMenuMain() {
  return (
    <>
      <AdminAsideMenuItem to="/user" title="User" />

      <AdminAsideMenuItem to="/supportdetails" title="Support Details" />
      {/* <AdminAsideMenuItem to="/bankdetails" title="Bank Details" /> */}
      <AdminAsideMenuItem to="/transactionlist/1" title="Transactions" />
      <AdminAsideMenuItem to="/country" title="Country" />
      <AdminAsideMenuItem to="/logoutgs" title="Logout" />
    </>
  );
}
