import React from "react";
import { Link } from "react-router-dom";
import styles from "../../../scss/Card.module.scss";
import { useIntl } from "react-intl";

function Confirm() {
  const intl = useIntl();
  return (
    <div
      className=""
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <div className="d-flex">
        <div>
          <Link to="/verifyidentity">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
              height={20}
              width={30}
              alt=""
            />
          </Link>
        </div>
        <h2 className={styles.head}>
          {intl.formatMessage({ id: "confirmYourIdentity" })}
        </h2>
      </div>
      <div className="bg-white conatiner text-center mt-8 p-2 shadow-sm p-10 mb-5  rounded">
        <div className="container p-2 text-dark bg-opacity-25 col-6 p-5 flex-end rounded ">
          <p className="text-muted">
            Your identification documents will help us to validate your identity
            , comply with regulations and secure your account{" "}
          </p>
        </div>
        <div className="container text-dark bg-opacity-25 col-5    flex-end rounded ">
          <div className="text-center align-items-start">
            <div className="text-start">
              {intl.formatMessage({ id: "howToConfirmMyIdentity" })}
            </div>
            <div className=" text-start mt-3">
              <p>
                <span>1.</span>
                <span className="ms-2">
                  Take a selfie by holding your Id card and most recent
                  statement{" "}
                </span>
              </p>
              <p>
                2. Cardholder name and ID should match and be clearly visible
              </p>
              <p>
                3. Your identification documents should be close to your face
              </p>
              <p>
                4. Upload a scanned copy of your business registration and valid
                government ID
              </p>
            </div>
          </div>
          <div>
            <div className="mt-10">Here is an example for selfie :</div>
            <div className="d-flex">
              <div className="container bg-warning p-6 text-dark bg-opacity-25 col-7 p-20 mt-10 flex-end rounded"></div>

              <div className="container bg-warning p-6 text-dark bg-opacity-25 col-7 p-20 mt-10 flex-end rounded ms-6"></div>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-10">
            <button className="btn btn-bg-warning m-3 col-lg-6 " type="submit">
              Correct
            </button>

            <button
              className="btn btn-bg-warning m-3 col-lg-6 ms-15 "
              type="submit"
            >
              Incorrect
            </button>
          </div>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input "
              style={{ display: "none" }}
              id="customFile"
              onChange={(e) => {}}
            />
            <div className="mt-5 d-flex justify-content-between ">
              <label
                className=" custom-file-label  container bg-warning  text-dark bg-opacity-25 flex-end rounded  col-lg-12"
                style={{ content: "Browse", cursor: "pointer" }}
                htmlFor="customFile"
              >
                <p className="text-start p-2 mt-2"> Choose file to Upload</p>
              </label>
              <button className="btn btn-bg-warning ">Browser</button>
            </div>
          </div>
        </div>
        <Link to="/">
          <button
            className=" mt-10 btn btn-warning m-5 col-lg-6 ms-20"
            type="submit"
          >
            Confirm ID
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Confirm;
