/* eslint-disable import/no-anonymous-default-export */
export default {
  GET_GS_ACCOUNT:() => `/user/dashboard`,
  GET_CONFIRM_DETIALS:(id)=>`/card/getFund/${id}`,
    GET_GS_CARD: () => `/user/dashboard`,
    // ADD_FUND: () => `/card/addFund`,
    GET_ALL_USER_FUND_TRANSACTION: (query) => `/addFund/getAll?${query ? query : ""}`,
    GET_USER_ADDFUND_TRANSACTIONS_BY_ID:(id)=>`/addFund/${id}`,
   
    ADD_FUND: () => `/addFund/addFund/`,
    GET_USER_FUND_TRANSACTION: (query) => `/addFund/me?${query ? query : ""}`,


   
  };
//   /gsAccount/addBalance