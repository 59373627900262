import React from "react";
import { Link } from "react-router-dom";
import styles from "../../scss/Card.module.scss";
import { useIntl } from "react-intl";

export default function SendAmmount() {
  const intl = useIntl();
  return (
    <div
      className=""
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <div className="d-flex">
        <div>
          <Link to="/send">
            <i className="fs-1 bi bi-arrow-left-circle-fill"></i>
          </Link>
        </div>
        <h2 className={styles.head}>Enter Send Amount</h2>
      </div>

      <div className=" bg-white container mt-8 p-8 shadow-sm p-3 mb-5 bg-body-tertiary rounded">
        <div className="col-lg-12 d-flex justify-content-between bg-white">
          <div className="col-lg-10 ms-2">
            {" "}
            <h3>{intl.formatMessage({ id: "AMOUNT" })}</h3>
          </div>
          <span className="fw-semibold me-2">0.00</span>
        </div>
        <div className="grid gap-4 d-flex justify-content-between mt-2">
          <input
            type="number"
            name=""
            className="form-control "
            id=""
            placeholder="$50"
          />
          <input
            type="number"
            name=""
            className="form-control ms-2"
            id=""
            placeholder="$100"
          />
          <input
            type="number"
            name=""
            className="form-control ms-2"
            id=""
            placeholder="$250"
          />
        </div>
        <div className="  mt-20">
          {" "}
          <Link className="" to="/confirmsendammount">
            {" "}
            <button className="btn btn-warning col-12 mb-10">
              {" "}
              {intl.formatMessage({ id: "continuebtn" })}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
