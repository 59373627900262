/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Services from "../Services";
import { useIntl } from "react-intl";

export default function Setting() {
  const intl = useIntl();
  const [isIdentityverify, setIsIdentityverify] = useState<any>();
  const [firstName, setFirstName] = useState();
  const [LastName, setLastName] = useState();
  const [GsTag, setGsTag] = useState();
  const GET_USER = async () => {
    try {
      const response: any = await Services.User.GET_USER();
      setFirstName(response?.data?.data?.firstName);
      setLastName(response?.data?.data?.lastName);
      setGsTag(response?.data?.data?.gsTag);
      if (response) {
        if (response?.data?.data?.isIdentityVerify === 1) {
          setIsIdentityverify(response?.data?.data?.isIdentityVerify);
        } else {
          setIsIdentityverify(response.data.data.isIdentityVerify);
        }
      }
    } catch(error) {console.log(error);}
  };

  useEffect(() => {
    GET_USER();
  }, []);
  return (
    <div
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <div className="p-10 bg-white shadow-sm  mb-5 bg-body-tertiary rounded">
        <h2 className="text-center">
          {intl.formatMessage({ id: "settings" })}
        </h2>

        <div className=" bg-white container mt-6 ">
          <div
            className="container mt-10 "
            data-aos="fade-left"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="500"
          >
            <h2>
              {intl.formatMessage({ id: "goodDay" })}
              {/* &nbsp;
              <span
               style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          maxWidth: "10ch",
                        }}
              >
                {firstName}&nbsp;{LastName}
              </span> */}
              &nbsp;
              <span>{GsTag}</span>
            </h2>

            <div className="mt-10 d-flex justify-content-sm-between">
              <h6>{intl.formatMessage({ id: "accountVerified" })} </h6>
              <span className="svg-icon svg-icon-primary svg-icon-2x">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <circle
                      fill="#000000"
                      opacity="0.3"
                      cx="12"
                      cy="12"
                      r="10"
                    />
                    <path
                      d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                      fill="#000000"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </span>
            </div>

            <div className="mt-5 d-flex justify-content-sm-between">
              <h6>{intl.formatMessage({ id: "emailAddress" })}</h6>
              <span className="svg-icon svg-icon-primary svg-icon-2x">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <circle
                      fill="#000000"
                      opacity="0.3"
                      cx="12"
                      cy="12"
                      r="10"
                    />
                    <path
                      d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                      fill="#000000"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </span>
            </div>

            <div className="mt-5 d-flex justify-content-sm-between">
              <h6>{intl.formatMessage({ id: "phoneNumber" })}</h6>
              <span className="svg-icon svg-icon-primary svg-icon-2x">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <circle
                      fill="#000000"
                      opacity="0.3"
                      cx="12"
                      cy="12"
                      r="10"
                    />
                    <path
                      d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                      fill="#000000"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>

          <div
            className="p-5"
            data-aos="fade-left"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="500"
          >
            <Link to="/profile">
              <button className="btn btn-warning col-lg-12 mt-10 ">
                {intl.formatMessage({ id: "updateGsProfilebtn" })}
              </button>
            </Link>
          </div>
        </div>
        <div
          className="d-flex justify-content-start ms-4 m-10 ms-8"
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className="  col-lg-12  ">
            <Link to="/accountsetting">
              <div className="conatiner d-flex justify-content-between mt-5">
                <div>
                  <h5>{intl.formatMessage({ id: "accountSetting" })}</h5>
                </div>
                <div className="mt-1">
                  <Link to="/accountsetting">
                    <span className="svg-icon svg-icon-primary svg-icon-2x">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                            fill="#000000"
                            fillRule="nonzero"
                            transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                          />
                        </g>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </Link>

            {isIdentityverify === 0 && (
              <Link to="/verifyidentity">
                <div className="conatiner d-flex justify-content-between mt-5">
                  <div>
                    <h5>{intl.formatMessage({ id: "verifyYourIdentity" })}</h5>
                  </div>
                  <div className="mt-1">
                    <Link to="/verifyidentity">
                      <span className="svg-icon svg-icon-primary svg-icon-2x">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                            />
                          </g>
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </Link>
            )}

            {isIdentityverify === 2 && (
              <div className="conatiner d-flex justify-content-between mt-5">
                <div>
                  <h5>{intl.formatMessage({ id: "verifyYourIdentity" })}</h5>
                </div>
                <div className="mt-1">
                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
            )}
            {isIdentityverify === 3 && (
              <Link to="/verifyidentity">
                <div className="conatiner d-flex justify-content-between mt-5">
                  <div>
                    <h5>{intl.formatMessage({ id: "verifyYourIdentity" })}</h5>
                  </div>
                  <div className="mt-1">
                    <Link to="/verifyidentity">
                      <span className="svg-icon svg-icon-primary svg-icon-2x">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <polygon points="0 0 24 0 24 24 0 24" />
                            <path
                              d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                            />
                          </g>
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </Link>
            )}
            {isIdentityverify === 1 && (
              <div className="conatiner d-flex justify-content-between mt-5">
                <div>
                  <h5>{intl.formatMessage({ id: "verifyYourIdentity" })}</h5>
                </div>
                <div className="mt-1">
                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
            )}

            <Link to="/security">
              <div className="conatiner d-flex justify-content-between mt-5">
                <div>
                  <h5>{intl.formatMessage({ id: "security&Privacy" })}</h5>
                </div>
                <div className="mt-1">
                  <Link to="/security">
                    <span className="svg-icon svg-icon-primary svg-icon-2x">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                            fill="#000000"
                            fillRule="nonzero"
                            transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                          />
                        </g>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </Link>
            <Link to="/addpayoutbankdetials">
              <div className="conatiner d-flex justify-content-between mt-5">
                <div>
                  <h5>{intl.formatMessage({ id: "addPayoutBankDetails" })}</h5>
                </div>
                <div className="mt-1">
                  <Link to="/addpayoutbankdetials">
                    <span className="svg-icon svg-icon-primary svg-icon-2x">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                            fill="#000000"
                            fillRule="nonzero"
                            transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                          />
                        </g>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </Link>

            <Link to="">
              <div className="conatiner d-flex justify-content-between mt-5">
                <div>
                  <h5>{intl.formatMessage({ id: "referFriend" })}</h5>
                </div>
                <div className="mt-1">
                  <span className="svg-icon svg-icon-primary  svg-icon-2x">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
            </Link>
            <div className="conatiner d-flex justify-content-between mt-5">
              <div>
                <Link to="/logoutgs">
                  <h5 className="text-warning">
                    {intl.formatMessage({ id: "logout" })}
                  </h5>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
