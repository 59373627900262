/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Services from "../../Services";
import Toster from "../../Components/AdminPanelToster";

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}
const AddFundAppove = () => {
  const [userAll, setUserAll] = useState<any>();
  const [textarea, setTextArea] = useState("");
  const location = useLocation();
  const data1: any = location?.state;
  const data2: any = data1?.data;
  const navigate = useNavigate();
  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });
  useEffect(() => {
    if (!userAll) {
      setUserAll({ ...data2 });
    }
  }, []);

  const getByID = async (id, data) => {
    try {
      const response: any =
        await Services.GsAccount.GET_USER_ADDFUND_TRANSACTIONS_BY_ID(id, data);
      } catch(error) {console.log(error);}
  };

  const updateApprove = () => {
    const id = userAll.id;
    const data = {
      balance: data2.amount,
      requestStatus: "Accepted",
      adminNotes: textarea,
    };

    getByID(id, data);
    setTextArea("");
    setTimeout(() => {
      navigate(`/transactionlist/1`);
    }, 2000);
    setToaster({
      type: "success",
      title: "Successful",
      text: "Accepted  successfully",
      visiblity: "show",
    });

    setTimeout(() => {
      setToaster({
        type: "",
        title: "",
        text: "",
        visiblity: "hide",
      });
    }, 1000);
  };

  const updateReject = () => {
    const id = userAll.id;
    const data = {
      balance: data2.amount,
      requestStatus: "Declined",
      adminNotes: textarea,
    };

    getByID(id, data);
    setTextArea("");

    setTimeout(() => {
      navigate(`/transactionlist/1`);
    }, 2000);
    setTimeout(() => {
      navigate(`/transactionlist/1`);
    }, 2000);
    setToaster({
      type: "success",
      title: "Successful",
      text: "Declined  successfully",
      visiblity: "show",
    });

    setTimeout(() => {
      setToaster({
        type: "",
        title: "",
        text: "",
        visiblity: "hide",
      });
    }, 1000);
  };
  const handleTextarea =(e)=>{
    setTextArea(e.target.value.trimStart());
  }
  return (
    <div>
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      <div className="d-flex justify-content-around">
        <div className="col-lg-8 bg-white p-10 shadow p-3 mb-5 bg-body-tertiary rounded">
          <div>
            <div className="gap-3 d-flex justify-content-between">
              <div className="mt-6  col-6  rounded p-3 mb-2   bg-body-secondary text-emphasis-dark">
                <label> User Name : </label>
                <span
                  className="ms-3"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "pre-wrap",
                    maxWidth: "20ch",
                    wordWrap: "break-word",

                    overflowWrap: "break-word",
                  }}
                >
                  {userAll?.User?.firstName} {userAll?.User?.lastName}
                </span>
              </div>
              <div className="mt-6  col-6 rounded p-3 mb-2   bg-body-secondary text-emphasis-dark">
                <label> Amount : </label>
                <span className="ms-3">{userAll?.amount}</span>
              </div>
            </div>
            <div className="gap-3 d-flex justify-content-between">
            <div className="mt-6  col-6 rounded p-3 mb-2   bg-body-secondary text-emphasis-dark">
              <label> GS Tag : : </label>
              <span
                className="ms-3"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "pre-wrap",
                  maxWidth: "20ch",
                  wordWrap: "break-word",

                  overflowWrap: "break-word",
                }}
              >
               {userAll?.User?.gsTag }
              </span>
            </div>
            <div className="mt-6  col-6 rounded p-3 mb-2   bg-body-secondary text-emphasis-dark">
              <label> Email : </label>
              <span
                className="ms-3"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "pre-wrap",
                  maxWidth: "20ch",
                  wordWrap: "break-word",

                  overflowWrap: "break-word",
                }}
              >
               {userAll?.User?.email }
              </span>
            </div>
            </div>
            <div className="gap-3 d-flex justify-content-between">
            <div className="mt-6  col-6 rounded p-3 mb-2   bg-body-secondary text-emphasis-dark">
              <label> User Notes : </label>
              <span
                className="ms-3"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "pre-wrap",
                  maxWidth: "20ch",
                  wordWrap: "break-word",

                  overflowWrap: "break-word",
                }}
              >
                {userAll?.userNotes }
              </span>
            </div>
            <div className="mt-6  col-6 rounded p-3 mb-2   bg-body-secondary text-emphasis-dark">
              <label> Bank Details : </label>
              <span
                className="ms-3"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "pre-wrap",
                  maxWidth: "20ch",
                  wordWrap: "break-word",

                  overflowWrap: "break-word",
                }}
              >
                {userAll?.User?.bankDetails}
              </span>
            </div>
         
            </div>
          </div>

          <div className="card  text-center"></div>
        </div>

        <div className=" ms-10  bg-white p-10 shadow p-3 mb-5 bg-body-tertiary rounded ">
          <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-300px mb-10 order-1 order-lg-2">
            <div
              className="card card-flush mb-0 "
              data-aos="fade-down"
              data-aos-anchor-placement="top-bottom"
              data-kt-sticky="true"
              data-kt-sticky-name="subscription-summary"
              data-kt-sticky-offset="{default: false, lg: '200px'}"
              data-kt-sticky-width="{lg: '250px', xl: '300px'}"
              data-kt-sticky-left="auto"
              data-kt-sticky-top="150px"
              data-kt-sticky-animation="false"
              data-kt-sticky-zindex="95"
            >
              <div className=" flex-column p-3 d-flex justify-content-between ">
                {userAll?.requestStatus === "Requested" && (
                  <>
                    <div className="form-group mb-5 " data-aos="fade-up-right">
                      <label className=" fs-6 fw-semibold mb-2">
                        Comment <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control form-control-solid"
                        id="exampleFormControlTextarea1"
                        placeholder="Enter comment"
                        rows={6}
                        value={textarea}
                        onChange={
                         handleTextarea
                        }
                      />
                      <div className="mt-10 ms-1">
                        <span
                          className={`badge badge-pill ${
                            textarea && textarea?.length < 251
                              ? "badge-success"
                              : "badge-danger"
                          }`}
                        >
                          {textarea?.length ? textarea?.length : 0}/250
                        </span>
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-between gap-5"
                      data-aos="fade-up-left"
                    >
                      <div className="me-0">
                        <button
                          className="mt-10 fs-3 btn btn-success "
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-flip="top-end"
                          disabled={!textarea || textarea?.length > 250}
                          onClick={updateApprove}
                        >
                          <span className=" svg-icon svg-icon-2 text-start"></span>
                          Accepted
                        </button>
                      </div>
                      <div className="me-0">
                        <button
                          className="mt-10 fs-4 btn btn-danger "
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-kt-menu-flip="top-end"
                          disabled={!textarea || textarea?.length > 250}
                          onClick={updateReject}
                        >
                          <span className="svg-icon svg-icon-2 text-start"></span>
                          Declined
                        </button>
                        <div
                          className="menu menu-sub menu-sub-dropdown w-250px w-md-500px"
                          data-kt-menu="true"
                        ></div>
                      </div>
                    </div>
                  </>
                )}
                {userAll?.requestStatus === "Accepted" && (
                  <div
                    className="d-flex flex-column"
                    data-aos="fade-down-right"
                  >
                    Comment :
                    <div className=" text-muted mb-3">
                      {userAll?.adminNotes}
                    </div>
                    Status:
                    <div className=" text-success">Approved</div>
                  </div>
                )}
                {userAll?.requestStatus === "Declined" && (
                  <div className="d-flex flex-column" data-aos="fade-up-left">
                    Comment :
                    <div className=" text-muted mb-3">
                      {userAll?.adminNotes}
                    </div>
                    Status:
                    <div className=" text-danger">Rejected</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <>
            <div
              className="modal fade"
              id="exampleModal"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Modal title
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">...</div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="button" className="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default AddFundAppove;
