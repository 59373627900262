/* eslint-disable import/no-anonymous-default-export */
import Auth from "./Auth";
import Transaction from "./Transaction";
import Card from "./Card";
import User from "./User";
import Wallet from "./Wallet"
import AccountLink from "./AccountLink"
import Support from "./Support";
import WithdrawalAc from "./WithdrawalAc";
import Move from "./Move"
import GsAccount from "./GsAccount";
import MonthlyStatments from "./MonthlyStatments";
import Country from "./Country"


export default {
  Auth,
  GsAccount,
  Transaction,
  Card,
  User,
  Wallet,
  AccountLink,
  Support,
  Move,
  WithdrawalAc,
  MonthlyStatments,
  Country
};
