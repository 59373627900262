/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Services from "../../../Services";
import Toster from "../../../Components/AdminPanelToster";

export default function EditBankDetails() {
  interface ToasterState {
    type: string;
    title: string;
    text: string;
    visiblity: string;
  }
  const { id } = useParams();
  let navigate = useNavigate();
  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const [beneficaryName, setBeneficaryName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [abARoutingNumber, setABARoutingNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [type, setType] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorbeneficaryName, setErrorBeneficaryName] = useState<string>("");
  const [erroraccountNumber, setErrorAccountNumber] = useState<string>("");
  const [errorbankAddress, setErrorBankAddress] = useState<string>("");
  const [errorabARoutingNumber, setErrorABARoutingNumber] =
    useState<string>("");
  const [errorbankName, setErrorBankName] = useState<string>("");
  const [errortype, setErrorType] = useState("");

  const exceptThisSymbolsnumber = ["+", "e", "E", "-", "/", "*", " "];

  const getbankdetails = async () => {
    try {
      const response: any = await Services.Wallet.GET_ADMIN_BANKDETAILS();
      setBeneficaryName(response?.data?.data?.html?.BeneficaryName);
      setAccountNumber(response?.data?.data?.html?.AccountNumber);
      setBankAddress(response?.data?.data?.html?.BankAddress);
      setABARoutingNumber(response?.data?.data?.html?.ABARoutingNumber);
      setBankName(response?.data?.data?.html?.BankName);
      setType(response?.data?.data?.type);
    } catch(error) {console.log(error);}
  };

  const handleSubmit = async () => {
    try {
      const data = {
        AccountNumber: accountNumber,
        BankName: bankName,
        BankAddress: bankAddress,
        ABARoutingNumber: abARoutingNumber,
        BeneficaryName: beneficaryName,
      };
      const response: any = await Services.Wallet.UPDATE_ADMIN_BANKDETAILS(id, {
        html: data,
        type: type,
      });
      if (response) {
        setIsDisabled(true);

        setTimeout(() => {
          navigate("/bankdetails");
        }, 1500);

        setToaster({
          type: "success",
          title: "Successfull",
          text: "Updated Bank Details successfully",
          visiblity: "show",
        });
      }
    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };

  function handlecomment(e) {
    if (e.key === " " && e.target.value.trim() === "") {
      e.preventDefault();
    }
  }
  const handleBeneficiaryName = (e) => {
    setBeneficaryName(e.target.value);
    if (!e.target.value.trim()) {
      setErrorBeneficaryName("Beneficiary name is required");
    } else {
      setErrorBeneficaryName("");
    }
  };
  const handleAcountNumber = (e) => {
    setAccountNumber(e.target.value);

    if (!e.target.value.trim()) {
      setErrorAccountNumber("Account number is required");
    } else {
      setErrorAccountNumber("");
    }
  };
  const handleBankAddress = (e) => {
    setBankAddress(e.target.value);

    if (!e.target.value.trim()) {
      setErrorBankAddress("Bank address is required");
    } else {
      setErrorBankAddress("");
    }
  };
  const handleBankName = (e)=>{
    setBankName(e.target.value);
    if (!e.target.value.trim()) {
      setErrorBankName("Bank name is required");
    } else {
      setErrorBankName("");
    }
  }
  const handleABA =(e)=>{
    setABARoutingNumber(e.target.value);
    if (!e.target.value.trim()) {
      setErrorABARoutingNumber(
        "Abarouting number is required"
      );
    } else {
      setErrorABARoutingNumber("");
    }
  }
  const handleTypeOfAccount = (e)=>{
    setType(e.target.value);
    if (!e.target.value.trim()) {
      setErrorType("Type of account is required");
    } else {
      setErrorType("");
    }
  }

  useEffect(() => {
    getbankdetails();
  }, []);

  return (
    <div>
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      <div className="container mt-4">
        <div className="bg-white p-4 shadow rounded mt-4" data-aos="fade-left">
          <div className="card">
            <div className="card-body">
              <div>
                <div className="text-start mb-3">
                  <span>Transaction Type: Wire</span>
                </div>

                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="beneficiaryName" className="form-label">
                      Beneficiary Name
                    </label>
                    <div className="">
                      <input
                        className="form-control"
                        type="text"
                        onChange={handleBeneficiaryName}
                        onKeyDown={(e) => {
                          handlecomment(e);
                        }}
                        value={beneficaryName} //
                        placeholder={`Enter ${beneficaryName}`}
                      />
                      <div className="p-2">
                        {errorbeneficaryName && (
                          <>
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                              }}
                            >
                              {errorbeneficaryName}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label htmlFor="accountNumber" className="form-label">
                      Account Number
                    </label>
                    <div className="">
                      <input
                        type="number"
                        min="0"
                        onChange={handleAcountNumber}
                        onKeyDown={(e: any) => {
                          handlecomment(e);

                          exceptThisSymbolsnumber.includes(e.key) &&
                            e.preventDefault();
                          if (
                            e.target.value.length > 10 &&
                            e.key !== "Backspace" &&
                            e.key !== "Delete"
                          ) {
                            e.preventDefault();
                          }
                        }}
                        value={accountNumber}
                        placeholder={`Enter ${accountNumber}`}
                        className="form-control bg-transparent p-5"
                      />
                      <div className="p-2">
                        {erroraccountNumber && (
                          <>
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                              }}
                            >
                              {erroraccountNumber}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="bankAddress" className="form-label">
                      Bank Address
                    </label>
                    <div className="">
                      <input
                        onChange={handleBankAddress}
                        onKeyDown={(e) => {
                          handlecomment(e);
                        }}
                        value={bankAddress} //
                        placeholder={`Enter ${bankAddress}`}
                        className="form-control bg-transparent p-5"
                      />
                      <div className="p-2">
                        {errorbankAddress && (
                          <>
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                              }}
                            >
                              {errorbankAddress}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label htmlFor="bankName" className="form-label">
                      Bank Name
                    </label>
                    <div className="">
                      <input
                        onChange={
                         handleBankName
                        }
                        onKeyDown={(e) => {
                          handlecomment(e);
                        }}
                        value={bankName}
                        placeholder={`Enter ${bankName}`}
                        className="form-control bg-transparent p-5"
                      />
                      <div className="p-2">
                        {errorbankName && (
                          <>
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                              }}
                            >
                              {errorbankName}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="abaRoutingNumber" className="form-label">
                      ABA Routing Number
                    </label>
                    <div className="">
                      <input
                        type="number"
                        min="0"
                        onChange={
                         handleABA
                        }
                        onKeyDown={(e: any) => {
                          exceptThisSymbolsnumber.includes(e.key) &&
                            e.preventDefault();
                          if (
                            e.target.value.length > 10 &&
                            e.key !== "Backspace" &&
                            e.key !== "Delete"
                          ) {
                            e.preventDefault();
                          }
                        }}
                        value={abARoutingNumber}
                        placeholder={`Enter ${abARoutingNumber}`}
                        className="form-control bg-transparent p-5"
                      />
                      <div className="p-2">
                        {errorabARoutingNumber && (
                          <>
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                              }}
                            >
                              {errorabARoutingNumber}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label htmlFor="type" className="form-label">
                      Type Of Account
                    </label>
                    <div className="">
                      <input
                        onChange={
                         handleTypeOfAccount
                        }
                        onKeyDown={(e) => {
                          handlecomment(e);
                        }}
                        value={type}
                        placeholder={`Enter ${type}`}
                        className="form-control bg-transparent p-5"
                      />
                      <div className="p-2">
                        {errortype && (
                          <>
                            <span
                              style={{
                                color: "red",
                                position: "absolute",
                              }}
                            >
                              {errortype}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 d-flex justify-content-between">
                <Link to="/viewbankdetails" className="">
                  <button className="btn btn-secondary">Back</button>
                </Link>
                <Link to={`/editbankdetails/${id}`}>
                  <button
                    className="btn btn-warning"
                    onClick={handleSubmit}
                    disabled={
                      !beneficaryName ||
                      !accountNumber ||
                      !bankAddress ||
                      !bankName ||
                      !abARoutingNumber ||
                      !type
                    }
                  >
                    Update
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
