
// /* eslint-disable jsx-a11y/anchor-is-valid */


const AdminHeaderToolbar = () => {
  return (
    <div>
      <div>
        <div className="d-flex p-7">
          <div>
            <h1 className="">Admin Panel</h1>
          </div>
          <div>
            <i className="ms-5 fs-1 text-dark bi bi-person-fill-gear"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AdminHeaderToolbar };
