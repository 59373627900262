/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import axios from "axios";
import Services from "../../../../Services";

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

export default function VerifyEmail() {
  const [showTokenExpire, setShowTokenExpire] = useState(false);
  const [showVerified, setShowverified] = useState(false);
  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const intl = useIntl();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");

  const handleApiCall = async () => {
    try {
      const response: any = await Services.Auth.ISEMAILVERIFY(token);

      if (response?.status === 200) {
        setShowverified(true);
        setTimeout(() => {
          navigate("/auth/login");
         }, 2000);
      } else {
       
      }
    } catch (error: any) {
      setShowTokenExpire(true);
      if (
        error?.response &&
        (error?.response?.status === 400 || error?.response?.status === 401)
      ) {
        setShowTokenExpire(true);
        setTimeout(() => {
          navigate("/auth/login");
         }, 2000);
      }
    }
  };

  useEffect(() => {
    handleApiCall();
  }, []);
  return (
    <div
      className=""
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      {showTokenExpire === true && (
        <div className="container  col-log-lg-12 ">
          <div className="d-flex justify-content-center align-items-center ">
            <div className="text-center">
              <img
                src={toAbsoluteUrl("/media/logos/verifederror.png")}
                alt=""
                height={200}
                width={200}
              />

              <br />
              <h3 className="mt-5 text-center">
               
                Email Not verified
              </h3>
              <h4 className="fs-bolder mt-5 text-center">Token expired</h4>
            </div>
          </div>
        </div>
      )}
      {showVerified === true && (
        <div className="container  col-log-lg-12 ">
          <div className="d-flex justify-content-center align-items-center ">
            <div className="text-center">
              <img
                src={toAbsoluteUrl("/media/logos/tick.png")}
                alt=""
                height={200}
                width={200}
              />
              <br />
              <h3 className="mt-5 text-center">
                {/* {intl.formatMessage({ id: "fundRequestSuccessful" })} */}
                Email verified
              </h3>
              <h4 className="fs-bolder mt-5 text-center">
                Your email address was successfully verified.
              </h4>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
