import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../scss/Card.module.scss";
import { useIntl } from "react-intl";

function Info() {
  const intl = useIntl();

  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Number, setNumber] = useState("");

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  return (
    <div
      className=""
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <div className="d-flex">
        <div>
          <Link to="/createaccount">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
              height={20}
              width={30}
              alt=""
            />
          </Link>
        </div>
        <div></div>
        <h2 className={styles.head}>{intl.formatMessage({ id: "gsApp" })} </h2>
      </div>
      <div className=" bg-white  mt-8 p-2 shadow-sm p-10 mb-5  rounded">
        <div className="mt-5">
          <h6 className="text-start ms-6">
            {" "}
            {intl.formatMessage({ id: "weNeedToKnowYouToServeYouBetter" })}
          </h6>
          <div className="mt-8 ">
            <input
              className="form-control m-5 mt-2"
              type="text"
              placeholder="First Name , Middle Name"
              value={FirstName}
              onChange={handleFirstNameChange}
            />
          </div>
        </div>
        <div className="mt-10">
          <input
            className="form-control m-5 mt-2"
            type="text"
            placeholder="Last Name"
            value={LastName}
            onChange={handleLastNameChange}
          />
        </div>

        <div className="mt-10">
          <input
            className="form-control m-5 mt-2"
            type=""
            placeholder={intl.formatMessage({ id: "phoneNumber" })}
            value={Number}
            onChange={handleNumberChange}
          />
        </div>
        <Link to="/verification">
          <button className="btn btn-warning m-6 col-lg-12" type="submit">
            {" "}
            {intl.formatMessage({ id: "continuebtn" })}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Info;
