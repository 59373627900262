/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import { useState } from "react"
import { useIntl } from "react-intl"
import { Link, useNavigate } from "react-router-dom"
import styles from "../../scss/Card.module.scss";
import Services from "../../Services";
import Toster from "../../Components/Toster";

export default function AddPayoutBankDetials() {
  interface ToasterState {
    type: string;
    title: string;
    text: string;
    visiblity: string;
  }
  const navigate = useNavigate()
  const intl = useIntl()
  const [bankdetials, setbankdetials] = useState('')
  const [isDisable, setIsDisable] = useState(true);
  const [bankdetialsError, setbankdetialsError] = useState('')


  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });




  const handleBankDetials = async () => {
    try {
      let data = {
        bankDetails: bankdetials,
      };
      setIsDisable(true)
     
      const response = await Services.User.UPDATE_USER_BY_ID(data);

      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));

        navigate("/wallet");

      }, 1500);
      setToaster({
        type: "success",
        title: "Successfull",
        text: "Update bank details successfully",
        visiblity: "show",
      });

    } catch (error: any) {
      setIsDisable(false)
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  }
  return (<>
    <Toster
      type={toaster.type}
      title={toaster.title}
      text={toaster.text}
      visiblity={toaster.visiblity}
    />
    <div className="">
      <div className=" p-10 bg-white container   shadow mb-5 bg-body-tertiary rounded">
        <div className="d-flex">
          <div className="">
            <Link to="/setting">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
                height={20}
                width={30}
                alt=""
              />
            </Link>
          </div>
          <div></div>
          <h2 className={styles.handleHeader}>{intl.formatMessage({ id: 'bankDetails' })}</h2>
        </div>

        <div className=" p-5 mt-10">

          <div className="col-lg-12 mb-7">
            <h3>{intl.formatMessage({ id: 'bankDetails' })}</h3>
            <div className="mb-3">
              <textarea
                // type="text"
                id="exampleFormControlTextarea1"
                // rows="3"
                name="bankdetials"
                placeholder={intl.formatMessage({ id: 'bankDetails' })}
                value={bankdetials}
                onChange={(e) => {
                  if (e.target.value == " ") {
                    setIsDisable(true)
                    setbankdetialsError("Required")
                  } else {
                    setbankdetialsError("")
                    setbankdetials(e.target.value.trimStart())
                    setIsDisable(false)
                  }
                  setbankdetials(e.target.value.trimStart())
                }}
                className="form-control py-6  p-3 mb-2 text-gray row-5"  >

              </textarea>
              {bankdetialsError ? (
                <p className="text-start  position-absolute " style={{ color: "red" }}>
                  {bankdetialsError}
                </p>
              ) : null}

            </div>

          </div>
          <div className="p-5 mt-5">
            <button className="btn btn-primary col-2 " disabled={(isDisable)} onClick={handleBankDetials}>{intl.formatMessage({ id: 'savebtn' })}</button></div>
        </div>
      </div>
    </div>
  </>
  )
}