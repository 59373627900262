/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../_metronic/helpers";
import Services from "../Services";
import { useIntl } from "react-intl";

export default function Wallet() {
  const intl = useIntl();
  const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'
  const [isDisabled, setIsDisabled] = useState(true);

  const [gsAccountData, setGsAccountData] = useState<any>({});
  const [primaryAccountData, setPrimaryAccountData] = useState<any>({});
  const [isverify, setIsVerify] = useState<any>();
  const [isIdentityverify, setIsIdentityverify] = useState<any>();
  const [xauPrice, setXAUPrice] = useState<any>();
  const xauP= gsAccountData.balance / xauPrice

  const GET_USER = async () => {
    try {
      const response: any = await Services.User.GET_USER();
      if (response) {
        if (response?.data?.data?.isIdentityVerify === 1) {
          setIsIdentityverify(response?.data?.data?.isIdentityVerify);
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
          setIsIdentityverify(response?.data?.data?.isIdentityVerify);
        }
      }
      setIsVerify(response.data.data.image);
      setIsIdentityverify(response?.data?.data?.isIdentityVerify);
      localStorage.setItem(
        "Gsbalance",
        response?.data?.data?.GsAccount?.balance
      );
      localStorage.setItem("GsTagNumber", response?.data?.data?.gsTag);
      localStorage.setItem("phoneNumber", response?.data?.data?.phoneNumber);
      localStorage.setItem("Email", response?.data?.data?.email);
   
      
      
     
      
    } catch(error) {console.log(error);}
  };

  const GET_GS_ACCOUNT = async () => {
    try {
      const response: any = await Services.GsAccount.GET_GS_ACCOUNT();
      const data = response?.data;
      setXAUPrice(data?.xauPrice)
      data.gsAccount.type = "gsAccount";
      data.primaryAccount.type = "primaryAccount";
      setPrimaryAccountData(data?.primaryAccount);
      setGsAccountData(data?.gsAccount);
    } catch(error) {console.log(error);}
  };

  useEffect(() => {
    GET_GS_ACCOUNT();
    GET_USER();
  }, []);
  return (
    <div className="">
      <div className="">
        <div
          className="container mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded "
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className="d-flex justify-content-between align-content-start p-5 ">
            <div className="">
              <h2>{intl.formatMessage({ id: "gsGoldAccount" })}</h2>
              <span className="fw-semibold">
                {" "}
                {gsAccountData.accountNumber}
              </span>
              <br />
            </div>

            <div className="">
              <span className="fw-semibold">
                {" "}
                {intl.formatMessage({ id: "xau" })}{" "}
                {gsAccountData.balance > 0
                  ? gsAccountData.balance.toFixed(8)
                  : gsAccountData.balance}
              </span><br></br>
              <span className="fw-semibold">

              
              {xauP > 0 ? `${"$ "}${xauP.toFixed(2)}` : ""}

              </span>
            </div>
          </div>
          <div className=""></div>
        </div>
        <div
          className="container mt-8 shadow p-3 mb-5 bg-body-tertiary rounded"
          data-aos="fade-right"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className="d-flex  justify-content-between align-content-start  p-5">
            <div className="">
              {" "}
              <h2>{intl.formatMessage({ id: "primaryAccount" })}</h2>
              <span className="fw-semibold">
                {primaryAccountData.accountNumber}
              </span>
            </div>

            <div className="">
            <span className="fw-semibold">
  ${" "}
  {primaryAccountData.balance > 0
    ? primaryAccountData.balance.toFixed(2) // Adjust the number inside toFixed based on your preference
    : primaryAccountData.balance}
</span>

            </div>
          </div>
          <div className="">
            <Link to="/addfundlist" className="">
              <button
                className="btn btn-warning col-lg-4 mt-6"
                disabled={isDisabled}
              >
                {intl.formatMessage({ id: "fundAccount" })}
              </button>
            </Link>
          </div>

          {isIdentityverify === 0 && (
            <div className="bg-warning-subtle text-danger container col-lg-5 p-3 ms-3 mt-7 rounded text-center">
              <span className=" position-relative text-center">
                {intl.formatMessage({
                  id: "yourAccountIsNotVerifiedPleaseVerifyYourIdentityToContinue",
                })}
              </span>
            </div>
          )}

          {isIdentityverify === 2 && (
            <div className="bg-warning-subtle text-danger container col-lg-3 p-3 ms-3 mt-7 rounded text-center">
              <span className="m-5 ">
                {intl.formatMessage({
                  id: "verificationInProgress",
                })}
              </span>
            </div>
          )}
          {isIdentityverify === 3 && (
            <div className="bg-warning-subtle text-danger container col-lg-4 p-3 ms-3 mt-7 rounded text-center">
              <span className="m-5 ">
                {intl.formatMessage({
                  id: "yourAccountIsVerificationInRejected",
                })}
              </span>
            </div>
          )}
        </div>
      </div>

      <div
        className="d-flex justify-content-center align-item-center  "
        data-aos="fade-left"
        data-aos-anchor="#example-anchor"
        data-aos-offset="500"
        data-aos-duration="500"
      >
        <p className="text-center fw-bold mt-8 fs-3 mt-5 ">
          {intl.formatMessage({ id: "explore" })}
        </p>
      </div>

      <div
        className="d-flex justify-content-between align-item-center shadow p-3 mb-5 bg-body-tertiary rounded col-12 rounded p-5 float-end bg-warning  text-dark bg-opacity-25 "
        data-aos="fade-right"
        data-aos-anchor="#example-anchor"
        data-aos-offset="500"
        data-aos-duration="500"
      >
        <div>
          {" "}
          <h5 className="fw-bold ">
            {intl.formatMessage({ id: "inviteYourFriends" })}
          </h5>
          <p>
            {intl.formatMessage({ id: "inviteYourfriendswithTheSparkCode" })}
          </p>
        </div>

        <div>
          <img
            src={toAbsoluteUrl("/media/logos/inviteicon.png")}
            alt=""
            height={40}
            width={40}
          />
        </div>
      </div>
    </div>
  );
}
