/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Services from "../../Services";
import Toster from "../../Components/AdminPanelToster";
import Spinner from "../../Components/Spinner";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

function SupportDetails() {
  interface ToasterState {
    type: string;
    title: string;
    text: string;
    visiblity: string;
  }
  const [first, setFirst] = useState(0);

  const [search, setSearch] = useState("");
  const [rows, setRows] = useState(10);
  const [total_list, setTotal_list] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [showDropdown, setShowDropdown] = useState(false);
  const [data, setData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [support, setSupport] = useState([]);
  const [dropdownStates, setDropdownStates] = useState(
    support && support?.map(() => false)
  );
  const [isLoaded, setIsLoaded] = useState(true);
  const [open, setopen] = useState<String>("Open");
  const [close, setclose] = useState<String>("Close");
  const [inProgress, setInprogress] = useState<String>("InProgress");
  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });
  const toggleDropdown = (index) => {
    const newDropdownStates = [...dropdownStates];
    newDropdownStates[index] = !newDropdownStates[index];
    for (let i = 0; i < newDropdownStates.length; i++) {
      if (i !== index) {
        newDropdownStates[i] = false;
      }
    }
    setDropdownStates(newDropdownStates);
  };
  const getSupport = async () => {
    setIsLoaded(true);
    try {
      let object: any = {
        page: page,
        limit: rows,
        search: search,
      };
      let payload: any = new URLSearchParams(object).toString();
      const response: any = await Services.Support.GET_SUPPORT(payload);

      setSupport(response?.data?.data?.rows);
      setTotalCount(response?.data?.data?.count / rows);
      setTotal_list(response?.data?.data?.count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSupport();
  }, [search, page, rows]);

  const handlePageClick = (event) => {
    const crrPage = event.selected + 1;
    setCurrentPage(crrPage);
    setPage(crrPage);
  };
  const searchHandler = (val) => {
    setSearch(val);
  };

  const handleAction = async (id, selectedValue) => {
    
    setopen("Open");
    setclose("Close");
    setInprogress("InProgress");

    const data = { supportStatus: selectedValue };
    try {
      const response: any = await Services.Support.UPDATE_SUPPORT_BY_ID(
        id,
        data
      );
      if (response) {
        setToaster({
          type: "success",
          title: "Successful",
          text: "Status Updated successfully",
          visiblity: "show",
        });

        setTimeout(() => {
          setToaster({
            type: "",
            title: "",
            text: "",
            visiblity: "hide",
          });
        }, 2000);
        setTimeout(() => {
          setShowDropdown(false);
          window.location.reload();
        }, 100);
      }
      setShowDropdown(true);
      setopen("");
      setclose("");
      setInprogress("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className=" ">
      <div className="p-5 bg-white rounded container ">
        <div className="p-2 ">
          <Toster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
          />

          {!isLoaded && <Spinner />}

          <div className="">
            <h2 className="text-center p-2">Support</h2>

            <table
              className=" bg-white table align-middle  table-striped  table-hover  table-row-dashed fs-6 gy-7 gx-8 table-responsive responsive  bg-body-tertiary rounded"
              id="kt_table_users"
            >
              <thead data-aos="fade-left thead-dark">
                <tr className="text-center  text-muted fw-bold fs-7 text-uppercase gs-0 ">
                  <th className="min-w-200px text-dark">User Name</th>
                  <th className="min-w-200px text-dark">Title</th>
                  <th className="min-w-200px text-dark">Subject</th>
                  <th className="min-w-200px text-dark">Status</th>
                  <th className="min-w-200px text-dark">Action</th>
                </tr>
              </thead>
              <tbody
                className="text-center text-gray-600 "
                data-aos="fade-right"
              >
                {support && support?.map((item: any, i) => {
                  return (
                    <tr
                      key={i}
                      className="hover text-emphasis-dark text-muted fw-bold fs-5 text-truncate "
                    >
                      <td
                        className=""
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          maxWidth: "10ch",
                        }}
                      >
                        {item?.User?.firstName}
                      </td>
                      <td
                        className=""
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          maxWidth: "10ch",
                        }}
                      >
                        {item?.tittle}
                      </td>
                      <td
                        className=""
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          maxWidth: "10ch",
                        }}
                      >
                        {item?.subject}
                      </td>

                      <td className="">
                        {item.supportStatus === open && (
                          <span className="text-success">Open</span>
                        )}
                        {item.supportStatus === inProgress && (
                          <span className="text-primary">In Progress</span>
                        )}
                        {item.supportStatus === close && (
                          <span className="text-danger">Close</span>
                        )}
                      </td>
                      <td className="">
                        <div className="me-0 ">
                          <span
                            className="cursor-pointer"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="top-start"
                            data-kt-menu-flip="top-end"
                            onClick={() => toggleDropdown(i)}
                          >
                            <i className=" text-success fs-3 text-center bi bi-pencil-square"></i>
                          </span>

                          {dropdownStates[i] ? (
                            <div className="" data-kt-menu="true">
                              <div className=""></div>

                              <div className="separator border-gray-200"></div>

                              <div className="">
                                <div className="mb-10">
                                  <label className="form-label fw-bold">
                                    Status:
                                  </label>

                                  <div>
                                    <select
                                      className="form-select form-select-solid"
                                      data-placeholder="Select option"
                                      onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        handleAction(item.id, selectedValue);
                                      }}
                                      defaultValue={item.supportStatus}
                                    >
                                      <option
                                        value={open ? open.toString() : ""}
                                      >
                                        Open
                                      </option>
                                      <option
                                        value={close ? close.toString() : ""}
                                      >
                                        Close
                                      </option>

                                      <option
                                        value={
                                          inProgress
                                            ? inProgress.toString()
                                            : ""
                                        }
                                      >
                                        In Progress
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {data && totalCount > 1 ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={first}
                pageCount={totalCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportDetails;
