/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import 'font-awesome/css/font-awesome.min.css';
// sheetal code
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../scss/Card.module.scss";
import Services from "../../Services";
import ReactPaginate from "react-paginate";

function User() {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [total_list, setTotal_list] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [UserDetails, setUserDetails] = useState([]);

  const getuser = async () => {
    try {
      let object: any = {
        page: page,
        limit: rows,
        search: search,
      };
      let payload: any = new URLSearchParams(object).toString();
      const response: any = await Services.User.GET_USERS(payload);
      setUserDetails(response.data.data.rows);
      setData(response?.data?.data?.rows);
      setTotalCount(response?.data?.data?.count / rows);
      setTotal_list(response?.data?.data?.count);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    getuser();
  }, [search, page, rows]);

  const handlePageClick = (event) => {
    const crrPage = event.selected + 1;
    setCurrentPage(crrPage);
    setPage(crrPage);
  };

  return (
    <div>
      <div className="p-5 bg-white rounded container ">
        <div className="p-2">
          <div className="">
            <h2 className="text-center p-2">User Details</h2>

            <table
              className=" bg-white table align-middle  table-striped  table-hover  table-row-dashed fs-6 gy-7 gx-8 table-responsive responsive  bg-body-tertiary rounded"
              id="kt_table_users"
            >
              <thead data-aos="fade-left thead-dark">
                <tr className="text-center  text-muted fw-bold fs-7 text-uppercase gs-0 ">
                  <th className="min-w-150px text-dark">S.N</th>
                  <th className="min-w-200px text-dark">GS TAG</th>
                  <th className="min-w-200px text-dark">User Name</th>
                  <th className="min-w-200px text-dark ">Email</th>
                  <th className="min-w-200px text-dark ">Status</th>

                  <th className="min-w-125px text-dark">Action</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-semibold ">
                {UserDetails && UserDetails?.map((user: any, index) => {
                  return (
                    <tr
                      key={index}
                      className="hover  text-muted fw-bold fs-7  gs-0 text-center"
                    >
                      <td className=" pt-12">{index + 1}</td>
                      <td className="pt-12">
                        {user.gsTag.length > 10
                          ? `${user.gsTag.c(0, 10)}...`
                          : user.gsTag}
                      </td>
                      <td className="pt-12">
                        {user.firstName.length > 10
                          ? `${user.firstName.slice(0, 10)}...`
                          : user.firstName}
                      </td>
                      <td className="pt-12">
                        {user.email.length > 10
                          ? `${user.email.slice(0, 10)}...`
                          : user.email}
                      </td>

                      <td className="pt-12">
                        {(user.isIdentityVerify === 1 && (
                          <span className="text-success">Approved</span>
                        )) ||
                          (user.isIdentityVerify === 3 && (
                            <span className="text-danger">Reject</span>
                          )) ||
                          (user.isIdentityVerify === 2 && (
                            <span className="text-danger">InProgress</span>
                          )) ||
                          "Pending"}
                      </td>

                      <td>
                        <button className="btn s">
                          <Link to={`/viewdetails/${user.id}`} type="button">
                            <i className="pt-2 mb-1 fs-2 fa-solid fa-eye text-warning"></i>
                          </Link>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {data && totalCount > 1 ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={first}
                pageCount={totalCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default User;
