/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Services from '../../../../Services';
import Toster from '../../../../Components/Toster';

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}
export default function NewPassword() {
  let navigate = useNavigate();

  const [newpasswordError, setNewPasswordError] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [newPasswordType, setNewPasswordType] = useState("password");

  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const [isDisabled, setIsDisabled] = useState(true); 
  const [isLoaded, setIsLoaded] = useState(true);
  const exceptThisSymbols = ["+", "-", "*", "/", " "];
const search = new URLSearchParams(window.location.search).get("token")

  const IdentityVerifySubmit = async () => {
    let isValid = true;
   
    if (newpassword !== confirmPassword) {
      setConfirmPasswordError("Password does not match");
    } else {
      try {
        let data:any = {
          token:search
        };
        const query = new URLSearchParams(data)
        const data2:any = {
          password :newpassword
        }
        const response: any = await Services.Auth.RESET_PASSWORD(query,data2);


        if (response) {
          setIsLoaded(true);
          setIsDisabled(true)
          setTimeout(() => {
            navigate("/");
          }, 1500);

          setToaster({
            type: "success",
            title: "Successfull",
            text: "Change password successfully",
            visiblity: "show",
          });
        }
      } catch (error: any) {
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        setToaster({
          type: "danger",
          title: "Error Occured",
          text: error?.response?.data?.message
            ? error?.response?.data?.message 
            : "An unknown error occured",
          visiblity: "show",
        });
      }
    }
  };
  const handleNewPass = (e) =>{
    const passwordValue = e.target.value.trimStart();
    setNewPassword(passwordValue);

    if (passwordValue === "") {
      setIsDisabled(true);
      setNewPasswordError("Required");
    } else if (
      passwordValue.length < 8 ||
      passwordValue.length > 32
    ) {
      setIsDisabled(true);
      setNewPasswordError(
        "Password must be between 8 and 32 characters"
      );
    } else if (
      !/[a-zA-Z]/.test(passwordValue) ||
      !/\d/.test(passwordValue)
    ) {
      setIsDisabled(true);
      setNewPasswordError(
        "Password must contain at least one letter and one number"
      );
    } else {
      setNewPasswordError("");
      setIsDisabled(false);
    }
  }

  const handleConfirmPass = (e) =>{
    if (e.target.value.trim() === "") {
      setIsDisabled(true);

      setConfirmPasswordError("Required");
    }
    else if (e.target.value.trim() !== newpassword) {
      setIsDisabled(true);

      setConfirmPasswordError("Password does not match");
    }
    // else if(e.target.value.length<8){
    //   setIsDisabled(true);
    //   setConfirmPasswordError("Please enter 8 digit password")
    // }
    else {
      setIsDisabled(false)
      setConfirmPassword(e.target.value.trimStart());
      setConfirmPasswordError("");
    }

    setConfirmPassword(e.target.value.trimStart());
  }

  return (
    <>
    <Toster
    type={toaster.type}
    title={toaster.title}
    text={toaster.text}
    visiblity={toaster.visiblity}
  />
    <div data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500">
    
     
      <div className='container bg-white shadow-sm p-8 mb-5  bg-body-tertiary rounded '>
        <div className='container '>
        
        <div>
        <h3 className='text-center'>Change Password</h3>
      </div>
      <div className="position-relative  mt-12 mb-5">
            <h6 className="text-start ">New Password</h6>
                     <input
                      type={newPasswordType}
                      id="password"
                      placeholder="password"
                      name="password"
                      autoComplete="off"
                      className="form-control bg-transparent"
                      onPaste={(e:any) => {
                        const passwordValue = e.target.value.trimStart();
                        setNewPassword(passwordValue);

                        if (passwordValue === "") {
                          setIsDisabled(true);
                          setNewPasswordError("Required");
                        } else if (
                          passwordValue.length < 8 ||
                          passwordValue.length > 32
                        ) {
                          setIsDisabled(true);
                          setNewPasswordError(
                            "Password must be between 8 and 32 characters"
                          );
                        } else if (
                          !/[a-zA-Z]/.test(passwordValue) ||
                          !/\d/.test(passwordValue)
                        ) {
                          setIsDisabled(true);
                          setNewPasswordError(
                            "Password must contain at least one letter and one number"
                          );
                          
                        } else {
                          setNewPasswordError("");
                          setIsDisabled(false);
                        }
                      }}
                      onChange={(e) => {
                        const passwordValue = e.target.value.trimStart();
                        setNewPassword(passwordValue);

                        if (passwordValue === "") {
                          setIsDisabled(true);
                          setNewPasswordError("Required");
                        } else if (
                          passwordValue.length < 8 ||
                          passwordValue.length > 32
                        ) {
                          setIsDisabled(true);
                          setNewPasswordError(
                            "Password must be between 8 and 32 characters"
                          );
                        } else if (
                          !/[a-zA-Z]/.test(passwordValue) ||
                          !/\d/.test(passwordValue)
                        ) {
                          setIsDisabled(false);
                          setNewPasswordError(
                            "Password must contain at least one letter and one number"
                          );
                        } else {
                          setNewPasswordError("");
                          setIsDisabled(false);
                        }
                      }}
                      value={newpassword}
                      onKeyDown={(e: any) => {
                        exceptThisSymbols.includes(e.key) && e.preventDefault();
                        
                        
                      }}
                    />

            {newpasswordError ? (
              <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                {newpasswordError}
              </p>
            ) : null}

            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 mt-4"
              data-kt-password-meter-control="visibility"
              onClick={() => {
                newPasswordType === "password"
                  ? setNewPasswordType("text")
                  : setNewPasswordType("password");
              }}
            >
              {newPasswordType === "password" ? (
                <i className="bi bi-eye fs-2"></i>
              ) : (
                <i className="bi bi-eye-slash fs-2"></i>
              )}
            </span>
          </div>

          <div className="position-relative  mt-12 mb-5">
            <h6 className="text-start ">Confirm Password</h6>

            <input
              type={confirmPasswordType}
              id="password"
              placeholder="password"
              name="password"
              autoComplete="off"
              className="form-control bg-transparent"
              onChange={(e) => {
                if (e.target.value.trim() === "") {
                  setIsDisabled(true);

                  setConfirmPasswordError("Required");
                }
                else if (e.target.value.trim() !== newpassword) {
                  setIsDisabled(true);

                  setConfirmPasswordError("Password does not match");
                }
               else {
                  setIsDisabled(false)
                  setConfirmPassword(e.target.value.trimStart());
                  setConfirmPasswordError("");
                }

                setConfirmPassword(e.target.value.trimStart());
              }}
              value={confirmPassword}
              onKeyDown={(e) =>
                exceptThisSymbols.includes(e.key) && e.preventDefault()

              }

            />

            {confirmPasswordError ? (
              <p className="text-start  position-absolute mt-2" style={{ color: "red" }}>
                {confirmPasswordError}
              </p>
            ) : null}

            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 mt-4"
              data-kt-password-meter-control="visibility"
              onClick={() => {
                confirmPasswordType === "password"
                  ? setConfirmPasswordType("text")
                  : setConfirmPasswordType("password");
              }}
            >
              {confirmPasswordType === "password" ? (
                <i className="bi bi-eye fs-2"></i>
              ) : (
                <i className="bi bi-eye-slash fs-2"></i>
              )}
            </span>
          </div>


          <div className="mt-12  d-flex justify-content-between">
            {" "}
            {/* <Link to='/security' className=''>
              <button className="btn btn-secondary ">
                Cancel </button>
            </Link> */}
            <button className="btn btn-warning  col-12" onClick={IdentityVerifySubmit} disabled={!( newpassword && confirmPassword && newpassword.length>=8 && newpassword.length<=32  && !isDisabled && !newpasswordError)}>
              Change password</button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
