/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import styles from "../../scss/Card.module.scss";
import { useIntl } from "react-intl";

export default function ConfirmWithdraw() {
  const intl = useIntl();
  return (
    <div
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <h2 className="text-center">Withdraw</h2>
      <div className="container">
        <div className="container mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded">
          <div className="d-flex align-content-start p-5">
            <div className="col-lg-12">
              {" "}
              <h3>From Account</h3>
              <div className="mb-3">
                <select
                  id="disabledSelect"
                  className="form-select p-3 mb-2 text-gray fw-semibold "
                >
                  <option className="fw-semibold ">Select From Account</option>
                  <option className="">item1</option>
                  <option>item2</option>
                  <option>item3</option>
                  <option>item4</option>
                  <option>item5</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-8 p-2 shadow p-3 mb-5 bg-body-tertiary rounded">
          <div className="d-flex align-content-start p-5">
            <div className="col-lg-12">
              {" "}
              <h3>{intl.formatMessage({ id: "toAccount" })}</h3>
              <div className="mb-3">
                <select
                  id="disabledSelect"
                  className="form-select p-3 mb-2 text-gray fw-semibold "
                >
                  <option className="fw-semibold ">Select From Account</option>
                  <option className="">item1</option>
                  <option>item2</option>
                  <option>item3</option>
                  <option>item4</option>
                  <option>item5</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-8 p-2 shadow-sm p-3 mb-5 bg-body-tertiary rounded">
          <div className="col-lg-12 d-flex justify-content-between">
            <span className="fw-bold">
              {intl.formatMessage({ id: "AMOUNT" })}
            </span>
            <span className="fw-semibold">0.00</span>
          </div>
          <div className="grid gap-4 d-flex justify-content-between mt-10">
            <input
              type="number"
              name=""
              className="form-control "
              id=""
              placeholder="$50"
            />
            <input
              type="number"
              name=""
              className="form-control ms-2"
              id=""
              placeholder="$100"
            />
            <input
              type="number"
              name=""
              className="form-control ms-2"
              id=""
              placeholder="$250"
            />
          </div>
        </div>
        <div className="">
          <Link to="/confirmdetails">
            <button className="btn btn-warning col-lg-12 mt-20  ">
              {" "}
              {intl.formatMessage({ id: "continuebtn" })}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
