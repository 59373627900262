/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import Constant from "../Constant";
import api from "../api";

export default {


    CREATE_COUNTRY: (data) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.post(
              Constant.BASE_URL + api.Country.CREATE_COUNTRY(),data
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
      },
      GET_ALL_COUNTRY: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.get(
              Constant.BASE_URL + api.Country.GET_ALL_COUNTRY()
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
      },
      UPDATE_COUNTRY: (id,data) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.put(
                Constant.BASE_URL + api.Country.UPDATE_COUNTRY(id),data
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
      },
      GET_BY_ID: (id) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.get(
              Constant.BASE_URL + api.Country.GET_BY_ID(id)
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
      }, 
}