/* eslint-disable @typescript-eslint/no-unused-vars */
import { Link } from "react-router-dom";
import styles from "../../scss/Card.module.scss";
import Services from "../../Services";
import { useIntl } from "react-intl";
import { useState } from "react";

export default function Statment() {
  const intl = useIntl();
  const getstatments = async (a, b) => {
    try {
      let object: any = {
        startDate: a,
        endDate: b,
      };
      let payload: any = new URLSearchParams(object).toString();
      const response: any = await Services.MonthlyStatments.GET_STATMENTS(
        payload
      );
    } catch (error) {
      console.log(error);
    }
  };
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
 const years = Array.from(
    { length: currentYear - 1973 + 1 },
    (_, index) => currentYear - index
  );



  const [selectedYear, setSelectedYear] = useState("");

  return (
    <div
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <div className="bg-white d-flex justify-content-start ms-4 shadow-sm p-10 mb-5 bg-body-tertiary rounded mt-10">
        <div className="col-lg-12">
          <div>
            <div className="d-flex ">
              <div>
                <Link to="/setting">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
                    height={20}
                    width={30}
                    alt=""
                  />
                </Link>
              </div>
              <h2 className={styles.handleHeader}>
                {intl.formatMessage({ id: "statement&Documents" })}
              </h2>
            </div>

            <div className="text-center col-g"></div>
          </div>

          <div className="conatiner d-flex justify-content-between mt-10">
          <div>
              {/* <p className="fw-semibold">
                {intl.formatMessage({ id: "dateRange" })} <br />
                {intl.formatMessage({ id: "last12Months" })}
              </p> */}
              {/* <div>
                <label>Select a year:</label>
                <select id="year"></select>
              </div> */}
              <div>
                {/* <label htmlFor="year" className="me-3" >{intl.formatMessage({ id: "SelectaYear" })} :</label> */}
                <select
                  className="form-select form-control-solid" 
                  id="year"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  <option value="">
                    {intl.formatMessage({ id: "SelectaYear" })}
                  </option>
                  {years && years?.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
                                      
            <div className="mt-1 ms-1">
              <Link to="/accountsetting">
                <span className="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                      />
                    </g>
                  </svg>
                </span>
              </Link>
            </div>
          </div>
          <div
            className="conatiner d-flex justify-content-between mt-5"
            onClick={() =>
              getstatments(`${selectedYear}-01-01`, `${selectedYear}-01-31`)
            }
            style={{ cursor: "pointer" }}
          >
            <div>
              <p className="fw-semibold">{intl.formatMessage({ id: "jan" })}</p>
            </div>
            <div className="mt-1 ms-1">
              <span
                className="svg-icon svg-icon-primary svg-icon-2x"
                onClick={() =>
                  getstatments(`${selectedYear}-01-01`, `${selectedYear}-01-31`)
                }
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div
            className="conatiner d-flex justify-content-between mt-5"
            onClick={() =>
              getstatments(`${selectedYear}-02-01`, `${selectedYear}-02-29`)
            }
            style={{ cursor: "pointer" }}
          >
            <div>
              <p className="fw-semibold">{intl.formatMessage({ id: "feb" })}</p>
            </div>
            <div className="mt-1 ms-1">
              <span
                className="svg-icon svg-icon-primary svg-icon-2x"
                onClick={() =>
                  getstatments(`${selectedYear}-02-01`, `${selectedYear}-02-29`)
                }
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div
            className="conatiner d-flex justify-content-between mt-5"
            onClick={() =>
              getstatments(`${selectedYear}-03-01`, `${selectedYear}-03-31`)
            }
            style={{ cursor: "pointer" }}
          >
            <div>
              <p className="fw-semibold">{intl.formatMessage({ id: "mar" })}</p>
            </div>
            <div className="mt-1 ms-1">
              <span
                className="svg-icon svg-icon-primary svg-icon-2x"
                onClick={() =>
                  getstatments(`${selectedYear}-03-01`, `${selectedYear}-03-31`)
                }
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div
            className="conatiner d-flex justify-content-between mt-5"
            onClick={() =>
              getstatments(`${selectedYear}-04-01`, `${selectedYear}-04-31`)
            }
            style={{ cursor: "pointer" }}
          >
            <div>
              <p className="fw-semibold">{intl.formatMessage({ id: "apr" })}</p>
            </div>
            <div className="mt-1 ms-2">
              <span
                className="svg-icon svg-icon-primary svg-icon-2x"
                onClick={() =>
                  getstatments(`${selectedYear}-04-01`, `${selectedYear}-04-31`)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div
            className="conatiner d-flex justify-content-between mt-5"
            onClick={() =>
              getstatments(`${selectedYear}-05-01`, `${selectedYear}-05-31`)
            }
            style={{ cursor: "pointer" }}
          >
            <div>
              <p className="fw-semibold">{intl.formatMessage({ id: "may" })}</p>
            </div>
            <div className="mt-1 ms-2">
              <span
                className="svg-icon svg-icon-primary svg-icon-2x"
                onClick={() =>
                  getstatments(`${selectedYear}-05-01`, `${selectedYear}-05-31`)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div
            className="conatiner d-flex justify-content-between mt-5"
            onClick={() =>
              getstatments(`${selectedYear}-06-01`, `${selectedYear}-06-31`)
            }
            style={{ cursor: "pointer" }}
          >
            <div>
              <p className="fw-semibold">{intl.formatMessage({ id: "jun" })}</p>
            </div>
            <div className="mt-1 ms-1">
              <span
                className="svg-icon svg-icon-primary svg-icon-2x"
                onClick={() =>
                  getstatments(`${selectedYear}-06-01`, `${selectedYear}-06-31`)
                }
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div
            className="conatiner d-flex justify-content-between mt-5"
            onClick={() =>
              getstatments(`${selectedYear}-07-01`, `${selectedYear}-07-31`)
            }
            style={{ cursor: "pointer" }}
          >
            <div>
              <p className="fw-semibold">{intl.formatMessage({ id: "jul" })}</p>
            </div>
            <div className="mt-1 ms-1">
              <span
                className="svg-icon svg-icon-primary svg-icon-2x"
                onClick={() =>
                  getstatments(`${selectedYear}-07-01`, `${selectedYear}-07-31`)
                }
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div
            className="conatiner d-flex justify-content-between mt-5"
            onClick={() =>
              getstatments(`${selectedYear}-08-01`, `${selectedYear}-08-31`)
            }
            style={{ cursor: "pointer" }}
          >
            <div>
              <p className="fw-semibold">{intl.formatMessage({ id: "aug" })}</p>
            </div>
            <div className="mt-1 ms-1">
              <span
                className="svg-icon svg-icon-primary svg-icon-2x"
                onClick={() =>
                  getstatments(`${selectedYear}-08-01`, `${selectedYear}-08-31`)
                }
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div
            className="conatiner d-flex justify-content-between mt-5"
            onClick={() =>
              getstatments(`${selectedYear}-09-01`, `${selectedYear}-09-31`)
            }
            style={{ cursor: "pointer" }}
          >
            <div>
              <p className="fw-semibold">{intl.formatMessage({ id: "sep" })}</p>
            </div>
            <div className="mt-1 ms-1">
              <span
                className="svg-icon svg-icon-primary svg-icon-2x"
                onClick={() =>
                  getstatments(`${selectedYear}-09-01`, `${selectedYear}-09-31`)
                }
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div
            className="conatiner d-flex justify-content-between mt-5"
            onClick={() =>
              getstatments(`${selectedYear}-10-01`, `${selectedYear}-10-31`)
            }
            style={{ cursor: "pointer" }}
          >
            <div>
              <p className="fw-semibold">{intl.formatMessage({ id: "oct" })}</p>
            </div>
            <div className="mt-1 ms-1">
              <span
                className="svg-icon svg-icon-primary svg-icon-2x"
                onClick={() =>
                  getstatments(`${selectedYear}-10-01`, `${selectedYear}-10-31`)
                }
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div
            className="conatiner d-flex justify-content-between mt-5"
            onClick={() =>
              getstatments(`${selectedYear}-11-01`, `${selectedYear}-11-31`)
            }
            style={{ cursor: "pointer" }}
          >
            <div>
              <p className="fw-semibold">{intl.formatMessage({ id: "nov" })}</p>
            </div>
            <div className="mt-1 ms-1">
              <span
                className="svg-icon svg-icon-primary svg-icon-2x"
                onClick={() =>
                  getstatments(`${selectedYear}-11-01`, `${selectedYear}-11-31`)
                }
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div
            className="conatiner d-flex justify-content-between mt-5"
            onClick={() =>
              getstatments(`${selectedYear}-12-01`, `${selectedYear}-12-31`)
            }
            style={{ cursor: "pointer" }}
          >
            <div>
              <p className="fw-semibold">{intl.formatMessage({ id: "dec" })}</p>
            </div>
            <div className="mt-1 ms-1">
              <span
                className="svg-icon svg-icon-primary svg-icon-2x"
                onClick={() =>
                  getstatments(`${selectedYear}-12-01`, `${selectedYear}-12-31`)
                }
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
