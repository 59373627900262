import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

export default function Withdraw() {
  const intl = useIntl();
  return (
    <div
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <h2 className="text-center">Withdraw Account</h2>
      <div className="conatiner d-flex justify-content-between col-12 mt-5">
        <Link to="/setting">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
            height={20}
            width={30}
            alt=""
          />
        </Link>
      </div>
      <div>
        <div className="container mt-8 p-2 shadow-sm p-3 mb-5  rounded">
          <div className="d-flex align-content-start p-5">
            <div className="col-lg-11">
              {" "}
              <h3>From Account</h3>
              <span>Select From Account</span>
            </div>
          </div>
        </div>
        <div className="container mt-8 p-2 shadow-sm p-3 mb-5  rounded">
          <div className="d-flex align-content-start p-5">
            <div className="col-lg-11">
              {" "}
              <h3>{intl.formatMessage({ id: "toAccount" })}</h3>
              <span>Select To Account</span>
            </div>
          </div>
        </div>
        <div className="container mt-8 p-2 shadow-sm p-3 mb-5  rounded">
          <div className="d-flex align-content-start p-5">
            <div className="col-lg-11 d-flex justify-content-between ">
              {" "}
              <b>
                <span>{intl.formatMessage({ id: "AMOUNT" })}</span>
              </b>
              <span className="mt-1">0.00</span>
            </div>
            <br></br>
            <br></br>
          </div>
          <div className="d-flex align-content-start p-5">
            <div className="col-lg-11 d-flex justify-content-between ">
              <span>$50</span>
              <span>$100</span>
              <span>$250</span>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <Link to="/confirmdetails">
          <button className="btn btn-warning col-lg-2 mt-10">
            {" "}
            {intl.formatMessage({ id: "continuebtn" })}
          </button>
        </Link>
      </div>
    </div>
  );
}
