/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import constant from "../Constant";
import api from "../api"

export default {

    CREATE_SUPPORT: (data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await Axios.post(
                    constant.BASE_URL + api.Support.CREATE_SUPPORT(),
                    data
                );
                resolve(response);
            } catch (err) {
                reject(err);
            }
        });
    },


    GET_SUPPORT: (query) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.get(
              constant.BASE_URL + api.Support.GET_SUPPORT(query)
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
      }, 

      UPDATE_SUPPORT_BY_ID: (id,data) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.put(
              constant.BASE_URL + api.Support.UPDATE_SUPPORT_BY_ID(id),data
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
      }, 
}