
    /* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Services from "../../Services";
import moment from "moment";
import ReactPaginate from "react-paginate";

export default function MoveTransactions() {


  
  const [transactionHistroy, setTransactionHistory] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [total_list, setTotal_list] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const navigate = useNavigate();
  const getPrimaryData = async () => {
    try {
      let object: any = {
        typeOfTxn:2,
        page: page,
        limit: rows,
        // search: search,
        
      };
      let payload: any = new URLSearchParams(object).toString();
      const response: any =
        await Services.Transaction.GET_ALL_TRANSACTIONS_MOVE(payload);
        
      setTransactionHistory(response?.data?.data?.rows);
      setData(response?.data?.data?.rows);
      setTotalCount(response?.data?.data?.count / rows);
      setTotal_list(response?.data?.data?.count);
    } catch(error) {console.log(error);}
  };
  useEffect(() => {
    getPrimaryData();
  }, [search, page, rows]);

  const clickOnBtn = (ind) => {
    const data = { ...ind };
    navigate("/addfundappove", { state: { data } });
  };

  const handlePageClick = (event) => {
    const crrPage = event.selected + 1;
    setCurrentPage(crrPage);
    setPage(crrPage);
  };
  const searchHandler = (val) => {
    setSearch(val);
  };

  return (
    <div>
    <div>
      <div className="mt-5">
        <div className="p-5 bg-white rounded container ">
          <div className="">
            <div>
              <table
                className=" bg-white table align-middle  table-striped  table-hover  table-row-dashed fs-6 gy-7 gx-8 table-responsive responsive  bg-body-tertiary rounded"
                id="kt_table_users"
              >
                <thead data-aos="fade-left thead-dark">
                  <tr className="text-center  text-muted fw-bold fs-7  gs-0 ">
                  <th className="min-w-150px text-dark">User Name</th>
                    <th className="min-w-150px text-dark">From Account</th>
                    <th className="min-w-150px text-dark">To Account</th>
                    <th className="min-w-150px text-dark">Amount </th>
                    <th className="min-w-150px text-dark">Fees </th>

                    <th className="min-w-150px text-dark">Final Amount </th>                   
                    <th className="min-w-150px text-dark">Date & Time</th>
                 
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-semibold ">
                  {transactionHistroy && transactionHistroy?.map((item: any, index) => {
                    var finalAmount=item.balance-item.fee
                    return (
                        
                      <tr className="text-center" key={index}>
                        
                         <td>
                          <span className="text-hover-primary d-block mb-1 fs-6">
                            {item?.sentByUser?.firstName}
                          </span>
                        </td>
                             <td>
                          <span className="text-hover-primary d-block mb-1 fs-6">
                            {(item.type === "CR" && (
                              <span className="">Primary Acccount </span>
                            )) ||
                              (item.type === "DR" && (
                                <span className="">Gs Gold Account</span>
                              )) ||
                              ""}
                          </span>
                        </td>
                        <td>
                          <span className="text-hover-primary d-block mb-1 fs-6">
                            {(item.type === "CR" && (
                              <span className="">Gs Gold Account </span>
                            )) ||
                              (item.type === "DR" && (
                                <span className="">Primary Acccount</span>
                              )) ||
                              ""}
                          </span>
                        </td>
                        <td>
                          <span className=" text-hover-primary d-block mb-1 fs-6 ">
                            {item.balance}
                          </span>
                        </td>
                        <td>{item.fee}</td>
                        <td>{finalAmount}</td>
                      
                        
                        <td>
                          {" "}
                          {moment(item.createdAt).format(
                            "MMM DD, YYYY hh:mm A"
                          )}
                        </td>
                   
                      
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div>
            {data && totalCount > 1 ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={first}
                pageCount={totalCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
