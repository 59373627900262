import { Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { AuthLayout } from './AuthLayout'
import { Login } from './components/Login'
import SignUpStepOne from './components/SignUpStepOne'
import FinalStep from './components/steps/FinalStep'
import ForgotPasswordGS from './components/ForgotPasswordGS'
import NewPassword from './components/NewPassword'

import VerifyEmail from './components/VerifyEmail'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      {/* <Route path='forgot-password' element={<ForgotPassword />} /> */}
      <Route path='/forgotpassword' element={<ForgotPasswordGS />} />
      <Route path='/newpassword' element={<NewPassword />} />
      <Route path="/verifyemail" element={<VerifyEmail />} />

      <Route path='registration' element={<Registration />} />
      <Route path='signupstepone' element={<SignUpStepOne />} />

      <Route path="finalstep" element={<FinalStep />} />



      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }
