/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Services from "../../Services";
import moment from "moment";
import { useParams } from "react-router-dom";

export default function SendList(className) {
  const { id } = useParams();
  const [transactionHistroy, setTransactionHistory] = useState([]);

  const getsendlist = async () => {
    try {
      const response: any = await Services.Transaction.GET_TRANSACTIONS_BY_ID(
        id,
        3
      );
      setTransactionHistory(response?.data?.data?.rows);
    } catch(error) {console.log(error);}
  };

  useEffect(() => {
    getsendlist();
  }, []);

  return (
    <div>
      <div className="mt-5">
        <div className={`card ${className} `}>
          <div className="card-body py-3 ">
            <div className="table-responsive">
              <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                <thead className="text-center">
                  <tr className="fw-bold text-muted">
                    <th className="min-w-150px">Send By</th>
                    <th className="min-w-200px">Received By</th>
                    <th className="min-w-200px">Amount</th>
                    <th className="min-w-150px">Date & Time</th>
                  </tr>
                </thead>

                <tbody className="text-center">
                  {transactionHistroy && transactionHistroy?.map((item: any, index) => {
                    return (
                      <tr>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {item?.sentByUser?.gsTag}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {item?.receivedByUser?.gsTag}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {item.balance}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
                            {moment(item.createdAt).format(
                              "MMM DD, YYYY hh:mm A"
                            )}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
