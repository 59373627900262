/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Services from "../../Services";

import Spinner from "../../Components/Spinner";
import { toAbsoluteUrl } from "../../_metronic/helpers";

import Toster from "../../Components/Toster";
import Constant from "../../Constant";
import { useIntl } from "react-intl";

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

export default function EditProfileDetials() {
  const intl = useIntl();
  let navigate = useNavigate();
  const [modelOpen, setModelOpen] = useState(true);
  const [verifyPinHandler, setVerifyPinHandler] = useState<any>("");
  const [pinIsDisabled, setPinIsDisabled] = useState(false);
  const [pinError, setPinError] = useState("");
  const [gsTag, setGsTag] = useState("");
  const [oldGsTag, setOldGsTag] = useState("");
  const [gsTagError, setGsTagError] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [oldPhone, setOldPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessWeb, setBusinessWeb] = useState("");

  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledApiCall, setIsDisabledApiCall] = useState(true);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const [businessAddressError, setBusinessAddressError] = useState("");
  const [businessWebError, setBusinessWebError] = useState("");
  const [bussinessNameError, setBusinessNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [toggle, settoggle] = useState(true);
  const [datetoggle, setDateToggle] = useState(false);
  const [datetoggle1, setDateToggle1] = useState(false);
  const [date, setDate] = useState("");
  const [isPin, setIsPin] = useState<any>(false);
  const [isEnable, setIsEnable] = useState<any>("");
  const [isChecked, setIsChecked] = useState<any>(isEnable);

  const handlePaste = (e) => {
    let isValid = true;
    const pastedText = e.clipboardData.getData("Text");
    const isValidNumber = /^\d{7,15}$/;

    if (
      !isValidNumber.test(pastedText) ||
      pastedText.length < 7 ||
      pastedText.length > 15
    ) {
      e.preventDefault();
      setPhoneError("Invalid phone number format");
      isValid = false;
    }
  };

  // pin setup code start

  const handleCheckboxChange = async () => {};

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      setVerifyPinHandler("");
      setPinError("");
    }
  };

  const handleVerifyPin = async (e) => {
    if (e.target.value.length <= 0) {
      setPinIsDisabled(true);
      setPinError("Required");
    } else if (e.target.value.length < 4) {
      setPinIsDisabled(true);
      setPinError("");
    } else {
      setPinError("");
      setPinIsDisabled(false);
    }
    setVerifyPinHandler(e.target.value.trim());
  };
  const handlepin = async () => {
    try {
      if (verifyPinHandler !== "" && !pinError) {
        setIsDisabled(true);
        const data1: any = await Services.Auth.USER_PIN_VARIFY({
          pin: verifyPinHandler,
          isPinVerify: isChecked ? false : true,
        });
        if (data1) {
          setIsChecked(!isChecked);

          setPinError("");
          setToaster({
            type: "success",
            title: "Successful",
            text: "Pin Match successfully",
            visiblity: "show",
          });

          setTimeout(() => {
            setToaster({
              type: "",
              title: "",
              text: "",
              visiblity: "hide",
            });
            window.location.reload();
          }, 1000);

          setModelOpen(false);

          // handlefinish();
        } else {
          setPinError("pin not match");
        }
      } else {
        if (toggle === true) {
          setPinError("Required");
        } else {
          setPinError("");
        }
      }
    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);
      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };

  const GoToSetupPin = () => {
    navigate("/setuptransaction?type=setup");
    window.location.reload();
  };

  localStorage.setItem("isPinVerify", isChecked);

  const handleClose = () => {
    // setIsChecked(!isChecked);
  };
  const handleImageUpload = (e) => {
    setuploadButtonHide(true);
    const file = e.target.files[0];

    if (file) {
      settoggle(false);
      setProfile(file);
    } else {
    }
  };
  const ImageUpload = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (profile) {
      const formData = new FormData();
      formData.append("profile", profile);

      try {
        const response: any = await Services.Auth.PROFILE_UPLOAD(formData);
        if (response) {
          setProfile(response.data.image);
          setIsLoaded(true);
          settoggle(true);

          setTimeout(() => {
            setToaster((prevState) => ({
              ...prevState,
              visiblity: "hide",
            }));
          }, 800);
          setToaster({
            type: "success",
            title: "Successfull",
            text: "Update detials successfully",
            visiblity: "show",
          });
          setuploadButtonHide(false);
        }
      } catch (error: any) {
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        setToaster({
          type: "danger",
          title: "Error Occured",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : "An unknown error occured",
          visiblity: "show",
        });
      }
    }
  };

  const [dob, setDob] = useState("");
  const [profile, setProfile] = useState<any>("");
  const [uploadButtonHide, setuploadButtonHide] = useState(false);

  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });
  const exceptThisSymbols = ["+", "-", "."];
  const exceptThisSymbolsForPhoneNumber = ["e", "E", "-", "*", "/", " "];

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const [userData, setUserData] = useState([]);

  const GET_USER = async () => {
    try {
      const response: any = await Services.User.GET_USER();
      setOldGsTag(response.data.data.gsTag);
      setGsTag(response.data.data.gsTag);
      setEmail(response.data.data.email);
      setFirstName(response.data.data.firstName);
      setLastName(response.data.data.lastName);
      setBusinessName(response.data.data.businessName);
      setDob(response.data.data.dob);
      setProfile(response.data.data.profile);
      setPhone(response.data.data.phoneNumber);
      setOldPhone(response.data.data.phoneNumber);
      setAddress(response.data.data.address);
      setBusinessAddress(response.data.data.businessAddress);
      setBusinessWeb(response.data.data.businessWebsite);
      setUserData(response.data.data);
      setIsPin(response.data.data.pin);
      setIsChecked(response.data.data.isPinVerify);
      setIsEnable(response.data.data.isPinVerify);
    } catch(error) {console.log(error);}
  };

  const UpdateGsTag = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (oldGsTag !== gsTag) {
      try {
        let data = {
          gsTag: gsTag,
        };
        const response: any = await Services.User.UPDATE_GS_TAG(data);
        if (response) {
          handleSubmit(event);
        }
      } catch (error: any) {
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        setToaster({
          type: "danger",
          title: "Error Occured",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : "An unknown error occured",
          visiblity: "show",
        });
      }
      if (oldPhone !== phone) {
        try {
          const response: any = await Services.Auth.PHONE_NUMBER_VERIFY(phone);
          if (response?.status === 200) {
            setTimeout(() => {
              setToaster((prevState) => ({
                ...prevState,
                visiblity: "hide",
              }));
            }, 1500);

            setToaster({
              type: "danger",
              title: "Error Occurred",
              text: "Phone number already exist ",
              visiblity: "show",
            });
          }
        } catch (error: any) {
          setTimeout(() => {
            setToaster((prevState) => ({
              ...prevState,
              visiblity: "hide",
            }));
          }, 1500);

          setToaster({
            type: "danger",
            title: "Error Occured",
            text: error?.response?.data?.message
              ? error?.response?.data?.message
              : "An unknown error occured",
            visiblity: "show",
          });
        }
      }
    } else if (oldPhone !== phone) {
      try {
        const response: any = await Services.Auth.PHONE_NUMBER_VERIFY(phone);
        if (response?.status === 200) {
          setTimeout(() => {
            setToaster((prevState) => ({
              ...prevState,
              visiblity: "hide",
            }));
          }, 1500);

          setToaster({
            type: "danger",
            title: "Error Occurred",
            text: "Phone number already exist ",
            visiblity: "show",
          });
        } else {
          handleSubmit(event);
        }
      } catch (error: any) {
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        setToaster({
          type: "danger",
          title: "Error Occured",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : "An unknown error occured",
          visiblity: "show",
        });
      }
    } else {
      handleSubmit(event);
    }
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsDisabledApiCall(false);
    event.preventDefault();
    try {
      let data = {
        businessName: businessName,
        businessAddress: businessAddress,
        businessWebsite: businessWeb,
        firstName: firstName.trimStart(),
        lastName: lastName.trimStart(),
        gsTag: gsTag,
        address: address,
        profile: profile ? profile : "",
        phoneNumber: phone,
        dob: dob,
      };

      const response = await Services.User.UPDATE_USER_BY_ID(data);

      settoggle(false);
      setTimeout(() => {
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 500);

        navigate("/profile");
      }, 500);
      setToaster({
        type: "success",
        title: "Successfull",
        text: "Update detials successfully",
        visiblity: "show",
      });
      setIsDisabled(false);
    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };

  useEffect(() => {
    GET_USER();

    const toggle = localStorage.getItem("isPinVerify");
    if (toggle) {
      const parsedToggle = JSON.parse(toggle);
      setIsChecked(parsedToggle);
    } else {
    }
  }, []);
  const handleDateChange = (event) => {
    const selectedDateValue = event.target.value;
    const fifteenYearsAgo = getFifteenYearsAgoDate();
    if (selectedDateValue === "") {
      setDateToggle(true);
      setDateToggle1(true);
    } else {
      const selectedDate = new Date(selectedDateValue);
      const fifteenYearsAgoDate = new Date(fifteenYearsAgo);
      if (selectedDate <= fifteenYearsAgoDate) {
        setDob(selectedDateValue);
        setDateToggle(false);
        setDateToggle1(false);
      } else {
      }
    }
  };

  const getFifteenYearsAgoDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 16);
    const yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1;
    let dd: any = today.getDate();

    if (mm < 10) {
      mm = `0${mm}`;
    }
    if (dd < 10) {
      dd = `0${dd}`;
    }

    return `${yyyy}-${mm}-${dd}`;
  };

  return (
    <div>
      <div
        className="  "
        data-aos="fade-left"
        data-aos-anchor="#example-anchor"
        data-aos-offset="500"
        data-aos-duration="500"
      >
    
        {!isLoaded && <Spinner />}

        <div className="card position-absolute   bg-white  col-12">
          <div>
            <Link to="/profile">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
                height={20}
                width={30}
                alt=""
              />
            </Link>
          </div>
         
          <div id="kt_account_profile_details" className="collapse show">
            <form noValidate className="form">
              <div className="card-body border-top p-9">
                <div className="row mb-6 ">
                  <div className="col-lg-12 container d-flex justify-content-center align-items-center  ">
                    <div
                      className="image-input image-input-outline "
                      data-kt-image-input="true"
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl(profile)})`,
                      }}
                    >
                      <div
                        className="image-input-wrapper w-120px h-100px"
                        style={{
                          borderRadius: "50%",
                        }}
                      >
                        {profile ? (
                          <div className="position-relative">
                            <img
                              src={
                                profile instanceof Blob
                                  ? URL.createObjectURL(profile)
                                  : `${Constant.BASE_URL_UPLOADS}${profile}`
                              }
                              crossOrigin="anonymous"
                              alt={`Uploaded Image`}
                              className="uploaded-image position-relative"
                              height={110}
                              width={110}
                              style={{ borderRadius: "50%" }}
                            />
                            <label
                              className="float-end"
                              data-action="change"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="Change avatar"
                            >
                              <i
                                className="fa fa-pen icon-sm position-absolute translate-middle p-1 radius-full"
                                style={{
                                  bottom: "2px",
                                  right: "0px",
                                  background: "black",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "2px",
                                }}
                              >
                                <input
                                  id="fileInput"
                                  className="form-control d-none"
                                  type="file"
                                  placeholder="Choose File"
                                  onChange={handleImageUpload}
                                />
                              </i>
                            </label>
                          </div>
                        ) : (
                          <div className="position-relative">
                            <img
                              src={toAbsoluteUrl("/media/logos/userimage.jpeg")}
                              alt="Default Profile Image"
                              height={110}
                              width={110}
                              style={{ borderRadius: "50%" }}
                            />

                            <label
                              className="float-end"
                              data-action="change"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="Change avatar"
                            >
                              <i
                                className="fa fa-pen icon-sm position-absolute translate-middle p-1 radius-full"
                                style={{
                                  bottom: "2px",
                                  right: "0px",
                                  background: "black",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "2px",
                                }}
                              >
                                <input
                                  id="fileInput"
                                  className="form-control d-none"
                                  type="file"
                                  placeholder="Choose File"
                                  onChange={handleImageUpload}
                                />
                              </i>
                            </label>
                          </div>
                        )}
                      </div>

                      {uploadButtonHide && (
                        <div className="mt-6 ms-9 position-absolute ">
                          <button
                            className=" bg-primary border border-0 rounded fs-6 bg-light"
                            disabled={!profile}
                            onClick={ImageUpload}
                          >
                            Upload
                          </button>
                        </div>
                      )}
                      <label className=" fw-bold fs-3 text-center ms-6 mt-13">
                        {intl.formatMessage({ id: "myprofile" })}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="container">
                  {" "}
                  <div className="col-12">
                    <div className="col-lg-12 ">
                      <div className="row d-flex justify-content-between">
                        <div className="col-lg-5 fv-row ">
                          <label className="  fw-bold fs-6">
                            <span className="required">
                              {" "}
                              {intl.formatMessage({ id: "First Name" })}
                            </span>
                          </label>

                          <input
                            type="text"
                            className="form-control form-control-lg  mb-3 mb-lg-0"
                            placeholder={intl.formatMessage({
                              id: "First Name",
                            })}
                            value={firstName}
                            onChange={(e) => {
                              if (e.target.value.trimStart() === "") {
                                setIsDisabled(true);

                                setFirstNameError("Required");
                              } else {
                                setIsDisabled(false);
                                setFirstName(e.target.value.trimStart());
                                setFirstNameError("");
                              }
                              setFirstName(e.target.value.trimStart());
                            }}
                          />
                          {!firstName && (
                            <span className="position-absolute alert-danger  text-capitalize">
                              {firstNameError}
                            </span>
                          )}
                        </div>

                        <div className="col-lg-5  fv-row">
                          <label className="  fw-bold fs-6">
                            <span className="required">
                              {intl.formatMessage({ id: "lastName" })}
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg "
                            placeholder={intl.formatMessage({ id: "lastName" })}
                            value={lastName}
                            onChange={(e) => {
                              if (e.target.value.trimStart() === "") {
                                setIsDisabled(true);

                                setLastNameError("Required");
                              } else {
                                setIsDisabled(false);
                                setLastName(e.target.value.trimStart());
                                setLastNameError("");
                              }
                              setLastName(e.target.value.trimStart());
                            }}
                          />
                          {!lastName && (
                            <span className="position-absolute alert-danger  text-capitalize">
                              {lastNameError}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="row mt-6 d-flex justify-content-between">
                        <div className="col-lg-5 fv-row">
                          <label className="  fw-bold fs-6">
                            <span className="required">
                              {" "}
                              {intl.formatMessage({ id: "gSTag" })}
                            </span>
                          </label>
                          <input
                            className="form-control form-control-lg "
                            type="number"
                            name={intl.formatMessage({ id: "gSTag" })}
                            value={gsTag}
                            onChange={(e) => {
                              const enteredNumber: any = e.target.value;
                              if (enteredNumber >= 0 || enteredNumber === "") {
                                setGsTag(enteredNumber);
                                if (enteredNumber.length > 10) {
                                  setIsDisabled(true);
                                  setGsTagError(
                                    "must be enter beetween 10 digit"
                                  );
                                } else if (enteredNumber.length < 10) {
                                  setIsDisabled(true);
                                } else if (enteredNumber.length === 0) {
                                  setIsDisabled(true);
                                  setGsTagError("Required");
                                } else {
                                  setIsDisabled(false);
                                  setGsTagError("");
                                }
                              }
                            }}
                            onKeyDown={(e: any) => {
                              exceptThisSymbols.includes(e.key) &&
                                e.preventDefault();
                              if (
                                e.target.value.length >= 10 &&
                                e.key !== "Backspace" &&
                                e.key !== "Delete"
                              ) {
                                e.preventDefault();
                                setGsTagError(
                                  "must be enter beetween 10 digit"
                                );
                              }
                            }}
                          />
                          {gsTagError && (
                            <span className="position-absolute alert-danger  text-capitalize">
                              {gsTagError}
                            </span>
                          )}
                        </div>

                        <div className="col-lg-5 fv-row">
                          <label className="  fw-bold fs-6">
                            <span className="required">
                              {intl.formatMessage({ id: "businessAddress" })}
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg f"
                            placeholder="Business website"
                            value={businessAddress}
                            onChange={(e) => {
                              if (e.target.value.trimStart() === "") {
                                setIsDisabled(true);

                                setBusinessAddressError("Required");
                              } else {
                                setIsDisabled(false);
                                setBusinessAddress(e.target.value.trimStart());
                                setBusinessAddressError("");
                              }
                              setBusinessAddress(e.target.value.trimStart());
                            }}
                          />
                          {!businessAddress && (
                            <span className="position-absolute alert-danger  text-capitalize">
                              {businessAddressError}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="row mt-8 d-flex justify-content-between">
                        <div className="col-lg-5 fv-row">
                          <label className="  fw-bold fs-6">
                            <span className="required">
                              {intl.formatMessage({ id: "businesswebsite" })}
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg f"
                            placeholder={intl.formatMessage({
                              id: "businesswebsite",
                            })}
                            value={businessWeb}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                setIsDisabled(true);

                                setBusinessWebError("Required");
                              } else {
                                setIsDisabled(false);
                                setBusinessWeb(e.target.value.trimStart());
                                setBusinessWebError("");
                              }
                              setBusinessWeb(e.target.value.trimStart());
                            }}
                          />
                          {!businessWeb && (
                            <span className="position-absolute alert-danger  text-capitalize">
                              {businessWebError}
                            </span>
                          )}
                        </div>

                        <div className="col-lg-5 fv-row">
                          <label className="  fw-bold fs-6">
                            <span className="required">
                              {intl.formatMessage({ id: "businessName" })}
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg f"
                            placeholder="Business website"
                            value={businessName}
                            onChange={(e) => {
                              if (e.target.value.trimStart() === "") {
                                setIsDisabled(true);

                                setBusinessNameError("Required");
                              } else {
                                setIsDisabled(false);
                                setBusinessName(e.target.value.trimStart());
                                setBusinessNameError("");
                              }
                              setBusinessName(e.target.value.trimStart());
                            }}
                          />
                          {!businessName && (
                            <span className="position-absolute alert-danger  text-capitalize">
                              {bussinessNameError}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="row mt-8 d-flex justify-content-between">
                        <div className="col-lg-5 fv-row">
                          <label className="  fw-bold fs-6">
                            <span className="required">
                              {" "}
                              {intl.formatMessage({ id: "email" })}
                            </span>
                          </label>
                          <input
                            className="form-control form-control-lg f"
                            type="email"
                            name="Email"
                            value={email}
                            disabled
                          />
                        </div>

                        <div className="col-lg-5 fv-row">
                          <label className="  fw-bold fs-6">
                            <span className="required">
                              {" "}
                              {intl.formatMessage({ id: "address" })}
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg f"
                            placeholder={intl.formatMessage({ id: "address" })}
                            value={address}
                            onChange={(e) => {
                              if (e.target.value.trimStart() === "") {
                                setIsDisabled(true);

                                setAddressError("Required");
                              } else {
                                setIsDisabled(false);
                                setAddress(e.target.value.trimStart());
                                setAddressError("");
                              }
                              setAddress(e.target.value.trimStart());
                            }}
                          />
                          {!address && (
                            <span className="position-absolute alert-danger  text-capitalize">
                              {addressError}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="row mt-8 d-flex justify-content-between">
                        <div className="col-lg-5 fv-row ">
                          <label className="  fw-bold fs-6">
                            <span className="required">
                              {intl.formatMessage({ id: "dateofBirth" })}
                            </span>
                          </label>
                          <input
                            type="date"
                            className="form-control form-control-lg f"
                            disabled={true}
                            value={dob}
                            max={getFifteenYearsAgoDate()}
                            onChange={handleDateChange}
                          />
                        </div>

                        <div className="col-lg-5 fv-row">
                          <label className="  fw-bold fs-6">
                            <span className="required">
                              {" "}
                              {intl.formatMessage({ id: "phoneNumber" })}
                            </span>
                          </label>
                          <input
                            type="number"
                            value={phone}
                            className="form-control form-control-lg f"
                            placeholder={intl.formatMessage({
                              id: "phoneNumber",
                            })}
                            onPaste={handlePaste}
                            onChange={(e) => {
                              const enteredNumber: any = e.target.value;

                              if (enteredNumber >= 0 || enteredNumber === "") {
                                setPhone(enteredNumber);
                                if (e.target.value.trimStart() === "") {
                                  setIsDisabled(true);

                                  setAddressError("Required");
                                } else if (enteredNumber.length < 15) {
                                  setIsDisabled(true);
                                } else if (enteredNumber.length === 0) {
                                  setIsDisabled(true);
                                  setPhoneError("Required");
                                } else {
                                  setIsDisabled(false);
                                  setPhoneError("");
                                }
                              }
                            }}
                            onKeyDown={(e: any) => {
                              exceptThisSymbolsForPhoneNumber.includes(e.key) &&
                                e.preventDefault();
                              if (
                                e.target.value.length >= 15 &&
                                e.key !== "Backspace" &&
                                e.key !== "Delete"
                              ) {
                                e.preventDefault();
                                setPhoneError(
                                  "Must be enter beetween  7 and  15 digit"
                                );
                              }
                            }}
                          />
                          {phoneError && (
                            <span className="position-absolute alert-danger  text-capitalize">
                              {phoneError}
                            </span>
                          )}
                        </div>
                      </div>
                       <div>
                      <div className=" row mt-8 d-flex justify-content-between">
                        <div className="  col-lg-9  fv-row">
                          <label className="fw-bold fs-6">
                            <span className="required">
                              {intl.formatMessage({
                                id: "requirePinForTransactions",
                              })}
                            </span>
                          </label>
                        </div>

                        <div className="form-check form-switch  mt-lg-0 col-lg-1 fv-row ">
                          <input
                            className="form-check-input float-end "
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            value={isChecked}
                            checked={isChecked}
                            // onChange={is}
                          />
                        </div>
                      </div>
</div>
                      <div className=" position-absolute row col-md-12 d-flex justify-content-between p-8">
                        <button
                          disabled={
                            !(
                              gsTag.length === 10 &&
                              firstName &&
                              lastName &&
                              email &&
                              phone.length >= 7 &&
                              phone.length <= 15 &&
                              address &&
                              businessName &&
                              businessAddress &&
                              businessWeb &&
                              toggle &&
                              dob &&
                              isDisabledApiCall
                            )
                          }
                          className="btn btn-primary me-2  col-11"
                          onClick={UpdateGsTag}
                        >
                          {intl.formatMessage({ id: "update" })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer d-flex justify-content-end py-6 px-9"></div>
            </form>
          </div>
        </div>
      </div>
      <>
      <Toster
          type={toaster.type}
          title={toaster.title}
          text={toaster.text}
          visiblity={toaster.visiblity}
        />
        {isPin == null && (
          <div
            className="modal fade"
            id="exampleModalCenter"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="reject"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    {intl.formatMessage({ id: "confirmation" })}
                  </h5>
                </div>
                <div className="modal-body">
                  {intl.formatMessage({ id: "pleaseSetupYourTransactionPin" })}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={handleClose}
                  >
                    {intl.formatMessage({ id: "close" })}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={GoToSetupPin}
                  >
                    {intl.formatMessage({ id: "continuebtn" })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
      <>
        {(modelOpen === true && isChecked === false) || isChecked === true ? (
          <div
            className="modal fade"
            id="exampleModalCenter"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            onClick={handleOverlayClick}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    {intl.formatMessage({ id: "verifyyourpin" })}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setVerifyPinHandler("");
                      setPinError("");
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="p-5 d-flex align-items-center position-relative my-1">
                      <input
                        type="number"
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-250px ps-14"
                        placeholder={intl.formatMessage({ id: "enteryourpin" })}
                        max={4}
                        min={0}
                        value={verifyPinHandler}
                        onChange={handleVerifyPin}
                        onKeyDown={(e: any) => {
                          exceptThisSymbols.includes(e.key) &&
                            e.preventDefault();
                          if (
                            e.target.value.length >= 4 &&
                            e.key !== "Backspace" &&
                            e.key !== "Delete"
                          ) {
                            e.preventDefault();
                            setPinError("");
                          }
                        }}
                      />
                      {pinError && (
                        <span
                          className="mt-20 "
                          style={{ color: "red", position: "absolute" }}
                        >
                          {pinError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setIsDisabled(false);
                      setVerifyPinHandler("");
                      setPinError("");
                    }}
                  >
                    {intl.formatMessage({ id: "close" })}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    //  disabled={pinIsDisabled}
                    onClick={handlepin}
                  >
                    {intl.formatMessage({ id: "done" })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    </div>
  );
}
