/* eslint-disable import/no-anonymous-default-export */
export default {
  LOGIN: () => "/auth/login",
  REGISTER: () => `/auth/register`,
  IMAGE_UPLOAD: () => `/user/documentImage`,
  UPDATE_IMAGE: () => `/user/me`,
  PROFILE_UPLOAD: () => `/user/profile/`,
  SEND_OTP: () => `/userOtp/sendOtp`,
  VERIFY_OTP: () => `/userOtp/verifyOtp`,
  RESEND_OTP: () => `/userOtp/reSendOtp/1`,
  GET_GSTAG: () => `/user/getGsTag`,
  GET_iMAGE: () => `/user/image`,
  CHANGE_PASSWORD: () => `/user/changePassword`,
  FORGOT_PASSWORD: () => `/auth/forgotPassword`,
  RESET_PASSWORD: (query) => `/auth/resetpassword?${query? query:""}`,
  ISEMAILVERIFY: (query) => `/auth/verify-email?token=${query}`,
  SENDEMAILVERIFY: () => `/auth/send-verification-email`,
  GET_COUNTRY: () => `/country/`,
  USER_SET_PIN: () => `/user/pin`,
  USER_PIN_VERIFY: () => `/gsAccount/pinVerify` ,
  EMAIL_VERIFY: (query) => `/user/isEmailExist?email=${query? query:""}`, 
  PHONE_NUMBER_VERIFY: (query) => `/user/isPhoneNumberExist?phoneNumber=${query? query:""}`, 
}; 

