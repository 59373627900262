/* eslint-disable react/jsx-no-target-blank */

import { AsideMenuItem } from "./AsideMenuItem";
import { useIntl } from "react-intl";

export function AsideMenuMain() {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to="/profile"
        title={intl.formatMessage({ id: "profile" })}
      />

      <AsideMenuItem
        to="/makeawithdraw"
        title={intl.formatMessage({ id: "makeWithdraw" })}
      />
      <AsideMenuItem
        to="/recenttransaction/1"
        title={intl.formatMessage({ id: "recentTransaction" })}
      />
      <AsideMenuItem
        to="/support"
        title={intl.formatMessage({ id: "support" })}
      />
      <AsideMenuItem
        to="/setting"
        title={intl.formatMessage({ id: "settings" })}
      />
      <AsideMenuItem
        to="/logoutgs"
        title={intl.formatMessage({ id: "logout" })}
      />
    </>
  );
}
