/* eslint-disable import/no-anonymous-default-export */

import constant from "../Constant";
import api from "../api"

export default {

    GET_STATMENTS: (query) => {
        return new Promise(async (resolve, reject) => {
          try {
            window.location.replace(
              constant.BASE_URL + api.MonthlyStatments.GET_STATMENTS(query),
            );
          } catch (err) {
            reject(err);
          }
        }); 
      }, 
}