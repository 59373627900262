import { useEffect, useState } from "react";
import Services from "../../../Services";
import { Link } from "react-router-dom";

export default function ViewBankDetails() {
  const [beneficaryName, setBeneficaryName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [abARoutingNumber, setABARoutingNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [type, setType] = useState("");
  const [id, setID] = useState("");

  const getbankdetails = async () => {
    try {
      const response: any = await Services.Wallet.GET_ADMIN_BANKDETAILS();
      setBeneficaryName(response?.data?.data?.html?.BeneficaryName);
      setAccountNumber(response?.data?.data?.html?.AccountNumber);
      setBankAddress(response?.data?.data?.html?.BankAddress);
      setABARoutingNumber(response?.data?.data?.html?.ABARoutingNumber);
      setBankName(response?.data?.data?.html?.BankName);
      setType(response?.data?.data?.type);
      setID(response?.data?.data?.id);
    } catch(error) {console.log(error);}
  };
  useEffect(() => {
    getbankdetails();
  }, []);

  return (
    <div className="container mt-4">
    <div className="bg-white p-4 shadow rounded mt-4" data-aos="fade-left">
      <div className="card">
        <div className="card-body">
          <div>
            <div className="text-start mb-3">
              <span>Transaction Type: Wire</span>
            </div>

            <div className="row">
              <div className="col-lg-6 mb-3">
                <label htmlFor="beneficiaryName" className="form-label">
                  Beneficiary Name
                </label>
                <div className="bg-body-secondary p-3 rounded text-dark">
                  {beneficaryName}
                </div>
              </div>

              <div className="col-lg-6 mb-3">
                <label htmlFor="accountNumber" className="form-label">
                  Account Number
                </label>
                <div className="bg-body-secondary p-3 rounded text-dark">
                  {accountNumber}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 mb-3">
                <label htmlFor="bankAddress" className="form-label">
                  Bank Address
                </label>
                <div className="bg-body-secondary p-3 rounded text-dark">
                  {bankAddress}
                </div>
              </div>

              <div className="col-lg-6 mb-3">
                <label htmlFor="bankName" className="form-label">
                  Bank Name
                </label>
                <div className="bg-body-secondary p-3 rounded text-dark">
                  {bankName}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 mb-3">
                <label htmlFor="abaRoutingNumber" className="form-label">
                  ABA Routing Number
                </label>
                <div className="bg-body-secondary p-3 rounded text-dark">
                  {abARoutingNumber}
                </div>
              </div>

              <div className="col-lg-6 mb-3">
                <label htmlFor="type" className="form-label">
                  Type Of Account
                </label>
                <div className="bg-body-secondary p-3 rounded text-dark">
                  {type}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 d-flex justify-content-between">
            <Link to="/bankdetails">
              <button className="btn btn-secondary">Back</button>
            </Link>
            <Link to={`/editbankdetails/${id}`}>
              <button className="btn btn-warning">Edit</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
