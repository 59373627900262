/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import Constant from "../Constant";
import api from "../api";
export default {
  GET_TRANSACTION: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_TRANSACTIONS()
        );
        resolve(response);
      } catch (err) {
        reject(err);
       
      }
    });
  },
  GET_USER_TRANSACTIONS: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_USER_TRANSACTIONS()
        );
        resolve(response);
      } catch (err) {
        reject(err);
     
      }
    });
  },
  GET_TRANSACTION_HISTORY: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_TRANSACTION_HISTORY(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_RECENT_SEND_TRANSACTION: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_RECENT_SEND_TRANSACTION()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_USER_WITHDRAW_TRANSACTIONS: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_USER_WITHDRAW_TRANSACTIONS(query)
        );
        resolve(response);
      
      } catch (err) {
        reject(err);
      
      }
    });
    // GET_ALL_USER_WITHDRAW_TRANSACTIONS 
  },
  GET_ALL_USER_WITHDRAW_TRANSACTIONS: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_ALL_USER_WITHDRAW_TRANSACTIONS(query)
        );
        resolve(response);
      
      } catch (err) {
        reject(err);
      
      }
    });
    // GET_ALL_USER_WITHDRAW_TRANSACTIONS 
  },
  GET_USER_WITHDRAW_TRANSACTIONS_BY_ID: (id,data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          Constant.BASE_URL + api.Transaction.GET_USER_WITHDRAW_TRANSACTIONS_BY_ID(id),data
        );
        resolve(response);
      
      } catch (err) {
        reject(err);
     
      }
    }); 
  },

  GET_TRANSACTIONS_USER: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_TRANSACTIONS_USER(query)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_TRANSACTIONS_USER_RECIEVED: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_TRANSACTIONS_USER_RECIEVED(query)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_TRANSACTIONS_USER_FUND: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_TRANSACTIONS_USER_FUND(query)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_TRANSACTIONS_BY_ID: (id, query) => {
   
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_TRANSACTIONS_BY_ID(id, query)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
    
  },
  GET_ALL_TRANSACTIONS_MOVE: ( query) => {
   
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_ALL_TRANSACTIONS_MOVE(query)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
    
  },
  GET_ALL_TRANSACTIONS_SEND: ( query) => {
   
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_ALL_TRANSACTIONS_SEND(query)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
    
  },
  GET_TRANSACTIONS_BY_ID_Fund: (id, query) => {

    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_TRANSACTIONS_BY_ID_Fund(id, query)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },

  GET_TRANSACTIONS_ADMIN_RECEIVED: (id, query) => {

    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Transaction.GET_TRANSACTIONS_ADMIN_RECEIVED(id, query)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }

};