//eslint-disable-next-line import/no-anonymous-default-export
export default {
  //live http://54.193.201.161:3000/
  // BASE_URL: "http://54.193.201.161:3000/v1",  
  // BASE_URL_UPLOADS : 'http://54.193.201.161:3000/uploads/',
  // BASE_URL_QR_CODE : 'http://54.193.201.161:3000/qrCode/',
  BASE_URL: "https://api.gsapp.live/v1",  
  BASE_URL_UPLOADS : 'https://api.gsapp.live/uploads/',
  BASE_URL_QR_CODE : 'https://api.gsapp.live/qrCode/',

  }