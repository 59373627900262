import React from "react";
import { Link } from "react-router-dom";

import { useIntl } from "react-intl";

function VerifyIdentity() {
  const intl = useIntl();
  return (
    <div
      className=""
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <h2 className="text-center mt-5">
        {intl.formatMessage({ id: "completeAccountVerification" })}
      </h2>

      <div className=" bg-white conatiner text-center mt-1 mt-5 mt-8 p-2 shadow-sm p-10 mb-5  rounded d-flex justify-content-center">
        <div className="container  ">
          <div>{/* <h2>Complete account verification</h2> */}</div>
          <div>
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
              height={10}
              width={20}
              alt=""
            />{" "}
            {intl.formatMessage({ id: "2Minutes" })}
          </div>
          <div className=" container p-2 text-dark bg-opacity-25 col-7 p-5flex-end rounded  mt-10">
            <p className="text-start mt-5 text-muted">
              {" "}
              {intl.formatMessage({
                id: "takeFullAdvantageOfGSAppWeRecommendCompletingThisStepNowBySubmittingGovernmentID DoingThisNowWillGiveYouFullAccessToAllOfGSFeatures",
              })}
            </p>
            <p className="text-start mt-5 text-muted">
              {intl.formatMessage({
                id: "YouMaySkipThisStepButWellLikelyAskYouAgainSometimeSoon",
              })}
            </p>
          </div>
          <div className="container p-2 text-dark bg-opacity-25 col-7 p-5flex-end rounded mt-10">
            <Link to="/auth/finalstep">
              <button className="btn btn-warning  col-lg-12 " type="submit">
                {" "}
                {intl.formatMessage({ id: "continuebtn" })}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyIdentity;
