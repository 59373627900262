import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Services from "../Services";
import Toster from "../Components/AdminPanelToster";
import Spinner from "../Components/Spinner";
import { useIntl } from "react-intl";

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

function Support() {
  const intl = useIntl()
  let navigate = useNavigate();
  const [title, setTittle] = useState("");
  const [subject, setSubject] = useState("");

  const [tittleError, setTittleError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true); 


  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });
  const exceptThisSymbols = ["~"];

  const handleSubmit = async () => {
    setIsLoaded(true)
    try {
      setIsDisabled(true)
      let data = {

        tittle: title,
        subject: subject,

      };

      const response: any = await Services.Support.CREATE_SUPPORT(data);
      if (response) {
        setToaster({
          type: "success",
          title: "Successfull",
          text: "Done Successfully",
          visiblity: "show",
        });
        setTimeout(() => {
          navigate("/wallet");
        }, 700);
      }
    } catch (error:any) {
      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error.response.data.message,
        visiblity: "show",
      });
      setTimeout(() => {
  
   
      }, 1500);
   
      }

  }
 const handleTitle = (e)=>{
  if (e.target.value.trim() === "") {
    setIsDisabled(true);
    setTittleError("Required");
  } else {
    setIsDisabled(false);
    setTittle(e.target.value);
    setTittleError("");
  }
  setTittle(e.target.value);
 }
 const handleSubject =(e)=>{
  if (e.target.value.trim() === "") {
    setIsDisabled(true);
    setSubject("Required");
  } else {
    setIsDisabled(false);
    setSubject(e.target.value);
    setSubjectError("");
  }
  setSubject(e.target.value);
 }
  return (
    <div>
       <Toster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
          />
          {!isLoaded && <Spinner />}
      <div className="container mt-20 bg-white p-5 col-6 shadow p-3 mb-5 bg-body-tertiary rounded  mt-10"
        data-aos="fade-left">

        <h2 className=" text-center">{intl.formatMessage({id: 'support'})}</h2>

        <div
          className=""
        >
         

          <div className="">
            <div id="kt_app_content" className="">
              <div
                id="kt_app_content_container"
                className=""
              >
                <div className="card">
                  <div>
                    <div className=" align-items-center position-relative my-1">
                      <span className="svg-icon svg-icon-1 position-absolute ms-6"></span>


                      <div>
                        <div className="row align-items-end m-10">
                          <div className="mt-6 ">
                            <label htmlFor="type" className="form-label">
                            {intl.formatMessage({id: 'title'})}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name=    {intl.formatMessage({id: 'title'})}
                              value={title}
                              onChange={
                               handleTitle
                              }
                              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                            />
                            {!title && (
                              <span className="position-absolute alert-danger  text-capitalize">
                                {tittleError}
                              </span>
                            )}
                          </div>



                          <div className="mt-6 ">
                            <label htmlFor="type" className="form-label">
                            {intl.formatMessage({id: 'subject'})}
                            </label>
                            <textarea
                              className="form-control"
                              name={intl.formatMessage({id: 'subject'})}
                              value={subject}
                              onChange={
                                handleSubject
                              }
                              onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                            />
                            {!subject && (
                              <span className="position-absolute alert-danger  text-capitalize">
                                {subjectError}
                              </span>
                            )}

                          </div>
                          <div className="col-12  py-5 mt-4">
                            <button
                              disabled={!title || !subject || isDisabled}

                              className="btn btn-primary me-2 col-12"
                              onClick={() => handleSubmit()}
                            >
                         {intl.formatMessage({id: 'done'})}
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )


}


export default Support;
