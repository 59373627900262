/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Services from "../../Services";
import { KTIcon } from "../../_metronic/helpers";
import ReactPaginate from "react-paginate";
import { useIntl } from "react-intl";
import Toster from "../../Components/AdminPanelToster";
interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}

export default function Country() {
  const intl = useIntl();
  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const [data, setData] = useState<any>([]);
  const [isEnable, setIsEnable] = useState<any>("");
  const [isChecked, setIsChecked] = useState<any>(isEnable);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [total_list, setTotal_list] = useState(0);
  const [search, setSearch] = useState("");
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");
  const [getCountry, setGetCountry] = useState("");
  const [getCountryError, setGetCountryError] = useState("");
  const [id, setId] = useState("");
  const [id2, setId2] = useState("");
  const [ToggleId, setToggleId] = useState("");
  const [status, setStatus] = useState("");

  const exceptThisSymbols = [
    "@",
    "~",
    "!",
    "#",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    "_",
    "+",
    "=",
    "-",
    ".",
    "/",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];

  const [modelOpen, setModelOpen] = useState(false);
  const [editModelOpen, setEditModelOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [page, setPage] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const onPageChange = (event: any) => {
    setPage(event.page + 1);
    setFirst(event.first);
    setRows(event.rows);
  };
  let query: any;

  const handlePageClick = (event) => {
    const crrPage = event.selected + 1;
    setCurrentPage(crrPage);
    setPage(crrPage);
  };

  useEffect(() => {
    getPrimaryData();
  }, [search, page, rows]);

  const getPrimaryData = async () => {
    let object: any = {
      page: page,
      limit: rows,
      search: search,
    };
    let payload: any = new URLSearchParams(object).toString();
    try {
      const response: any = await Services.Country.GET_ALL_COUNTRY();

      setIsChecked(response?.data?.data?.status);
      setToggleId(response);

      setIsEnable(response?.data?.data?.status);
      setData(response?.data?.data);
      setGetCountry(response?.data);

      setTotalCount(response?.data?.data?.count / rows);
      setTotal_list(response?.data?.data?.count);
    } catch(error) {console.log(error);}
  };
  const handleCountry = async (e) => {
    setModelOpen(false);
    try {
      let object: any = {
        country: country,
      };
      let payload: any = new URLSearchParams(object).toString();
      const response: any = await Services.Country.CREATE_COUNTRY(payload);
      if (response) {
        setToaster({
          type: "success",
          title: "Successful",
          text: "Country added successfully",
          visiblity: "show",
        });

        setTimeout(() => {
          setToaster({
            type: "",
            title: "",
            text: "",
            visiblity: "hide",
          });
          window.location.reload();
        }, 1000);
      } else {
      }
    } catch(error) {console.log(error);}
  };
  const handleChange = async (id, isChecked) => {
    setIsChecked(!isChecked);

    try {
      let payload = {
        status: status ? false : true,
      };
      const response: any = await Services.Country.UPDATE_COUNTRY(id, payload);
      if (response) {
        setIsChecked(!isChecked);
        setToaster({
          type: "success",
          title: "Successful",
          text: "Status Updated successfully",
          visiblity: "show",
        });

        setTimeout(() => {
          setToaster({
            type: "",
            title: "",
            text: "",
            visiblity: "hide",
          });
        }, 2000);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetById = async (id) => {
    try {
      const response: any = await Services.Country.GET_BY_ID(id);
      setGetCountry(response.data.data[0].country);
      setId(response?.data?.data[0].id);
    } catch(error) {console.log(error);}
  };
  const handleEditCountry = async (id: any, e) => {
    setGetCountry(e.target.value);

    try {
      let payload = {
        country: getCountry,
      };
      const response: any = await Services.Country.UPDATE_COUNTRY(id, payload);
      if (response) {
        setIsChecked(!isChecked);
        setToaster({
          type: "success",
          title: "Successful",
          text: "Status Updated successfully",
          visiblity: "show",
        });

        setTimeout(() => {
          setToaster({
            type: "",
            title: "",
            text: "",
            visiblity: "hide",
          });
        }, 2000);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />

      <div className="card ">
        <h1 className="text-center mt-4 " style={{marginRight:"60px"}}>Country List</h1>

        <div className="card-body ml-5 ">
          <div className="card-toolbar ">
            <button
              type="button"
              className="btn btn-warning ml-5"
              data-toggle="modal"
              data-target="#exampleModalCenter"
              onClick={() => setModelOpen(true)}
            >
              <KTIcon iconName="plus" className="fs-2" />
              Add Country
            </button>
          </div>
          <table
            className=" bg-white table align-middle table-row-dashed fs-6 gy-7 gx-8 table-responsive responsive  bg-body-tertiary rounded"
            id="kt_table_users"
          >
            <div className="card-title">
              <div className="d-flex align-items-center position-relative my-1"></div>
            </div>

            <thead data-aos="fade-left thead-dark">
              <tr className="text-center text-muted fw-bold fs-7 text-uppercase gs-0 ">
                <th className="min-w-150px text-dark">S.No</th>
                <th className="min-w-250px text-dark ">Country Name</th>
                <th className="min-w-250px text-dark ">Active</th>
                <th className="min-w-250px text-dark ">Edit</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 bg-white" data-aos="fade-right  ">
              {data &&
                data?.map((item: any, index) => {
                  return (
                    <tr className="text-center" key={index}>
                      <td className=" pt-12">{index + 1}</td>

                      <td
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          maxWidth: "10ch",
                        }}
                      >
                        {item.country ? item.country.substring(0, 100) : "-"}
                      </td>

                      <td>
                        <div className="form-check form-switch fv-row ">
                          <input
                            className="form-check-input float-end "
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            checked={item?.status}
                            onClick={() => {
                              setConfirm(true);
                              setId2(item.id);
                              setStatus(item?.status);
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-warning"
                          data-toggle="modal"
                          data-target="#exampleModalCenter"
                          onClick={() => {
                            setEditModelOpen(true);
                            handleGetById(item.id);
                          }}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {/* 1 modal
           */}
          {modelOpen === true ? (
            <>
              <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="reject"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">
                        Add Country
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setCountryError("");
                          setGetCountryError("");
                          setCountry("");
                          setGetCountry("");
                          window.location.reload();
                        }}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="p-5 d-flex align-items-center position-relative my-1">
                          <input
                            type="text"
                            data-kt-user-table-filter="search"
                            className="form-control form-control-solid w-250px ps-14"
                            placeholder="Add Country"
                            value={country}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setCountry(inputValue);

                              if (!inputValue.trim()) {
                                setCountryError("Required");
                              } else {
                                setCountryError("");
                              }
                            }}
                            onKeyDown={(e: any) => {
                              exceptThisSymbols.includes(e.key) &&
                                e.preventDefault();
                              if (
                                e.target.value.length >= 50 &&
                                e.key !== "Backspace" &&
                                e.key !== "Delete"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      {countryError && (
                        <>
                          <span
                            style={{
                              color: "red",
                              position: "absolute",
                              marginLeft: "8rem",
                            }}
                          >
                            {countryError}
                          </span>
                        </>
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => {
                          setCountryError("");
                          setGetCountryError("");
                          setCountry("");
                          window.location.reload();
                        }}
                      >
                        {intl.formatMessage({ id: "close" })}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={country.trim() === ""}
                        onClick={handleCountry}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {editModelOpen === true ? (
            <>
              <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="reject"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">
                        Edit Country
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setGetCountryError("");
                          setCountryError("");
                          setCountry("");
                          setGetCountry("");
                          window.location.reload();
                        }}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="p-5 d-flex align-items-center position-relative my-1">
                          <input
                            type="text"
                            data-kt-user-table-filter="search"
                            className="form-control form-control-solid w-250px ps-14"
                            placeholder={`Enter ${getCountry}`}
                            value={getCountry}
                            onChange={(e) => {
                              const inputValue1 = e.target.value;
                              setGetCountry(inputValue1);

                              if (!inputValue1.trim()) {
                                setGetCountryError("Required");
                              } else {
                                setGetCountryError("");
                              }
                            }}
                            onKeyDown={(e: any) => {
                              exceptThisSymbols.includes(e.key) &&
                                e.preventDefault();
                              if (
                                e.target.value.length >= 50 &&
                                e.key !== "Backspace" &&
                                e.key !== "Delete"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      {getCountryError && (
                        <>
                          <span
                            style={{
                              color: "red",
                              position: "absolute",
                              marginLeft: "8rem",
                            }}
                          >
                            {getCountryError}
                          </span>
                        </>
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => {
                          setCountryError("");
                          setGetCountryError("");
                          setCountry("");
                          window.location.reload();
                        }}
                      >
                        {intl.formatMessage({ id: "close" })}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={
                          !getCountry ||
                          (typeof getCountry === "string" &&
                            getCountry.trim() === "")
                        }
                        onClick={(e) => handleEditCountry(id, e)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {/* 3 modal */}
          {confirm === true && (
            <>
              <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="reject"
                aria-hidden="true"
                data-backdrop="static"
                data-keyboard="false"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLongTitle">
                        {intl.formatMessage({ id: "confirmation" })}
                      </h5>
                    </div>
                    <div className="modal-body">Are you sure ?</div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          setConfirm(false);
                          window.location.reload();
                        }}
                      >
                        No
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        onClick={() => {
                          handleChange(id2, isChecked);
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {data && totalCount > 1 ? (
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={first}
              pageCount={totalCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
