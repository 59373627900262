import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function AddCard() {
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolder, setCardHolder] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCVV] = useState("");

  const handleCardNumberChange = (event) => {
    setCardNumber(event.target.value);
  };

  const handleCardHolderChange = (event) => {
    setCardHolder(event.target.value);
  };

  const handleExpirationDateChange = (event) => {
    setExpirationDate(event.target.value);
  };

  const handleCVVChange = (event) => {
    setCVV(event.target.value);
  };

  return (
    <div
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
    >
      <div className="debit-card-form col-lg-10 mt-20 ms-20 ps-15  ">
        <form className="form-control container p-10  shadow-sm p-3 mb-5  rounded">
          <h2 className="text-center">Add Card</h2>
          <div className=" mt-5">
            <input
              className="form-control  mt-2"
              type="text"
              placeholder="Name on card"
              id="cardHolder"
              value={cardHolder}
              onChange={handleCardHolderChange}
            />

            <input
              className="form-control  mt-5 "
              type="text"
              placeholder="Card Number"
              id="cardNumber"
              value={cardNumber}
              onChange={handleCardNumberChange}
              maxLength={16}
            />
          </div>
          <div className="d-flex mt-5 grid gap-3">
            <div className="flex-column ">
              <input
                className="form-control"
                placeholder="Expiry (MM/YY)"
                type="text"
                id="expirationDate"
                value={expirationDate}
                onChange={handleExpirationDateChange}
                maxLength={5}
              />
            </div>
            <div className="flex-column ">
              <input
                className="form-control"
                placeholder="CVV"
                type="text"
                id="cvv"
                value={cvv}
                onChange={handleCVVChange}
                maxLength={3}
              />
            </div>
          </div>
          <Link to="/addcardmessage">
            <button className="btn btn-warning mt-10 col-lg-12" type="submit">
              Add Your Card
            </button>
          </Link>
        </form>
      </div>
    </div>
  );
}
