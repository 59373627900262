/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import Services from "../../Services";

import { KTIcon } from "../../_metronic/helpers";
import moment from "moment";
import { useIntl } from "react-intl";


export default function MyCard() {
  const intl = useIntl();

  const [cardData, setCardData] = useState<any>([]);

  const GET_CARD = async () => {
    try {
      const response: any = await Services.Card.GET_CARD();

      setCardData(response.data.data);
    } catch(error) {console.log(error);}
  };
  useEffect(() => {
    GET_CARD();
  }, []);

  return (
    <div className="bg-white">
      <body
        id="kt_app_body"
        data-kt-app-header-stacked="true"
        data-kt-app-header-primary-enabled="true"
        data-kt-app-header-secondary-enabled="true"
        className="app-default"
      >
        <div>
          <h1 className="text-center">Card</h1>
          <div className="card-header border-0 pt-5">
            <div className="card-toolbar float-end">
              <button type="button" className="btn btn-sm  btn-warning ">
                <KTIcon iconName="plus" className="fs-2" />
                Add New Card
              </button>
            </div>
          </div>

          <div className="card-body py-3 mt-10 bg-white">
            <div className="table-responsive">
              <table className="table align-middle gs-0 gy-4">
                <thead>
                  <tr className="fw-bold text-muted bg-light">
                    <th className="ps-4 min-w-250px rounded-start">Card</th>
                    <th className="min-w-200px">Balance</th>
                    <th className="min-w-200px">CVV</th>
                    <th className="min-w-200px">
                      {intl.formatMessage({ id: "type" })}
                    </th>
                    <th className="min-w-200px">Expiry Date</th>

                    <th className="min-w-200px">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {cardData && cardData?.map((item: any, key) => {
                    return (
                      <tr>
                        <td key={key}>
                          <div className="d-flex align-items-center">
                            <div className="symbol symbol-50px me-5">
                              <span className="svg-icon svg-icon-primary svg-icon-3x">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect x="0" y="0" width="24" height="24" />
                                    <rect
                                      fill="#000000"
                                      opacity="0.3"
                                      x="2"
                                      y="5"
                                      width="20"
                                      height="14"
                                      rx="2"
                                    />
                                    <rect
                                      fill="#000000"
                                      x="2"
                                      y="8"
                                      width="20"
                                      height="3"
                                    />
                                    <rect
                                      fill="#000000"
                                      opacity="0.3"
                                      x="16"
                                      y="14"
                                      width="4"
                                      height="2"
                                      rx="1"
                                    />
                                  </g>
                                </svg>
                              </span>
                            </div>
                            <div className="d-flex justify-content-start flex-column">
                              <a
                                href="#"
                                className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                              >
                                {item.cardName}
                              </a>
                              <span className="text-muted fw-semibold text-muted d-block fs-7">
                                {item.cardNumber}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href="#"
                            className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                          >
                            {item.balance}
                          </a>
                        </td>
                        <td>
                          <a
                            href="#"
                            className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                          >
                            {item.cvv}
                          </a>
                        </td>
                        <td>
                          <a
                            href="#"
                            className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                          >
                            {item.type}
                          </a>
                        </td>
                        <td>
                          <a
                            href="#"
                            className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
                          >
                            {moment(item.expiryDate).format("MMM DD, YYYY")}
                          </a>
                        </td>

                        <td className="">
                          <a
                            href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                          >
                            <KTIcon iconName="switch" className="fs-3" />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                          >
                            <KTIcon iconName="pencil" className="fs-3" />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                          >
                            <KTIcon iconName="trash" className="fs-3" />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}
