/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import Services from "../../../../Services";
import Spinner from "../../../../Components/Spinner";
import Toaster from "../../../../Components/Toster";
import { useIntl } from "react-intl";

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}
export function Registration() {
  const intl = useIntl();
  let navigate = useNavigate();
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [phone, setphone] = useState("");

  const [verify, setVerify] = useState("");
  const [skipbuttonEnable, setSkipbuttonEnable] = useState(true);
  const [email, setEmail] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [password, setPassword] = useState("");
  const [businessWeb, setBusinessWeb] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [pointContactAddress, setPointContactAddress] = useState("");
  const [role, setRole] = useState("User");
  const [temporaryOtpShow, setTemporaryOtpShow] = useState("");

  const [address, setAddress] = useState("");

  const [emailError, setEmailError] = useState("");

  const [bussinessAddressError, setBusinessAddressError] = useState("");
  const [bussinessNameError, setBusinessNameError] = useState("");
  const [bussinessWebError, setBusinessWebError] = useState("");
  const [fnameError, setfnameError] = useState("");
  const [LnameError, setLnameError] = useState("");
  const [pointError, setPointError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [checkBoxError, setCheckBoxError] = useState("");

  const [date, setDate] = useState("");

  const [error, setError] = useState("");

  const [phoneError, setphoneError] = useState("");
  const [verifyError, setVerifyError] = useState("");

  const exceptThisSymbols = ["+", "-", "*", "/", " "];
  const exceptThisSymbolsForPhoneNumber = ["e", "E", "-", "*", "/", " "];

  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });
  const [isLoaded, setIsLoaded] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [isDisabled, setIsDisabled] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);

  const [checkBox, setCheckbox] = useState(false);
  const [check, setCheck] = useState();

  const [storeData, setStoreData] = useState({});
  const [currentStep, setCurrentStep] = useState<number>(1);

 

  // date function use
  const handleDateChange = (event) => {
    const selectedDateValue = event.target.value;
    const fifteenYearsAgo = getFifteenYearsAgoDate();

    if (selectedDateValue === "") {
      setToggle(true);
      setToggle1(true);
    } else {
      const selectedDate = new Date(selectedDateValue);
      const fifteenYearsAgoDate = new Date(fifteenYearsAgo);

      if (selectedDate <= fifteenYearsAgoDate) {
        setDate(selectedDateValue);
        setToggle(false);
        setToggle1(false);
      } else {
      }
    }
  };

  const getFifteenYearsAgoDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 16);
    const yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1;
    let dd: any = today.getDate();

    if (mm < 10) {
      mm = `0${mm}`;
    }
    if (dd < 10) {
      dd = `0${dd}`;
    }

    return `${yyyy}-${mm}-${dd}`;
  };
  const handleVerifyOtp = async () => {
    setToggle1(false);
    setToggle(true);
    if (verify.length < 4 && verify.length > 0) {
      setVerifyError("please enter 4 digit opt");
    } else {
      setVerify(verify.trim());
      setVerifyError("");
    }

    try {
      let data = {
        phoneNumber: localStorage?.phone,
        otp: verify.trim(),
      };
      const response = await Services.Auth.VERIFY_OTP(data);

      if (response) {
        setIsLoaded(true);
        localStorage.setItem("currentStep", (currentStep + 1).toString());

        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
          setCurrentStep(currentStep + 1);
        }, 1000);

        setToaster({
          type: "success",
          title: "Successfull",
          text: "Verify Otp successfully",
          visiblity: "show",
        });
      }
    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };

  const handleResendOtp = async () => {
    try {
      let data = {
        phoneNumber: localStorage?.phone,
      };

      const response: any = await Services.Auth.SEND_OTP(data);

      if (response) {
        setIsLoaded(true);

        setTemporaryOtpShow(response?.data?.data?.otp);

        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        setToaster({
          type: "success",
          title: "Successfull",
          text: "Resend Otp successfully",
          visiblity: "show",
        });
        startTimer();
      }
    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };
  const [isButtonDisabled, setIsDisabledBtn] = useState(true);
  const handleSendOtp = async () => {
    if (!fName || !lName || !address || !phone || !date) {
      setIsDisabledBtn(false);
      return;
    }
    setToggle(true);
    setToggle1(true);
    localStorage.setItem("fname", fName.toString());
    localStorage.setItem("lname", lName.toString());
    localStorage.setItem("address", address.toString());
    localStorage.setItem("phone", phone.toString());
    localStorage.setItem("date", date.toString());
    if (phone.length >= 7 && phone.length <= 15) {
      setphoneError("");
    

      try {
        let data = {
          // phoneNumber: phone.slice(1),
          phoneNumber: phone,
        };
      
        const response: any = await Services.Auth.SEND_OTP(data);
        setphone(response.data.data.phoneNumber);
        if (response) {
          setTemporaryOtpShow(response?.data?.data?.otp);
          setTimeout(() => {
            setToaster((prevState) => ({
              ...prevState,
              visiblity: "hide",
            }));
            setToggle1(false);
            setCurrentStep(currentStep + 1);
          }, 1500);
          setToaster({
            type: "success",
            title: "Successful",
            text: "Send Otp successfully",
            visiblity: "show",
          });
          setIsLoaded(true);
          localStorage.setItem("currentStep", (currentStep + 1).toString());
        }
      } catch (error: any) {
        setToggle1(false);
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);
        setToaster({
          type: "danger",
          title: "Error Occurred",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : "An unknown error occurred",
          visiblity: "show",
        });
      }
      // } else {
      //   setphoneError("Please enter country code");
      // }
    } else {
      setphoneError("Number must be between 7 and 15 digits.");
    }
  };

  const goToNextStep = () => {
    localStorage.setItem("bussinessname", businessName);
    localStorage.setItem("bussinesswebname", businessWeb);
    localStorage.setItem("bussinesaddress", businessAddress);
    localStorage.setItem("point", pointContactAddress);

    let isValid = true;
    setEmailError("");
    setPasswordError("");
    setCheckBoxError("");
    if (!checkBox) {
      setIsDisabled(true);
      setCheckBoxError("Please select Privacy policy");
      isValid = false;
    } else {
      setCheckBoxError("");
    }
    // if (email.trim() === "") {
    //   setIsDisabled(true);
    //   setEmailError("Required");
    //   isValid = false;
    // } else if (!validateEmail(email.trim())) {
    //   setIsDisabled(true);
    //   setEmailError("Enter a valid email address");
    //   isValid = false;
    // }
    if (email === null) {
      // Handle the case where email is null
      setIsDisabled(true);
      setEmailError("Email is required");
      isValid = false;
    } else if (email.trim() === "") {
      // Handle the case where email is an empty string
      setIsDisabled(true);
      setEmailError("Email is required");
      isValid = false;
    } else if (!validateEmail(email.trim())) {
      // Handle the case where email is not a valid email address
      setIsDisabled(true);
      setEmailError("Enter a valid email address");
      isValid = false;
    }

    if (password === null) {
      // Handle the case where email is null
      setIsDisabled(true);
      setEmailError("Email is required");
      isValid = false;
    } else if (password.trim() === "") {
      setIsDisabled(true);
      setPasswordError("Please enter password");
      isValid = false;
    } else {
      setIsDisabled(false);
      localStorage.setItem("currentStep", (currentStep + 1).toString());

      // setCurrentStep(currentStep + 1);
    }

    setStoreData({
      phone,
      lName,
      fName,
      verify,
      getGSTag,
      email,
      password,
      checkBox,
      businessName,
      businessWeb,
      businessAddress,
      pointContactAddress,
      date,
      address,
      role,
    });

    localStorage.setItem("currentStep", (currentStep + 1).toString());

    // Move to the next step
    setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    const storedPhone: any = localStorage.getItem("phone");
    setphone(storedPhone);
    setToggle(false);
    setEmailError("");
    setCheckbox(false);
    setStoreData({
      phone,
      lName,
      fName,
      verify,
      getGSTag,
      email,
      password,
      checkBox,
      businessName,
      businessWeb,
      businessAddress,
      pointContactAddress,
      date,
      address,
      role,
    });

    localStorage.setItem("currentStep", (currentStep - 1).toString());

    // Move to the previous step
    setCurrentStep(currentStep - 1);
  };


  const PHONE_NUMBER_VERIFY = async () => {
    try {
      const response: any = await Services.Auth.PHONE_NUMBER_VERIFY(phone);
      if (response?.status === 200) {
        setToggle1(false);
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        setToaster({
          type: "danger",
          title: "Error Occurred",
          text: "Phone number already exist ",
          visiblity: "show",
        });
      } else if (response?.status === 204) {
        handleSendOtp();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const EMAIL_VERIFY = async () => {

    try {
      const response: any = await Services.Auth.EMAIL_VERIFY(email);
      if (response?.status === 200) {
        
        setToggle1(false);
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        setToaster({
          type: "danger",
          title: "Error Occurred",
          text: "Email address already exist ",
          visiblity: "show",
        });
      } else if (response?.status === 204) {
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [getGSTag, setGetGSTag] = useState<any>();
  const GetGsTag = async () => {
    try {
      const response: any = await Services.Auth.GET_GSTAG();
      setGetGSTag(response?.data?.gsTag);
    } catch (error) {
      console.log(error);
    }
  };
  const [countrydata, setCountryData] = useState<any>([]);

  const [country, setCountry] = useState<any>([]);
  const GetCountry = async () => {
    try {
      const response: any = await Services.Auth.GET_COUNTRY();

      setCountry(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetGsTag();
    GetCountry();

    const savedStep = localStorage.getItem("currentStep");
    const email: any = localStorage.getItem("email");
    const password: any = localStorage.getItem("password");
    const gstag: any = localStorage.getItem("gsTag");
    const fname: any = localStorage.getItem("fname");
    const lname: any = localStorage.getItem("lname");
    const phone: any = localStorage.getItem("phone");

    const address: any = localStorage.getItem("address");
    const bussinessname: any = localStorage.getItem("bussinessname");
    const bussinessweb: any = localStorage.getItem("bussinesswebname");
    const bussinessaddress: any = localStorage.getItem("bussinesaddress");
    const point: any = localStorage.getItem("point");
    const date: any = localStorage.getItem("date");
    const countryId: any = localStorage.getItem("countryId");
    setDate(date);
    setCountryData(countryId);
    setBusinessAddress(bussinessaddress);
    setBusinessName(bussinessname);
    setBusinessWeb(bussinessweb);
    setPointContactAddress(point);
    setAddress(address);
    setphone(phone);
    setFname(fname);
    setLname(lname);

    setEmail(email);
    setPassword(password);
    setGetGSTag(gstag);

    if (savedStep) {
      setCurrentStep(parseInt(savedStep, 10));
    }
  }, []);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = async () => {
    setToggle(true);

    try {
     
      setIsDisabled(true);
      let data = {
        email: email,
        password: password,
        firstName: fName,
        lastName: lName,
        phoneNumber: localStorage.getItem("phone"),
        gsTag: getGSTag,
        address: address,
        dob: date,
        businessName: businessName,
        businessWebsite: businessWeb,
        businessAddress: businessAddress,
        // pocAddress: pointContactAddress.trim(),
        countryId: countrydata,
      };
      const response = await Services.Auth.REGISTER_USER(data);

      if (response) {
        setIsLoaded(true);

        localStorage.removeItem("phone");
        setTimeout(() => {
          navigate("/auth/finalstep");
        }, 700);

        setToaster({
          type: "success",
          title: "Successfull",
          text: "Account create successfully",
          visiblity: "show",
        });
      }
      localStorage.removeItem("currentStep");
      localStorage.removeItem("previousStep");
      localStorage.removeItem("currentStep");
      localStorage.removeItem("previousStep");
      localStorage.removeItem("address");
      localStorage.removeItem("email");
      localStorage.removeItem("gsTag");
      localStorage.removeItem("fname");
      localStorage.removeItem("lname");
      localStorage.removeItem("password");
      // localStorage.removeItem("phone");
      localStorage.removeItem("bussinessname");
      localStorage.removeItem("bussinesswebname");
      localStorage.removeItem("bussinesaddress");
      localStorage.removeItem("point");
      localStorage.removeItem("date");
      localStorage.removeItem("countryId");
    } catch (error: any) {
      console.log(error, "error");
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };

  const handleSkipSubmit = async () => {
    try {
      setSkipbuttonEnable(false);
      let data = {
        email: email,
        password: password,
        firstName: fName,
        lastName: lName,
        phoneNumber: localStorage.getItem("phone"),
        gsTag: getGSTag,
        address: address,
        dob: date,
        businessName: businessName,
        businessWebsite: businessWeb,
        businessAddress: businessAddress,
        // pocAddress: pointContactAddress.trim(),
        countryId: countrydata,
      };
      const response: any = await Services.Auth.REGISTER_USER(data);
      if (response) {
        localStorage.removeItem("phone");
      }

      setIsLoaded(true);
      setTimeout(() => {
        navigate("/auth/login");
      }, 700);

      setToaster({
        type: "success",
        title: "Successfull",
        text: "Account create successfully",
        visiblity: "show",
      });
      // }
      localStorage.removeItem("currentStep");
      localStorage.removeItem("previousStep");
      localStorage.removeItem("currentStep");
      localStorage.removeItem("previousStep");
      localStorage.removeItem("address");
      localStorage.removeItem("email");
      localStorage.removeItem("gsTag");
      localStorage.removeItem("fname");
      localStorage.removeItem("lname");
      localStorage.removeItem("password");
      // localStorage.removeItem("phone");
      localStorage.removeItem("bussinessname");
      localStorage.removeItem("bussinesswebname");
      localStorage.removeItem("bussinesaddress");
      localStorage.removeItem("point");
      localStorage.removeItem("date");
      localStorage.removeItem("countryId");
    } catch (error: any) {
      setSkipbuttonEnable(true);
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };
  const handleCheckboxChange = () => {
    setCheckbox(!checkBox);

    if (checkBox) {
      setIsDisabled(true);
      setCheckBoxError("Please select Privacy policy");
    } else {
      setIsDisabled(false);
      setCheckBoxError("");
    }
  };
  const handleFirstStep = () => {
     
    let isValid = true;
    if (!checkBox) {
      setIsDisabled(true);
      setCheckBoxError("Please select Privacy policy");
      isValid = false;
    } else {
      setCheckBoxError("");
    }

    if (email.trim() === "") {
      setIsDisabled(true);
      setEmailError("Required");
      isValid = false;
    } else if (!validateEmail(email.trim())) {
      setIsDisabled(true);
      setEmailError("Enter a valid email address");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Password validation
    if (password.trim() === "") {
      setIsDisabled(true);
      setPasswordError("Please enter a password");
      isValid = false;
    } else {
      setPasswordError("");
    }
    if (isValid) {
      setIsDisabled(false);
      localStorage.setItem("currentStep", (currentStep + 1).toString());
      localStorage.setItem("email", email.trim());
      localStorage.setItem("password", password.trim());
      localStorage.setItem("gsTag", getGSTag.toString());
      localStorage.setItem("countryId", countrydata);
      EMAIL_VERIFY();
    }
  };

  const [timer, setTimer] = useState(30);
  const [isResendEnabled, setIsResendEnabled] = useState(true);

  const startTimer = () => {
    setIsResendEnabled(false);

    const intervalId = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      setTimer(30);
      setIsResendEnabled(true);
    }, 30000);
  };

  const handleEmail = (e) => {
    if (e.target.value.trim() === "") {
      setIsDisabled(true);

      setEmailError("Required");
    } else if (!validateEmail(e.target.value.trim())) {
      setIsDisabled(true);
      setEmailError("Enter a valid email address");
    } else {
      setEmail(e.target.value.trim());
      setEmailError("");
    }
    setEmail(e.target.value.trimStart());
  };

  const handlePassword = (e) => {
    const passwordValue = e.target.value.trimStart();
    setPassword(passwordValue);

    if (passwordValue === "") {
      setIsDisabled(true);
      setPasswordError("Required");
    } else if (passwordValue.length < 8 || passwordValue.length > 32) {
      setIsDisabled(true);
      setPasswordError("Password must be between 8 and 32 characters");
    } else if (!/[a-zA-Z]/.test(passwordValue) || !/\d/.test(passwordValue)) {
      setIsDisabled(true);
      setPasswordError(
        "Password must contain at least one letter and one number"
      );
    } else {
      setPasswordError("");
      setIsDisabled(false);
    }
  };
  const handleCountry = (e) => {
    setCountryData(e.target.value);
  };

  const handleFirstName = (e) => {
    if (e.target.value.trim() === "") {
      setToggle(true);
      setToggle1(true);

      setfnameError("Required");
    } else {
      setToggle(false);
      setToggle1(false);
      setFname(e.target.value.trimStart());
      setfnameError("");
    }
    setFname(e.target.value.trimStart());
  };

  const handleLastName = (e) => {
    if (e.target.value.trim() === "") {
      setToggle(true);
      setToggle1(true);

      setLnameError("Required");
    } else {
      setLname(e.target.value.trimStart());
      setLnameError("");
      setToggle(false);
      setToggle1(false);
    }
    setLname(e.target.value.trimStart());
  };

  const handleNumber = (e) => {
    
    const enteredNumber: any = e.target.value;

    if (enteredNumber >= 0 || enteredNumber === "") {
      setphone(enteredNumber);
      if (enteredNumber.length > 15) {
        setToggle(true);
        setToggle1(true);
        setphoneError("Must be enter beetween  7 and  15 digit");
      } else if (enteredNumber.length === 0) {
        setToggle(true);
        setToggle1(true);
        setphoneError("Required");
      } else {
        setToggle(false);
        setToggle1(false);
        setphoneError("");
      }
    }
  };
  const handleAddres = (e) => {
    if (e.target.value.trim() === "") {
      setToggle(true);
      setToggle1(true);

      setAddressError("Required");
    } else {
      setAddress(e.target.value.trimStart());
      setAddressError("");
      setToggle(false);
      setToggle1(false);
    }

    setAddress(e.target.value.trimStart());
  };

  const handleVerify = (e) => {
    const enteredNumber: any = e.target.value;

    if (enteredNumber >= 0 || enteredNumber === "") {
      setVerify(enteredNumber);
      if (enteredNumber.length > 4) {
        setToggle(true);
        setVerifyError("please enter 4 digit pin");
      } else if (enteredNumber.length === 0) {
        setVerifyError("Required");
        setToggle(true);
      } else {
        setVerifyError("");
        setToggle(false);
      }
    }
  };
  const handleBusinessName = (e) => {
    if (e.target.value.trim() === "") {
      setIsDisabled(true);

      setBusinessNameError("Required");
    } else {
      setBusinessName(e.target.value.trimStart());
      setBusinessNameError("");
    }
    setBusinessName(e.target.value.trimStart());
  };
  const handleBusinessWeb = (e) => {
    if (e.target.value.trim() === "") {
      setIsDisabled(true);

      setBusinessWebError("Required");
    } else {
      setBusinessWeb(e.target.value.trimStart());
      setBusinessWebError("");
    }
    setBusinessWeb(e.target.value.trimStart());
  };
  const handleBusinessAddress = (e) => {
    if (e.target.value.trim() === "") {
      setIsDisabled(true);

      setBusinessAddressError("Required");
    } else {
      setBusinessAddress(e.target.value.trimStart());
      setBusinessAddressError("");
    }
    setBusinessAddress(e.target.value.trimStart());
  };

  const handlePointAddress = (e) => {
    if (e.target.value.trim() === "") {
      setIsDisabled(true);

      setPointError("Required");
    } else {
      setPointContactAddress(e.target.value.trimStart());
      setPointError("");
    }
    setPointContactAddress(e.target.value.trimStart());
  };
  return (
    <div>
      <div className="container">
        <Toaster
          type={toaster.type}
          title={toaster.title}
          text={toaster.text}
          visiblity={toaster.visiblity}
        />
        {!isLoaded && <Spinner />}
        {currentStep === 1 && (
          <div>
            <>
              <div
                className="text-center  "
                data-aos="fade-left"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="500"
              >
                <div className="text-center mb-5">
                  <h1 className="text-dark fw-bolder ">
                    {" "}
                    {intl.formatMessage({ id: "signup" })}
                  </h1>
                </div>

                <div>
                  <div className="mt-7">
                    <h6 className="text-start ">
                      {" "}
                      {intl.formatMessage({ id: "letsCreateYourAccount" })}
                    </h6>
                    <div className="mt-7 mb-5 ">
                      <h6 className="text-start ">
                        {" "}
                        {intl.formatMessage({ id: "gSTag" })}
                      </h6>
                      <input
                        className="form-control  mt-2"
                        type="number"
                        placeholder={intl.formatMessage({ id: "gSTag" })}
                        value={getGSTag}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="mt-7 mb-5">
                    <h6 className="text-start ">
                      {intl.formatMessage({ id: "email" })}
                    </h6>
                    <input
                      className="form-control tolowercase mt-2" 

                      type="email"
                      placeholder={intl.formatMessage({ id: "email" })}
                      value={email}
                      onChange={handleEmail}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                    />{" "}
                    {emailError ? (
                      <p
                        className="text-start position-absolute mt-1"
                        style={{ color: "red" }}
                      >
                        {emailError}
                      </p>
                    ) : null}
                  </div>

                  <div className="position-relative  mt-8 mb-5">
                    <h6 className="text-start ">
                      {intl.formatMessage({ id: "password" })}
                    </h6>

                    <input
                      type={passwordType}
                      id="password"
                      placeholder={intl.formatMessage({ id: "password" })}
                      name="password"
                      autoComplete="off"
                      className="form-control bg-transparent"
                      onChange={handlePassword}
                      value={password}
                      onKeyDown={(e: any) => {
                        exceptThisSymbols.includes(e.key) && e.preventDefault();
                      }}
                    />

                    {passwordError ? (
                      <p
                        className="text-start  position-absolute mt-1"
                        style={{ color: "red" }}
                      >
                        {passwordError}
                      </p>
                    ) : null}

                    <span
                      className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 mt-4"
                      data-kt-password-meter-control="visibility"
                      onClick={() => {
                        passwordType === "password"
                          ? setPasswordType("text")
                          : setPasswordType("password");
                      }}
                    >
                      {passwordType === "password" ? (
                        <i className="bi bi-eye fs-2"></i>
                      ) : (
                        <i className="bi bi-eye-slash fs-2"></i>
                      )}
                    </span>
                  </div>
                </div>

                <div className="mt-7 mb-5">
                  <h6 className="text-start ">
                    {intl.formatMessage({ id: "country" })}
                  </h6>

                  <select
                    id="country"
                    name="country"
                    className="form-control p-2"
                    value={countrydata}
                    onChange={handleCountry}
                  >
                    <option value="">
                      {intl.formatMessage({ id: "selectCountry" })}
                    </option>
                    {country &&
                      country?.map((item: any, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {item.country}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className="fv-row mb-7 float-start mt- ">
                  <label
                    className="form-check form-check-inline d-flex justify-content-sm-evenly align-items-center"
                    htmlFor="kt_login_toc_agree"
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="kt_login_toc_agree"
                      onClick={handleCheckboxChange}
                    />

                    <span className="ms-2 mt-1">
                      {intl.formatMessage({
                        id: "PleaseagreetoTermsandPrivacyPolicy",
                      })}{" "}
                      <Link
                        to="https://keenthemes.com/metronic/?page=faq"
                        target="_blank"
                        className=" link-primary"
                      ></Link>
                      .
                    </span>
                  </label>
                  {checkBoxError ? (
                    <p
                      className="text-start  position-absolute mt-1"
                      style={{ color: "red" }}
                    >
                      {checkBoxError}
                    </p>
                  ) : null}
                </div>

                <div className="text-center ">
                  {currentStep < 9 && (
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary w-100 mb-2"
                      disabled={
                        !(
                          getGSTag &&
                          email &&
                          password &&
                          countrydata &&
                          password.length >= 8 &&
                          !passwordError
                        )
                      }
                      onClick={handleFirstStep}
                    >
                      {intl.formatMessage({ id: "continuebtn" })}
                    </button>
                  )}

                  {currentStep === 1 && (
                    <Link to="/auth">
                      <h6 className="link-icon fw-bold text-center text-primary  fs-5">
                        {" "}
                        {intl.formatMessage({ id: "alradyHaveAnAccount" })}
                      </h6>
                    </Link>
                  )}
                  {currentStep > 1 && (
                    <button
                      type="button"
                      id="kt_login_signup_form_cancel_button"
                      className="btn btn-lg btn-light-primary w-100 mb-5"
                      onClick={goToPreviousStep}
                    >
                      {intl.formatMessage({ id: "previousbtn" })}
                    </button>
                  )}
                </div>
              </div>
            </>
          </div>
        )}
        {currentStep === 2 && (
          <div>
            <div
              className="text-center mt-2 p-2 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className="text-center mb-11">
                <h1 className="text-dark fw-bolder mb-3">
                  {intl.formatMessage({ id: "signup" })}
                </h1>
              </div>

              <div>
                <div className="mt-9 mb-5">
                  <h6 className="text-start ">
                    {intl.formatMessage({
                      id: "weNeedToKnowYouToServeYouBetter",
                    })}
                  </h6>
                  <div className="mt-9 mb-5 ">
                    <h6 className="text-start ">
                      {" "}
                      {intl.formatMessage({ id: "First Name" })}
                    </h6>

                    <input
                      className="form-control mt-2"
                      type="text"
                      placeholder={intl.formatMessage({ id: "First Name" })}
                      value={fName}
                      onChange={handleFirstName}
                    />

                    {fnameError ? (
                      <p
                        className="text-start  position-absolute mt-2"
                        style={{ color: "red" }}
                      >
                        {fnameError}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-9 mb-5">
                  <h6 className="text-start ">
                    {intl.formatMessage({ id: "lastName" })}
                  </h6>

                  <input
                    className="form-control  "
                    type="text"
                    placeholder={intl.formatMessage({ id: "lastName" })}
                    value={lName}
                    onChange={handleLastName}
                  />
                  {LnameError ? (
                    <p
                      className="text-start  position-absolute mt-2"
                      style={{ color: "red" }}
                    >
                      {LnameError}
                    </p>
                  ) : null}
                </div>

                <div className="mt-8 mb-5">
                  <h6 className="text-start ">
                   {intl.formatMessage({
                      id: "Addyourphonenumberincludingcountrycode",
                    })}
                  </h6>
                  <span>
                

                 <div>
                 <input
                      className="form-control mt-2"
                      type="number"
                      placeholder={intl.formatMessage({ id: "+1" })}
                      value={phone}

                      onChange={handleNumber}
                      onKeyDown={(e: any) => {
                        exceptThisSymbolsForPhoneNumber.includes(e.key) &&
                          e.preventDefault();
                        if (
                          e.target.value.length >= 15 &&
                          e.key !== "Backspace" &&
                          e.key !== "Delete"
                        ) {
                          e.preventDefault();
                          setphoneError(
                            "Must be entered between 7 and 15 digits"
                          );
                          setToggle(false);
                        }
                      }}
                    />
                    {/* <p style={{position:"absolute",top:"24.2rem",left:"10px"}}>
                         <i className="bi  text-dark bi-plus-lg"></i>
                    </p> */}
                 </div>
                        

                 
                  </span>{" "}
                  {phoneError ? (
                    <p
                      className="text-start  position-absolute mt-2"
                      style={{ color: "red" }}
                    >
                      {phoneError}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="mt-9 mb-5">
                <h6 className="text-start ">
                  {" "}
                  {intl.formatMessage({ id: "address" })}
                </h6>
                <div className="mt-2">
                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder={intl.formatMessage({ id: "address" })}
                    value={address}
                    onChange={handleAddres}
                  />
                  {addressError ? (
                    <p
                      className="text-start  position-absolute mt-2"
                      style={{ color: "red" }}
                    >
                      {addressError}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="mt-9 mb-5">
                <h6 className="text-start ">
                  {intl.formatMessage({ id: "dateofBirth" })}
                </h6>
                <div className="mt-2">
                  <input
                    className="form-control mt-2"
                    type="date"
                    value={date}
                    placeholder="DD/MM/YY"
                    max={getFifteenYearsAgoDate()}
                    onChange={handleDateChange}
                  />
                  {error && <div className="error">{error}</div>}

                  {error && <div className="error">{error}</div>}
                </div>
              </div>

              <div className="text-center ">
                {currentStep < 9 && (
                  <button
                    type="submit"
                    id="kt_sign_up_submit"
                    className="btn btn-lg btn-primary w-100 mb-2"
                    disabled={
                      toggle1 || !fName || !lName || !phone || !address || !date
                    }
                    onClick={PHONE_NUMBER_VERIFY}
                  >
                    {intl.formatMessage({ id: "continuebtn" })}
                  </button>
                )}

                {currentStep > 1 && (
                  <button
                    type="button"
                    id="kt_login_signup_form_cancel_button"
                    className="btn btn-lg btn-light-primary w-100 mb-5"
                    onClick={goToPreviousStep}
                  >
                    {intl.formatMessage({ id: "previousbtn" })}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {currentStep === 3 && (
          <div>
            <div
              className=" p-5 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className="mt-20 ms-2 ">
                <h1 className="text-secondary-emphasis">
                  {intl.formatMessage({ id: "enterVerificationCode" })}
                </h1>
              </div>
              <div className="mt-5 ms-2 text-warning-emphasis">
                <span>
                  {" "}
                  {intl.formatMessage({
                    id: "a4DigitVerificationCodeWasSentTo********45",
                  })}{" "}
                </span>{" "}
                <br></br>
                <br></br>
                <span>
                  {" "}
                  {intl.formatMessage({
                    id: "Pleasemakesureyouhaveaddedphonenumberincludingcountrycode",
                  })}{" "}
                </span>
              </div>
              <div className="ms-2">
                {" "}
                {temporaryOtpShow && (
                  <span className="">
                    {" "}
                    {intl.formatMessage({ id: "Otp" })} : {temporaryOtpShow}
                  </span>
                )}
              </div>
              <div className=" mt-4 mb-5 col-12 ">
                <input
                  className="form-control "
                  type="number"
                  name=""
                  id=""
                  value={verify}
                  onChange={handleVerify}
                  onKeyDown={(e: any) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                    if (
                      e.target.value.length >= 4 &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete"
                    ) {
                      e.preventDefault();
                      setVerifyError("Please enter 4 digit pin");
                    }
                  }}
                />{" "}
                {verifyError ? (
                  <p
                    className="text-start mt-2 position-absolute"
                    style={{ color: "red" }}
                  >
                    {verifyError}
                  </p>
                ) : null}
              </div>

              <div className="mt-2  fw-bold float-end">
                <div>
                  <Link
                    className={`text-warning-emphasis float-end ${
                      isResendEnabled ? "" : "disabled"
                    }`}
                    to="#"
                    onClick={isResendEnabled ? handleResendOtp : undefined}
                  >
                    <span> {intl.formatMessage({ id: "resendbtn" })}</span>
                  </Link>
                  <br />
                  <div className=" float-end ">
                    {" "}
                    {isResendEnabled ? null : (
                      <p className="fw-semibold">
                        {intl.formatMessage({ id: "resendCodeIn" })}
                        {timer} {intl.formatMessage({ id: "second" })}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="text-center  mt-20">
                <div className="text-center ">
                  {currentStep < 9 && (
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary w-100 mb-2"
                      disabled={!verify}
                      onClick={handleVerifyOtp}
                    >
                      {intl.formatMessage({ id: "verify" })}
                    </button>
                  )}

                  {currentStep > 1 && (
                    <button
                      type="button"
                      id="kt_login_signup_form_cancel_button"
                      className="btn btn-lg btn-light-primary w-100 mb-5"
                      onClick={goToPreviousStep}
                    >
                      {intl.formatMessage({ id: "previousbtn" })}
                    </button>
                  )}
                </div>{" "}
              </div>

              <div></div>
            </div>
          </div>
        )}
        {currentStep === 4 && (
          <div>
            <div
              className="text-center mt-8 p-2 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className="mt-9 mb-5">
                <h1>{intl.formatMessage({ id: "businessInformation" })}</h1>
                <div className="mt-10">
                  <h6 className="text-start ">
                    {intl.formatMessage({ id: "businessName" })}
                  </h6>

                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder={intl.formatMessage({ id: "businessName" })}
                    value={businessName}
                    onChange={handleBusinessName}
                  />
                  {bussinessNameError ? (
                    <p
                      className="text-start  position-absolute  mt-2"
                      style={{ color: "red" }}
                    >
                      {bussinessNameError}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="mt-9 mb-5">
                <h6 className="text-start ">
                  {intl.formatMessage({ id: "businesswebsite" })}
                </h6>

                <input
                  className="form-control  mt-2"
                  type="text"
                  placeholder={intl.formatMessage({ id: "businesswebsite" })}
                  value={businessWeb}
                  onChange={handleBusinessWeb}
                />
                {bussinessWebError ? (
                  <p
                    className="text-start  position-absolute  mt-2"
                    style={{ color: "red" }}
                  >
                    {bussinessWebError}
                  </p>
                ) : null}
              </div>

              <div className="text-center  mt-10">
                <div className="text-center ">
                  {currentStep < 9 && (
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary w-100 mb-2"
                      onClick={goToNextStep}
                      disabled={!(businessName && businessWeb)}
                    >
                      {intl.formatMessage({ id: "continuebtn" })}
                    </button>
                  )}

                  {currentStep > 1 && (
                    <button
                      type="button"
                      id="kt_login_signup_form_cancel_button"
                      className="btn btn-lg btn-light-primary w-100 mb-5"
                      onClick={goToPreviousStep}
                    >
                      {intl.formatMessage({ id: "previousbtn" })}
                    </button>
                  )}
                </div>{" "}
              </div>
            </div>
          </div>
        )}
        {currentStep === 5 && (
          <div>
            <div
              className="text-center mt-8 p-2 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className=" text-warning-emphasis">
                <h1>{intl.formatMessage({ id: "businessAddress" })}</h1>
              </div>
              <div className="mt-15  mb-5">
                <h6 className="text-start ">
                  {intl.formatMessage({ id: "businessAddress" })}
                </h6>
                <div className="mt-2 ">
                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder={intl.formatMessage({ id: "businessAddress" })}
                    value={businessAddress}
                    onChange={handleBusinessAddress}
                  />
                  {bussinessAddressError ? (
                    <p
                      className="text-start  position-absolute  mt-2"
                      style={{ color: "red" }}
                    >
                      {bussinessAddressError}
                    </p>
                  ) : null}
                </div>
              </div>
              {/* <div className="mt-9 mb-5">
                <h6 className="text-start ">
                  {intl.formatMessage({ id: "pointOfContactAddress" })}
                </h6>

                <div className="mt-2">
                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder={intl.formatMessage({
                      id: "pointOfContactAddress",
                    })}
                    value={pointContactAddress}
                    onChange={
                    handlePointAddress
                    }
                  />
                  {pointError ? (
                    <p
                      className="text-start  position-absolute  mt-2"
                      style={{ color: "red" }}
                    >
                      {pointError}
                    </p>
                  ) : null}
                </div>
              </div> */}
              <div className="text-center  mt-10">
                <div className="text-center ">
                  {currentStep < 9 && (
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary w-100 mb-2"
                      onClick={goToNextStep}
                      disabled={!businessAddress}
                    >
                      {intl.formatMessage({ id: "continuebtn" })}
                    </button>
                  )}
                  {currentStep > 1 && (
                    <button
                      type="button"
                      id="kt_login_signup_form_cancel_button"
                      className="btn btn-lg btn-light-primary w-100 mb-5"
                      onClick={goToPreviousStep}
                    >
                      {intl.formatMessage({ id: "previousbtn" })}
                    </button>
                  )}
                </div>{" "}
              </div>
            </div>
          </div>
        )}
        {currentStep === 6 && (
          <div>
            <div
              className="text-center mt-8 p-2 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className=" text-warning-emphasis">
                <h1> {intl.formatMessage({ id: "reviewYourInformation" })}</h1>
              </div>
              <div className="mt-4 text-black-50">
                <p>
                  {intl.formatMessage({
                    id: "yourInformationWillBeUsedToVerifyYourIdentityKindlyConfirmEverythingIsCorrectToAvoidDelays",
                  })}
                </p>
              </div>
              <div className="mt-5">
                <h6 className="text-start ">
                  {intl.formatMessage({ id: "name" })}
                </h6>
                <div className="mt-2 ">
                  <input
                    className="form-control mt-2"
                    type="text"
                    placeholder={intl.formatMessage({ id: "name" })}
                    disabled
                    value={`${fName}${" "}${lName}`}
                  />
                </div>
              </div>
              <div className="mt-5">
                <h6 className="text-start ">
                  {" "}
                  {intl.formatMessage({ id: "address" })}
                </h6>
                <div className="mt-2">
                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder={intl.formatMessage({ id: "address" })}
                    disabled
                    value={address}
                  />
                </div>
              </div>
              <div className="mt-5 mb-6">
                <h6 className="text-start ">
                  {intl.formatMessage({ id: "dateofBirth" })}
                </h6>
                <div className="mt-2">
                  <input
                    className="form-control  mt-2"
                    type="date"
                    value={date}
                    placeholder="DD/MM/YY"
                    disabled
                  />
                </div>
              </div>
              <div className="text-center  mt-10">
                <div className="text-center ">
                  {currentStep < 9 && (
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary w-100 mb-2"
                      onClick={goToNextStep}
                    >
                      {intl.formatMessage({ id: "continuebtn" })}
                    </button>
                  )}

                  {currentStep > 1 && (
                    <button
                      type="button"
                      id="kt_login_signup_form_cancel_button"
                      className="btn btn-lg btn-light-primary w-100 mb-5"
                      onClick={goToPreviousStep}
                    >
                      {intl.formatMessage({ id: "previousbtn" })}
                    </button>
                  )}
                </div>{" "}
              </div>
            </div>
          </div>
        )}
        {currentStep === 7 && (
          <div>
            <div
              className="text-center mt-8 p-2 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              <div className=" text-warning-emphasis">
                <h1>
                  {intl.formatMessage({ id: "reviewYourBusinessInformation" })}
                </h1>
              </div>
              <div className="mt-5 text-black-50">
                <p>
                  {intl.formatMessage({
                    id: "yourInformationWillBeUsedToVerifyYourIdentityKindlyConfirmEverythingIsCorrectToAvoidDelays",
                  })}
                </p>
              </div>
              <div className="mt-5">
                <h6 className="text-start ">
                  {intl.formatMessage({ id: "businessName" })}
                </h6>

                <div className=" ">
                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder={intl.formatMessage({ id: "businessName" })}
                    disabled
                    value={businessName}
                  />
                </div>
              </div>
              <div className="mt-5">
                <h6 className="text-start ">
                  {intl.formatMessage({ id: "businessAddress" })}
                </h6>

                <div className="">
                  <input
                    className="form-control mt-2"
                    type="text"
                    disabled
                    placeholder={intl.formatMessage({ id: "businessAddress" })}
                    value={businessAddress}
                  />
                </div>
              </div>
              <div className="mt-5 mb-6">
                <h6 className="text-start ">
                  {intl.formatMessage({ id: "businesswebsite" })}
                </h6>

                <div className="">
                  <input
                    className="form-control  mt-2"
                    type="text"
                    placeholder={intl.formatMessage({ id: "businesswebsite" })}
                    value={businessWeb}
                    disabled
                  />
                </div>
              </div>
              <div className="text-center  mt-10">
                <div className="text-center ">
                  {currentStep < 9 && (
                    <button
                      type="submit"
                      id="kt_sign_up_submit"
                      className="btn btn-lg btn-primary w-100 mb-2"
                      onClick={goToNextStep}
                    >
                      {intl.formatMessage({ id: "continuebtn" })}
                    </button>
                  )}

                  {currentStep > 1 && (
                    <button
                      type="button"
                      id="kt_login_signup_form_cancel_button"
                      className="btn btn-lg btn-light-primary w-100 mb-5"
                      onClick={goToPreviousStep}
                    >
                      {intl.formatMessage({ id: "previousbtn" })}
                    </button>
                  )}
                </div>{" "}
              </div>
            </div>
          </div>
        )}
        {currentStep === 8 && (
          <div>
            <div
              className="  p-2 "
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              {skipbuttonEnable && (
                <div className="conatiner d-flex justify-content-between ">
                  <span className="mb-5" onClick={handleSkipSubmit}>
                    <Link to="#"> {intl.formatMessage({ id: "skipbtn" })}</Link>
                  </span>
                </div>
              )}
              <div className="conatiner text-center mt-5">
                <div>
                  <h2>
                    {intl.formatMessage({ id: "completeAccountVerification" })}
                  </h2>
                </div>
                <div>
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
                    height={10}
                    width={20}
                    alt=""
                  />
                  {intl.formatMessage({ id: "2Minutes" })}
                </div>

                <div>
                  <div className="text-center  mt-10">
                    <div className="text-center ">
                      {currentStep < 9 && (
                        <button
                          type="submit"
                          id="kt_sign_up_submit"
                          className="btn btn-lg btn-primary w-100 mb-2"
                          disabled={toggle1}
                          onClick={handleSubmit}
                        >
                          {intl.formatMessage({ id: "continuebtn" })}
                        </button>
                      )}

                      {currentStep > 1 && (
                        <button
                          type="button"
                          id="kt_login_signup_form_cancel_button"
                          className="btn btn-lg btn-light-primary w-100 mb-5"
                          onClick={goToPreviousStep}
                        >
                          {intl.formatMessage({ id: "previousbtn" })}
                        </button>
                      )}
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
