/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link } from "react-router-dom";
import styles from "../../../scss/Card.module.scss";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { useLang, setLanguage } from "../../../_metronic/i18n/Metronici18n";

import clsx from "clsx";
import { useIntl } from "react-intl";

const languages = [
  {
    lang: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/flags/united-states.svg"),
  },
  {
    lang: "zh",
    name: "Mandarin",
    flag: toAbsoluteUrl("/media/flags/china.svg"),
  },
  {
    lang: "es",
    name: "Spanish",
    flag: toAbsoluteUrl("/media/flags/spain.svg"),
  },
  {
    lang: "ja",
    name: "Japanese",
    flag: toAbsoluteUrl("/media/flags/japan.svg"),
  },
  {
    lang: "de",
    name: "German",
    flag: toAbsoluteUrl("/media/flags/germany.svg"),
  },
  {
    lang: "fr",
    name: "French",
    flag: toAbsoluteUrl("/media/flags/france.svg"),
  },
];

export default function LanguageDisplaySetting() {
  const intl = useIntl();
  const lang = useLang();
  const currentLanguage = languages.find((x) => x.lang === lang);

  return (
    <div
      data-aos="fade-left"
      data-aos-anchor="#example-anchor"
      data-aos-offset="500"
      data-aos-duration="500"
      className="p-10 bg-white shadow-sm p-4 mb-5 bg-body-tertiary rounded"
    >
      <div className="d-flex">
        <div>
          <Link to="/accountsetting">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
              height={20}
              width={30}
              alt=""
            />
          </Link>
        </div>
        <h2 className={styles.handleHeader}>
          {intl.formatMessage({ id: "display&Language" })}
        </h2>
      </div>

      <div className="d-flex justify-content-start p-10">
        <div className=" mt-8 col-lg-12 p-5  ">
          <div className="conatiner d-flex justify-content-between mt-5">
            <div className="">
              <div
                className=" menu-item px-5"
                data-kt-menu-trigger="hover"
                data-kt-menu-placement="left-start"
                data-kt-menu-flip="bottom"
              >
                <a href="#" className="menu-link px-5">
                  <span className="menu-title position-relative">
                  {intl.formatMessage({ id: "language" })}
                    <span className="fs-8 rounded bg-light py-2 position-absolute translate-middle-y top-50 end-0">
                      {currentLanguage?.name}{" "}
                      <img
                        className="w-15px h-15px rounded-1 ms-2"
                        src={currentLanguage?.flag}
                        alt="Good Send"
                      />
                    </span>
                  </span>
                </a>

                <div className=" w-175px py-4">
                  {languages && languages?.map((l) => (
                    <div
                      className="menu-item px-3"
                      key={l.lang}
                      onClick={() => {
                        setLanguage(l.lang);
                      }}
                    >
                      <a
                        href="#"
                        className={clsx("menu-link d-flex px-5", {
                          active: l.lang === currentLanguage?.lang,
                        })}
                      >
                        <span className="symbol symbol-20px me-4">
                          <img
                            className="rounded-1"
                            src={l.flag}
                            alt="Good send"
                          />
                        </span>
                        {l.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
