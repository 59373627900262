import { Link, useLocation } from "react-router-dom";

function TransactionList() {
  const location = useLocation();

  return (
    <div>
      <div className="d-flex overflow-auto h-40px">
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
          <li className="nav-item">
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === "/transactionlist/1" && "active")
              }
              to="/transactionlist/1"
            >
              Fund Transaction
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === "/transactionlist/2" && "active")
              }
              to="/transactionlist/2"
            >
              Withdrawal Transaction
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === "/transactionlist/3" && "active")
              }
              to="/transactionlist/3"
            >
              Move Transaction
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === "/transactionlist/4" && "active")
              }
              to="/transactionlist/4"
            >
              Send Transaction
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TransactionList;
