/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function LogOut() {
  let navigate = useNavigate();

  useEffect(() => {
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("user_Details");
    // localStorage.clear();
    localStorage.removeItem("GsTagNumber");
    // localStorage.removeItem("i18nConfig");
    localStorage.removeItem("Email");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("kt_theme_mode_value");
    localStorage.removeItem("Gsbalance");
    navigate("/auth/login");
  }, []);
  return <></>;
}
