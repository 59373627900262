
// /* eslint-disable jsx-a11y/anchor-is-valid */

import { Link } from "react-router-dom";

import { useIntl } from "react-intl";

const HeaderToolbar = () => {
  const intl = useIntl()
  return (
    <div className="toolbar d-flex align-items-stretch">
      <div
        className={` py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
      >
        <nav className="navbar navbar-expand-lg  col-lg-12 m-5 ">
          <div className="container-fluid ">
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav grid gap-15 row-gap-3">
                <li className="nav-item d-flex ">
                  <Link className="navbar-brand fs-4" to="/wallet">
                    <span className="svg-icon svg-icon-secondry svg-icon-2x bg-white rounded p-2 hover-scale">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <circle
                            fill="#000000"
                            opacity="0.3"
                            cx="20.5"
                            cy="12.5"
                            r="1.5"
                          />
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            transform="translate(12.000000, 6.500000) rotate(-15.000000) translate(-12.000000, -6.500000) "
                            x="3"
                            y="3"
                            width="18"
                            height="7"
                            rx="1"
                          />
                          <path
                            d="M22,9.33681558 C21.5453723,9.12084552 21.0367986,9 20.5,9 C18.5670034,9 17,10.5670034 17,12.5 C17,14.4329966 18.5670034,16 20.5,16 C21.0367986,16 21.5453723,15.8791545 22,15.6631844 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,9.33681558 Z"
                            fill="#000000"
                          />
                        </g>
                      </svg>{" "}
                      <span className="ms-1 fs-5">{intl.formatMessage({id: 'wallet'})}</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item d-flex ">
                  <Link
                    className="nav-link active fs-4 "
                    aria-current="page"
                    to="/card"
                  >
                    <span className="svg-icon svg-icon-secondry svg-icon-2x bg-white rounded p-2 hover-scale">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            x="2"
                            y="5"
                            width="20"
                            height="14"
                            rx="2"
                          />
                          <rect
                            fill="#000000"
                            x="2"
                            y="8"
                            width="20"
                            height="3"
                          />
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            x="16"
                            y="14"
                            width="4"
                            height="2"
                            rx="1"
                          />
                        </g>
                      </svg>
                      <span className="ms-2 fs-5">{intl.formatMessage({id: 'card'})}</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item d-flex ">
                  <Link className="nav-link active fs-4 " to="/move">
                    <span className="svg-icon svg-icon-secondry svg-icon-2x bg-white rounded p-2 hover-scale">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />

                          <rect
                            fill="#000000"
                            opacity="0.3"
                            transform="translate(13.000000, 6.000000) rotate(-450.000000) translate(-13.000000, -6.000000) "
                            x="12"
                            y="8.8817842e-16"
                            width="2"
                            height="12"
                            rx="1"
                          />
                          <path
                            d="M9.79289322,3.79289322 C10.1834175,3.40236893 10.8165825,3.40236893 11.2071068,3.79289322 C11.5976311,4.18341751 11.5976311,4.81658249 11.2071068,5.20710678 L8.20710678,8.20710678 C7.81658249,8.59763107 7.18341751,8.59763107 6.79289322,8.20710678 L3.79289322,5.20710678 C3.40236893,4.81658249 3.40236893,4.18341751 3.79289322,3.79289322 C4.18341751,3.40236893 4.81658249,3.40236893 5.20710678,3.79289322 L7.5,6.08578644 L9.79289322,3.79289322 Z"
                            fill="#000000"
                            fillRule="nonzero"
                            transform="translate(7.500000, 6.000000) rotate(-270.000000) translate(-7.500000, -6.000000) "
                          />
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            transform="translate(11.000000, 18.000000) scale(1, -1) rotate(90.000000) translate(-11.000000, -18.000000) "
                            x="10"
                            y="12"
                            width="2"
                            height="12"
                            rx="1"
                          />
                          <path
                            d="M18.7928932,15.7928932 C19.1834175,15.4023689 19.8165825,15.4023689 20.2071068,15.7928932 C20.5976311,16.1834175 20.5976311,16.8165825 20.2071068,17.2071068 L17.2071068,20.2071068 C16.8165825,20.5976311 16.1834175,20.5976311 15.7928932,20.2071068 L12.7928932,17.2071068 C12.4023689,16.8165825 12.4023689,16.1834175 12.7928932,15.7928932 C13.1834175,15.4023689 13.8165825,15.4023689 14.2071068,15.7928932 L16.5,18.0857864 L18.7928932,15.7928932 Z"
                            fill="#000000"
                            fillRule="nonzero"
                            transform="translate(16.500000, 18.000000) scale(1, -1) rotate(270.000000) translate(-16.500000, -18.000000) "
                          />
                        </g>
                      </svg>

                      <span className="ms-2 fs-5">{intl.formatMessage({id: 'move'})}</span>
                    </span>
                  </Link>
                </li>

                <li className="nav-item d-flex ">
                  <Link className="nav-link active fs-4 " to="/send">
                    <span className="svg-icon svg-icon-secondry svg-icon-2x bg-white rounded p-2 hover-scale">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <path
                            d="M4,16 L5,16 C5.55228475,16 6,16.4477153 6,17 C6,17.5522847 5.55228475,18 5,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 Z M1,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 C-6.76353751e-17,11.4477153 0.44771525,11 1,11 Z M3,6 L5,6 C5.55228475,6 6,6.44771525 6,7 C6,7.55228475 5.55228475,8 5,8 L3,8 C2.44771525,8 2,7.55228475 2,7 C2,6.44771525 2.44771525,6 3,6 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                          <path
                            d="M10,6 L22,6 C23.1045695,6 24,6.8954305 24,8 L24,16 C24,17.1045695 23.1045695,18 22,18 L10,18 C8.8954305,18 8,17.1045695 8,16 L8,8 C8,6.8954305 8.8954305,6 10,6 Z M21.0849395,8.0718316 L16,10.7185839 L10.9150605,8.0718316 C10.6132433,7.91473331 10.2368262,8.02389331 10.0743092,8.31564728 C9.91179228,8.60740125 10.0247174,8.9712679 10.3265346,9.12836619 L15.705737,11.9282847 C15.8894428,12.0239051 16.1105572,12.0239051 16.294263,11.9282847 L21.6734654,9.12836619 C21.9752826,8.9712679 22.0882077,8.60740125 21.9256908,8.31564728 C21.7631738,8.02389331 21.3867567,7.91473331 21.0849395,8.0718316 Z"
                            fill="#000000"
                          />
                        </g>
                      </svg>
                      <span className="ms-2 fs-5">{intl.formatMessage({id: 'send'})}</span>
                    </span>
                   
                  </Link>
                </li>
                <li className="nav-item d-flex ">
                  <Link className="nav-link active fs-4 " to="/setting">
                    <span className="svg-icon svg-icon-secondry svg-icon-2x bg-white rounded p-2 hover-scale">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <path
                            d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z"
                            fill="#000000"
                          />
                        </g>
                      </svg>
                      <span className="ms-1 fs-5">{intl.formatMessage({id: 'menu'})}</span>
                    </span>

                  
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>

  );
};

export { HeaderToolbar };
