/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Services from "../Services";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { useIntl } from "react-intl";


export default function WithdrawalList(className) {
  const intl = useIntl();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [total_list, setTotal_list] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [data, setData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [transactionHistroy, setTransactionHistory] = useState([]);


  const getwithdrawallist = async () => {
    try {
      let object: any = {
        typeOfTxn: 4,
        page: page,
        limit: rows,
        search: search,
      };
      let payload: any = new URLSearchParams(object).toString();
      const response: any =
        await Services.Transaction.GET_TRANSACTIONS_USER_FUND(payload);
      setTransactionHistory(response?.data?.data?.rows);
      setData(response?.data?.data?.rows);
      setTotalCount(response?.data?.data?.count / rows);
      setTotal_list(response?.data?.data?.count);
    } catch(error) {console.log(error);}
  };

  useEffect(() => {
    getwithdrawallist();
  }, [search, page, rows]);
  const handlePageClick = (event) => {
    const crrPage = event.selected + 1;
    setCurrentPage(crrPage);
    setPage(crrPage);
  };
  const searchHandler = (val) => {
    setSearch(val);
  };
  return (
    <div>
         <div className="mt-5 ">
      <div className={`card ${className} p-5`}>
      <div className="card-body py-3 ">
          <div className="table-responsive">
            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
              <thead className="text-center">
                <tr className="fw-bold text-muted">
                
                  <th className="min-w-140px text-dark  "
                  
                  >
                    {intl.formatMessage({ id: "adminNotes" })}
                  </th>
                  <th className="min-w-140px  text-dark ">
                    {intl.formatMessage({ id: "userNotes" })}
                  </th>
                  <th className="min-w-200px text-dark ">
                    {intl.formatMessage({ id: "AMOUNT" })}
                  </th>
               
                  <th className="min-w-140px text-dark ">
                    {intl.formatMessage({ id: "date&Time" })}
                  </th>
                </tr>
              </thead>

              <tbody className="text-center text-gray-600 fw-semibold">
                {transactionHistroy && transactionHistroy?.map((item: any, index) => {
                  return (
                    <tr>
                      <td className=""
                       style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        overflowWrap: "break-word", 
                        maxWidth: "20ch", 
                      }}
                      >
                        <span className="fw-bold text-hover-primary d-block mb-1 fs-6">
                          {item.adminNotes}
                        </span>
                      </td>
                      <td className=""
                       style={{
                        whiteSpace: "pre-wrap", 
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                        maxWidth: "20ch", 
                      }}
                      >
                        <span className=" fw-bold text-hover-primary d-block mb-1 fs-6">
                          {item.userNotes}
                        </span>
                      </td>
                      <td>
                        <span className=" fw-bold text-hover-primary d-block mb-1 fs-6">
                          {item.balance}
                        </span>
                      </td>
                      <td>
                        <span className="fw-bold text-hover-primary d-block mb-1 fs-6">
                          {moment(item.createdAt).format(
                            "MMM DD, YYYY hh:mm A"
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div>
        {data && totalCount > 1 ? (
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={first}
            pageCount={totalCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        ) : null}
      </div>
      </div>
      </div>
    
    </div>
  );
}
