/* eslint-disable @typescript-eslint/no-unused-vars */
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useIntl } from "react-intl";


export default function RecentTransactions() {
  const intl = useIntl()
 
  const location = useLocation();

  return (
    <div>
     
     <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/recenttransaction/1' && 'active')
                }
                to='/recenttransaction/1'
              >
                {intl.formatMessage({id: 'fundTransaction'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/recenttransaction/2' && 'active')
                }
                to='/recenttransaction/2'
              >
                 {intl.formatMessage({id: 'moveTransaction'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/recenttransaction/3' && 'active')
                }
                to='/recenttransaction/3'
              >
               {intl.formatMessage({id: 'sendTransaction'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/recenttransaction/4' && 'active')
                }
                to='/recenttransaction/4'
              >
               {intl.formatMessage({id: 'withdrawalTransaction'})}
              </Link>
            </li>
            
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/recenttransaction/getReceivedTracnsactionMe/3' && 'active')
                }
                to='/recenttransaction/getReceivedTracnsactionMe/3'
              >
               {intl.formatMessage({id: 'ReceivedTransaction'})}
            
              </Link>
            </li>
          </ul>
        </div>
        </div>
  );
}