import { Link, useNavigate } from "react-router-dom";
import styles from "../../../scss/Card.module.scss";
import { useIntl } from "react-intl";

export default function Security() {
  const intl = useIntl();

  const navigate = useNavigate();

  const handlepin = () => {
    navigate("/setuptransaction");

    window.location.reload();
  };

  const handlePageChangePassword = () => {
    navigate("");
  };

  return (
    <div>
      <div
        data-aos="fade-left"
        data-aos-anchor="#example-anchor"
        data-aos-offset="500"
        data-aos-duration="500"
      >
        {" "}
        <div></div>
        <div className="bg-white d-flex justify-content-start ms-4 shadow-sm p-10 mb-5 bg-body-tertiary rounded mt-10">
          <div className=" col-lg-12  ">
            <div className="d-flex">
              <div>
                <Link to="/setting">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm0n0prKe1fHmyDIdCMDU0sJNXK6bcyLe28A&usqp=CAU"
                    height={20}
                    width={30}
                    alt=""
                  />
                </Link>
              </div>
              <div></div>
              <h2 className={styles.handleHeader}>
                {intl.formatMessage({ id: "security&Privacy" })}
              </h2>
            </div>

            <div className="conatiner d-flex justify-content-between mt-5"
            onClick={handlepin}
            >
              <div>
                <p className="fw-semibold" >
                  {intl.formatMessage({ id: "setupPinForTransactions" })}
                </p>
              </div>
              <div className="mt-1 ms-1">
                <span
                  className="svg-icon svg-icon-primary svg-icon-2x"
                  onClick={handlepin}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </div>

              <Link to="/changepassword">
            <div className="conatiner d-flex justify-content-between mt-5">
                <div>
                  <span
                    className="fw-semibold text-dark"
                    onClick={() => handlePageChangePassword}
                  >
                    {intl.formatMessage({ id: "changePassword" })}
                  </span>
                </div>
           
              <div className="mt-1 ms-1">
                <Link to="/changepassword">
                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                        />
                      </g>
                    </svg>
                  </span>
                </Link>
              </div>
            </div>
            </Link>
            <div className="conatiner d-flex justify-content-between mt-5">
              <div>
                <p className="fw-semibold">
                  {intl.formatMessage({ id: "gsPrivacy" })}
                </p>
              </div>
              <div className="mt-1 ms-1">
                <span className="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
            <div className="conatiner d-flex justify-content-between mt-5">
              <div>
                <p className="fw-semibold">
                  {intl.formatMessage({ id: "gsSecurity" })}
                </p>
              </div>
              <div className="mt-1 ms-2">
                <span className="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
            <div className="conatiner d-flex justify-content-between mt-5">
              <div>
                <p className="fw-semibold">
                  {intl.formatMessage({ id: "gsTermOfUse" })}
                </p>
              </div>
              <div className="mt-1 ms-2">
                <span className="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
