/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import constant from "../Constant";
import api from "../api";
import Constant from "../Constant";

export default {
    GET_WALLET_BY_ID: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Wallet.GET_WALLET_BY_ID(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    }); 
  },
  GET_BANK_DETAILS: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Wallet.GET_BANK_DETAILS()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
    // GET_BANK_DETAILS
  },
   //ADMIN get bank details

   GET_ADMIN_BANKDETAILS: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Wallet.GET_ADMIN_BANKDETAILS()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    }); 
  },
  
 //ADMIN update bank details

 UPDATE_ADMIN_BANKDETAILS: (id,data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Axios.put(
        constant.BASE_URL + api.Wallet.UPDATE_ADMIN_BANKDETAILS(id),data
      );
      resolve(response);
    } catch (err) {
      reject(err);
    }
  }); 
},

};
