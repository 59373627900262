/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-lone-blocks */

import React, { useEffect, useState } from 'react';

import Services from '../Services';
import Constant from '../Constant';

import { useIntl } from 'react-intl';

const Card = () => {
  const [QrCodeData, setQrCodeData] = useState('')
  const intl = useIntl();
  const [userData, setUserData] = useState('')
  const [firstNameData, setFirstNameData] = useState('')
  const [lastNameData, setLastNameData] = useState('')
  const [gsTagData, setGstagData] = useState('')

  const GET_USER_QR_Code = async () => {
    try {
      const response: any = await Services.User.GET_USER();
      setQrCodeData(response?.data?.data?.qrCode)
      setFirstNameData(response?.data?.data?.firstName)
      setLastNameData(response?.data?.data?.lastName)
      setUserData(response?.data?.data)
      setGstagData(response?.data?.data?.gsTag)

    } catch(error) {console.log(error);}
  };

  useEffect(() => {
    GET_USER_QR_Code();
  }, []);

  return (
    <div className="container bg-white p-15">
      <div className=" d-flex justify-content-center align-items-center " >
        <div className="card bg-warning p-3 rounded" style={{ width: "250px", height: "170px" }}>
          <h2 className="text-white"> {intl.formatMessage({ id: "gsGoldCard" })}</h2>
          <h3 className="text-white mt-3">{gsTagData}</h3>

          <span className="text-white mt-4">{intl.formatMessage({ id: "cardHolderName" })}</span>
          <h3
            className="text-white mt-2"
            style={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              maxWidth: "30ch",
            }}
          >
            {firstNameData?.length > 15
              ? firstNameData?.substring(0, 10) + "..."
              : firstNameData}{" "}
            &nbsp;
            {lastNameData?.length > 15
              ? lastNameData.substring(0, 10) + "..."
              : lastNameData}
          </h3>
        </div>
      </div>


      <div className='d-flex justify-content-center align-items-sm-center  mt-5'>

        {QrCodeData ? (
          <img
            crossOrigin="anonymous"
            src={`${Constant.BASE_URL_QR_CODE}${QrCodeData}`}
            height={250} width={250}
            alt='QR Code' />
        ) : (
          <div className='spinner-border text-warning mt-20'></div>

        )}
      </div>



    </div>
  );
};

export default Card;

