/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Services from "../Services";
import Styles from "../scss/Card.module.scss";
import Toster from "../Components/AdminPanelToster";
import Spinner from "../Components/Spinner";
import { useIntl } from "react-intl";

export default function Move() {
  const intl = useIntl();

  interface ToasterState {
    type: string;
    title: string;
    text: string;
    visiblity: string;
  }

  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });

  const initialIntlMessageGS = intl.formatMessage({ id: "gsGoldAccount" });
  const initialIntlMessagePR = intl.formatMessage({ id: "primaryAccount" });
  const exceptThisSymbols = ["+", "-", " "];
  const [gsAccountL, setGsAccountL] = useState({
    intlMessage: initialIntlMessageGS,
  });
  const [primaryAccountL, setPrimaryAccountL] = useState({
    intlMessage: initialIntlMessagePR,
  });

  const [amount, setAmount] = useState<any>(0);

  const [primaryAccount, setPrimaryAccount] = useState("");
  const [gsGoldAccount, setGSGoldAccount] = useState("");
  const [fromAccount, setFromAccount] = useState("");
  const [toAccount, setToAccount] = useState("");
  const [storeData, setStoreData] = useState({});
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [fromId, setFromID] = useState("");
  const [toId, setToID] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);
  const [amountError, setAmountError] = useState<any>("");
  const [balancePrimary, setBalancePrimary] = useState<number>(0);
  const [balanceGsGold, setBalanceGSGold] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [transactionFee, setTransactionFee] = useState<number>(0);

  const navigate = useNavigate();

  const getMoveAccount = async () => {
    try {
      const response: any = await Services.Move.GET_MOVE_ACCOUNT();

      let data = response.data;
      data.gsAccount.type = "gsAccount";
      data.primaryAccount.type = "primaryAccount";
      setPrimaryAccount(data?.primaryAccount?.type);
      setGSGoldAccount(data?.gsAccount?.type);
      setFromAccount(data?.primaryAccount?.type);
      setToAccount(data?.gsAccount?.type);
      setFromID(response?.data?.primaryAccount?.id);
      setToID(response?.data?.gsAccount?.id);
      setBalanceGSGold(response?.data?.gsAccount?.balance);
      setBalancePrimary(response?.data?.primaryAccount?.balance);
    } catch(error) {console.log(error);}
  };

  useEffect(() => {
    getMoveAccount();
  }, []);

  const handleFromAccountChange = (event) => {
    const selectedAccount = event.target.value;
    setFromAccount(selectedAccount);
    setAmount(0);
    setAmountError("");
    if (selectedAccount === gsGoldAccount) {
      setToAccount(primaryAccount);
    } else if (selectedAccount === primaryAccount) {
      setToAccount(gsGoldAccount);
    } else {
      setToAccount(gsGoldAccount);
    }
  };

  const goToNextStep = () => {
    localStorage.setItem("currentStep", (currentStep + 1).toString());
    setCurrentStep(currentStep + 1);
    localStorage.setItem("currentStep", (currentStep + 1).toString());
    setStoreData({
      fromAccount,
      toAccount,
      amount,
    });
    setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    localStorage.setItem("currentStep", (currentStep - 1).toString());

    setCurrentStep(currentStep - 1);
  };

  const payload = {
    fromAccount: fromAccount === "primaryAccount" ? fromId : toId,
    toAccount: fromAccount === "primaryAccount" ? toId : fromId,
    amount: amount,
    type: fromAccount,
  };

  const handlesubmit = async () => {
    try {
      setIsDisabled(true);
      const response: any = await Services.Move.UPDATE_MOVE_ACCOUNT(payload);
      if (response) {
        setToaster({
          type: "success",
          title: "Successful",
          text: "Exchange  successfully",
          visiblity: "show",
        });

        setTimeout(() => {
          setToaster({
            type: "",
            title: "",
            text: "",
            visiblity: "hide",
          });
        }, 1000);
        setTimeout(() => {
          navigate("/Transactionsuccessfull");
        }, 1500);
      }} catch(error) {console.log(error);}
  };

  function amountHandle(val: any) {
    // const transactionFees = (1 * val) / 100;
    // setTransactionFee(transactionFees)

    if (fromAccount === "primaryAccount") {
      if (val > balancePrimary) {
        setAmountError("Insufficient Balance");
      } else {
        setAmountError("");
      }
    } else {
      if (val > balanceGsGold) {
        setAmountError("Insufficient Balance");
      } else {
        setAmountError("");
      }
    }
  }
  const handleChange = (e) => {
    if (e.target.value < "0") {
      setAmount(0);
      return;
    }
    if (e.target.value == "00") {
      setAmount("");
      return;
    }

    amountHandle(e.target.value);
    if (e.target.value.length > 25) {
      setAmountError("");
    } else {
      setAmount(+e.target.value);
    }
  };
  return (
    <div>
      <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      {!isLoaded && <Spinner />}
      {currentStep === 1 && (
        <div
          className=" "
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className=" position-absolute container bg-white shadow p-10 mb-3 bg-body-tertiary rounded">
            <div className="">
              <h2 className="text-center">
                {intl.formatMessage({ id: "exchange" })}
              </h2>
              <div className="d-flex align-content-start p-5">
                <div className="col-lg-12">
                  {" "}
                  <h3 className="">
                    {intl.formatMessage({ id: "fromAccount" })}
                  </h3>
                  <div className="mb-3">
                    <select
                      style={{ width: "101%" }}
                      id="disabledSelect"
                      className="form-select p-3 mb-2 text-gray fw-semibold"
                      value={fromAccount}
                      onChange={handleFromAccountChange}
                    >
                      <option value={primaryAccount}>
                        {intl.formatMessage({ id: "primaryAccount" })}
                      </option>
                      <option value={gsGoldAccount}>
                        {intl.formatMessage({ id: "gsGoldAccount" })}
                      </option>
                    </select>
                    <span className="fw-bold">
                      {intl.formatMessage({ id: "balanceis" })} :{" "}
                    </span>
                    {toAccount === "gsAccount" ? (
                     <span className="fw-bold">
                     {" "}
                     $ {fromAccount === gsGoldAccount && balanceGsGold}
                   </span>
                   
                    ) : (
                      <span className="fw-bold">
                        {" "}
                        {intl.formatMessage({ id: "xau" })}{" "}
                        {fromAccount === primaryAccount && balancePrimary.toFixed(2)}
                      </span>
                    )}
                    <span className="fw-bold">
                      {fromAccount === primaryAccount && balancePrimary.toFixed(2)}
                      {fromAccount === gsGoldAccount && balanceGsGold}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="d-flex align-content-start p-1 ms-3">
                <div className="col-lg-12">
                  {" "}
                  <h3>{intl.formatMessage({ id: "toAccount" })}</h3>
                  <div className="mb-3">
                    <div className="d-flex align-content-start p-2">
                      <select
                        style={{ width: "100%" }}
                        id="disabledSelect"
                        disabled={true}
                        className="form-select p-3 mb-2 text-gray bg-white fw-semibold"
                        value={toAccount}
                      >
                        <option value={primaryAccount}>
                          {intl.formatMessage({ id: "primaryAccount" })}
                        </option>
                        <option value={gsGoldAccount}>
                          {intl.formatMessage({ id: "gsGoldAccount" })}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="col-lg-12 ">
                <span className=" fw-semibold  ">
                  <div className="fv-row">
                    <div className="ms-5">
                      <label className="form-label mb-3 p-1 ">
                        {intl.formatMessage({ id: "enterAmount" })}
                      </label>

                      <input
                        type="number"
                        style={{ width: "97%" }}
                        className={`mb-3 form-control form-control-lg  form-control-solid ms-3 ${Styles.amount}`}
                        name="account_name"
                        placeholder={intl.formatMessage({ id: "AMOUNT" })}
                        value={amount}
                        onChange={handleChange}
                        onKeyDown={(e: any) => {
                          exceptThisSymbols.includes(e.key) &&
                            e.preventDefault();

                          if (
                            e.target.value.length >= 25 &&
                            e.key !== "Backspace" &&
                            e.key !== "Delete"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <div className="p-3">
                        {amountError && (
                          <>
                            <span
                              className=""
                              style={{
                                color: "red",
                                position: "absolute",
                              }}
                            >
                              {amountError}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="ms-4">
                      <label className="form-label mb-3 p-3">
                        <span>
                          {" "}
                          {intl.formatMessage({ id: "transactionFees" })} :{" "}
                        </span>
                      </label>

                      <input
                        style={{ width: "97%" }}
                        type="number"
                        disabled={true}
                        className={`mb-3 form-control form-control-lg form-control-solid ms-3 ${Styles.enterammount}`}
                        name="account_name"
                        placeholder={intl.formatMessage({ id: "AMOUNT" })}
                        value={
                          fromAccount === "primaryAccount"
                            ? (amount * 3) / 100
                            : (amount * 1) / 100
                        }
                        onChange={handleChange}
                      />
                    </div>
                    <div className="ms-4">
                      <label className="form-label mb-3 p-3">
                        <span>
                          {" "}
                          {intl.formatMessage({ id: "remainingAmount" })} :{" "}
                        </span>
                      </label>

                      <input
                        type="number"
                        style={{ width: "97%" }}
                        disabled={true}
                        className={`mb-3 form-control form-control-lg form-control-solid ms-3 ${Styles.enterammount}`}
                        name="account_name"
                        placeholder={intl.formatMessage({ id: "AMOUNT" })}
                        value={
                          fromAccount === "primaryAccount"
                            ? (amount * 97) / 100
                            : (amount * 99) / 100
                        }
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </span>
              </div>
            </div>

            {fromAccount === primaryAccount && (
              <div className=" fv-row ms-10">
                <label className="align-items-center form-label mb-3 ">
                  {intl.formatMessage({ id: "chooseAmmount" })}
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="Provide your team size to help us setup your billing"
                  ></i>
                </label>
                <div className="row mb-2" data-kt-buttons="true">
                  <div className="col">
                    <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                      <button
                        className="btn-check"
                        name="account_team_size"
                        onClick={(e: any) => {
                          setAmount(+e.target.value);
                          amountHandle(e.target.value);
                        }}
                        value="100"
                      />
                      <span className="fw-bold fs-3">100</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                      <button
                        className="btn-check"
                        name="account_team_size"
                        onClick={(e: any) => {
                          setAmount(+e.target.value);
                          amountHandle(e.target.value);
                        }}
                        value="500"
                      />
                      <span className="fw-bold fs-3">500</span>
                    </label>
                  </div>
                  <div className="col">
                    <label className="btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4">
                      <button
                        className="btn-check"
                        onClick={(e: any) => {
                          setAmount(+e.target.value);
                          amountHandle(e.target.value);
                        }}
                        name="account_team_size"
                        value="1000"
                      />
                      <span className="fw-bold fs-3">1000</span>
                    </label>
                  </div>
                  <div className="col">
                    <label
                      className="btn btn-outline btn-outline-dashed btn-active-light-primary p-4 "
                      style={{ width: "11.3rem" }}
                    >
                      <button
                        className="btn-check"
                        onClick={(e: any) => {
                          setAmount(+e.target.value);
                          amountHandle(e.target.value);
                        }}
                        name="account_team_size"
                        value="3000"
                      />
                      <span className="fw-bold fs-4">3000</span>
                    </label>
                  </div>
                </div>
              </div>
            )}

            <div className="">
              <button
                className="btn btn-warning col-lg-12 mt-10  "
                disabled={
                  !amount ||
                  !(amount > 0) ||
                  (fromAccount === primaryAccount && balancePrimary < amount) ||
                  (fromAccount === gsGoldAccount && balanceGsGold < amount)
                }
                onClick={() => {
                  goToNextStep();
                }}
              >
                {intl.formatMessage({ id: "continuebtn" })}
              </button>
            </div>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div
          className=""
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className="bg-white mt-10  ">
            <div className=" p-10 container col-lg-12 shadow-sm p-4 mb-5 bg-body-tertiary rounded bg-white">
              <h2 className="text-center">
                {intl.formatMessage({ id: "confirm" })}
              </h2>
              <p className="text-center mt-10 fs-3">
                {intl.formatMessage({
                  id: "pleaseReviewTheDetailSandConfirmYourExchange",
                })}
              </p>

              <div className="container col-lg-12">
                <div className="d-flex justify-content-between align-items-start mt-20">
                  <div className="">
                    {" "}
                    <span>{intl.formatMessage({ id: "from" })}</span>
                  </div>

                  <div className="">
                    {" "}
                    <span className="fw-bolder">
                      {fromAccount === "gsAccount"
                        ? typeof gsAccountL === "string"
                          ? gsAccountL
                          : gsAccountL.intlMessage
                        : typeof primaryAccountL === "string"
                        ? primaryAccountL
                        : primaryAccountL.intlMessage}
                    </span>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-start mt-10">
                  <div className="">
                    {" "}
                    <span>{intl.formatMessage({ id: "to" })}</span>
                  </div>

                  <div className="">
                    {" "}
                    <span className="fw-bolder">
                      {toAccount === "gsAccount"
                        ? typeof gsAccountL === "string"
                          ? gsAccountL
                          : gsAccountL.intlMessage
                        : typeof primaryAccountL === "string"
                        ? primaryAccountL
                        : primaryAccountL.intlMessage}
                    </span>
                    <br />
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-start mt-10">
                  <div className="">
                    {" "}
                    <span>{intl.formatMessage({ id: "amount1" })}</span>
                  </div>

                  <div className="">
                    {" "}
                    <span className="fw-bolder">
                      {fromAccount === "gsAccount"
                        ? intl.formatMessage({ id: "xau" })
                        : "$"}{" "}
                      {typeof amount === "string"
                        ? amount.replace(/\.?0+$/, "")
                        : amount}
                    </span>
                    <br />
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-start mt-10">
                  <div className="">
                    <span>
                      {" "}
                      {intl.formatMessage({ id: "transactionFees" })}
                    </span>
                  </div>

                  <div className="">
                    <span className="fw-bolder">
                      {fromAccount === "gsAccount"
                        ? intl.formatMessage({ id: "xau" })
                        : "$"}{" "}
                      {fromAccount === "primaryAccount"
                        ? (amount * 3) / 100
                        : (amount * 1) / 100}
                    </span>
                    <br />
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-start mt-10">
                  <div className="">
                    <span>{intl.formatMessage({ id: "remainingAmount" })}</span>
                  </div>

                  <div className="">
                    <span className="fw-bolder">
                      {fromAccount === "gsAccount"
                        ? intl.formatMessage({ id: "xau" })
                        : "$"}{" "}
                      {fromAccount === "primaryAccount"
                        ? (amount * 97) / 100
                        : (amount * 99) / 100}
                    </span>
                    <br />
                  </div>
                </div>

                {/* <div className="d-flex justify-content-between align-items-start mt-10">
                  <div className="">
                    {" "}
                    <span>
                      
                    {intl.formatMessage({ id: "transactionFees" })}
                    </span>
                  </div>

                  <div className="">
                    {" "}
                    <span className="fw-bolder"> {fromAccount === "gsAccount" ? intl.formatMessage({ id: 'xau' }) :"$"} {transactionFee.toFixed(2)}</span>
                    <br />
                  </div>
                </div> */}

                <div className="mt-10  d-flex justify-content-between">
                  {" "}
                  <button
                    className="btn btn-warning "
                    onClick={() => goToPreviousStep()}
                  >
                    {intl.formatMessage({ id: "cancelexchange" })}
                  </button>{" "}
                  <button
                    className="btn btn-warning "
                    disabled={isDisabled}
                    onClick={() => {
                      handlesubmit();
                    }}
                  >
                    {intl.formatMessage({ id: "finishexchange" })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
