/* eslint-disable jsx-a11y/img-redundant-alt */
// import React, { useContext } from 'react'
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Services from "../../../../../Services";
import Toster from "../../../../../Components/Toster";
import Spinner from "../../../../../Components/Spinner";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { useIntl } from "react-intl";

interface ToasterState {
  type: string;
  title: string;
  text: string;
  visiblity: string;
}
export default function FinalStep() {
  const intl = useIntl()
  let navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(true);
   const [isDisabled, setIsDisabled] = useState(true); ///set--true
   const [toggle, setToggle] = useState(true); ///set--true
  const [toaster, setToaster] = useState<ToasterState>({
    type: "",
    title: "",
    text: "",
    visiblity: "hide",
  });
  const [imageData, setImageData] = useState("");
  const [imageResponseData, setImageResponseData] = useState("");

  const IdentityVerifySubmit = async () => {
    setToggle(true)
    try {
      let data = {
        image: imageResponseData,
        isIdentityVerify:'2'
      };
      const response: any = await Services.Auth.UPDATE_IMAGE(data);

      if (response) {
        setIsLoaded(true);

        setTimeout(() => {
          navigate("/auth");
        }, 700);

        setToaster({
          type: "success",
          title: "Successfull",
          text: "Update identity successfully",
          visiblity: "show",
        });
      }
    } catch (error: any) {
      setTimeout(() => {
        setToaster((prevState) => ({
          ...prevState,
          visiblity: "hide",
        }));
      }, 1500);

      setToaster({
        type: "danger",
        title: "Error Occured",
        text: error?.response?.data?.message
          ? error?.response?.data?.message // setIsDisabled(false);ge
          : "An unknown error occured",
        visiblity: "show",
      });
    }
  };

  const handleChangeImageData = (e) => {
    const file = e.target.files[0];
    setImageData(file);
    setIsDisabled(false)
  };

  const ImageUpload = async () => {
    setIsDisabled(true)
    if (imageData) {
      const formData = new FormData();
      formData.append("image", imageData);
      try {
        const response: any = await Services.Auth.IMAGE_UPLOAD(formData);

        if (response) {
          setToggle(false)
  setIsDisabled(true)
          setImageResponseData(response.data.image);
          setIsLoaded(true);
          

          setTimeout(() => {
            setToaster((prevState) => ({
              ...prevState,
              visiblity: "hide",
            }));
          }, 500);
          // setTimeout(() => {
            setToaster({
              type: "success",
              title: "Successfull",
              text: "Upload Document successfully",
              visiblity: "show",
            });
          // }, 100);
        }
      } catch (error: any) {
        setTimeout(() => {
          setToaster((prevState) => ({
            ...prevState,
            visiblity: "hide",
          }));
        }, 1500);

        setToaster({
          type: "danger",
          title: "Error Occured",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : "An unknown error occured",
          visiblity: "show",
        });
      }
    }
  };
  return (
    <>
      <div>
        <Toster
          type={toaster.type}
          title={toaster.title}
          text={toaster.text}
          visiblity={toaster.visiblity}
        />
        {!isLoaded && <Spinner />}
        <div
          className=""
          data-aos="fade-left"
          data-aos-anchor="#example-anchor"
          data-aos-offset="500"
          data-aos-duration="500"
        >
          <div className="conatiner text-center ">
            <h1> {intl.formatMessage({id: 'confirmYourIdentity'})}</h1>
            <div className="container p-1 text-dark bg-opacity-25 col-12   flex-end rounded ">
              <p className="text-muted">
              {intl.formatMessage({id: 'yourIdentificationDocumentsWillHelpUsToValidateYourIdentityComplyWithRegulationsAndSecureYourAccount'})}
              </p>
            </div>
            <div className="container text-dark bg-opacity-25 col-12     rounded ">
              <div className="text-center align-items-start">
                <div className="text-start">{intl.formatMessage({id: 'howToConfirmMyIdentity'})}</div>
                <div className=" text-start mt-1 ">
                  <p>
                    <span className="">
                    {intl.formatMessage({id: '1takeASelfieByHoldingYourIdCardAndMostRecentStatement'})}{" "}
                    </span>
                  </p>
                  <p>
                  {intl.formatMessage({id: '2cardholderNameAndIDShouldMatchAndBeClearlyVisible'})}
                  </p>
                  <p>
                  {intl.formatMessage({id: '3yourIdentificationDocumentsShouldBeCloseToYourFace'})}
                  </p>
                  <p>
                  {intl.formatMessage({id: '4.uploadAScannedCopyOfYourBusinessRegistrationAndValidGovernmentID'})}
                  </p>
                </div>
              </div>
              <div>
                <div className="mt-2">{intl.formatMessage({id: 'hereIsAnExampleForSelfie'})} :</div>
              </div>
            </div>
            <div className="d-flex col-12 grid gap-5">
              <div className="container  p-6 text-dark bg-opacity-25  mt-5 flex-end rounded">
              <img
                 src={toAbsoluteUrl('/media/logos/idverifyexample.jpg')}
                 height={200} width={180}
                //  className="h-150 w-150" 
                 // Replace with the URL of your default profile image
                 alt="Default Profile Image"
                //  height={110}
                //  width={110}
                //  style={{ borderRadius: "50%" }}
               />
              </div>
              {/* <div className="container p-6 text-dark bg-opacity-25  mt-5 flex-end rounded ">
              <img
                 src={toAbsoluteUrl('/media/logos/identityimage1.jpeg')}
                 height={100} width={160}
                //  className="h-150 w-150" 
                 // Replace with the URL of your default profile image
                 alt="Default Profile Image"
                //  height={110}
                //  width={110}
                //  style={{ borderRadius: "50%" }}
               />
              </div> */}
            </div>
            <div className="d-flex justify-content-between grid gap-4 mt-5">
              {/* <span className="w-50 mb-5 fs-5 text-bold ">{intl.formatMessage({id: 'correct'})}</span> */}
              {/* <button
                className="btn btn-lg btn-primary w-50 mb-5  "
                type="submit"
              >
                Correct
              </button> */}
              {/* <span className="w-50 mb-5 fs-5 text-bold">{intl.formatMessage({id: 'incorrect'})}</span> */}
              {/* <button
                className="btn btn-lg btn-primary w-50 mb-5   "
                type="submit"
              >
                Incorrect
              </button> */}
            </div>

            <div className="col-12 text-warning-emphasis d-flex justify-content-between align-items-sm-center">
              <div className="col-9">
                <input
                  id="fileInput"
                  className="form-control "
                 
                  type="file"
                  placeholder= {intl.formatMessage({id: 'choosefile'})}
                  onChange={handleChangeImageData}
                />
              </div>
              <div className="col-3   ms-1 ">
                <button
                  className="btn btn-primary  "
                  disabled={isDisabled}
                  onClick={ImageUpload}
                >
                {intl.formatMessage({id: 'UPLOADBTN'})}
                </button>
              </div>
            </div>

            <div className="text-center  mt-10">
              <div className="text-center ">
                <button
                  type="submit"
                  id="kt_sign_up_submit"
                  className="btn btn-lg btn-primary w-100 mb-2"
                  onClick={IdentityVerifySubmit}
                  disabled={(toggle)}
                >
                {intl.formatMessage({id: 'confirmid'})}
                </button>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
