/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import Constant from "../Constant";
import api from "../api";

export default {
    GET_CARD_BY_ID: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Card.GET_CARD_BY_ID(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    }); 
  },
  GET_CARD: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          Constant.BASE_URL + api.Card.GET_CARD()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    }); 
  },
  ADD_CARD: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          Constant.BASE_URL + api.Card.ADD_CARD(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    }); 
  },
  UPDATE_CARD: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          Constant.BASE_URL + api.Card.UPDATE_CARD(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    }); 
  },

};
