/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import Services from '../../../../Services';
import Toster from '../../../../Components/Toster';
import { Link, useNavigate } from 'react-router-dom';

import { useIntl } from 'react-intl';


interface ToasterState {
    type: string;
    title: string;
    text: string;
    visiblity: string;
  }
export default function ForgotPasswordGS() {
  const intl= useIntl()
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isDisabled, setIsDisabled] = useState(true); ///set--true
    const exceptThisSymbols = ["E", "+", "-", " "];
    const [toaster, setToaster] = useState<ToasterState>({
        type: "",
        title: "",
        text: "",
        visiblity: "hide",
      });
    
      let navigate = useNavigate();
   

      const validateEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
      };
      const forgotEmail = async () => {
        let isValid = true;
        setEmailError("");
       
        if (email.trim() === "") {
          setIsDisabled(false);
          setEmailError("Required");
          isValid = false;
        } else if (!validateEmail(email.trim())) {
          setIsDisabled(false);
          setEmailError("Enter a valid email address");
          isValid = false;
        }
     
        const data = {
          email: email,
        };
        try {
          const response = await Services.Auth.FORGOT_PASSWORD(data);
          if (response) {
            
            setToaster({
              type: "success",
              title: "Successfull",
              text: "Sent password reset. Please check your email",
              visiblity: "show",
            });
            setTimeout(() => {
              navigate(`/`);
            }, 700);
          }
        } catch (error:any) {
          setTimeout(() => {
            setToaster((prevState) => ({
              ...prevState,
              visiblity: "hide",
            }));
          }, 1500);
          setToaster({
            type: "danger",
            title: "Error Occured",
            text: error?.response?.data?.message
              ? error.response.data.message
              : error?.message
              ? error.message
              : "An unknown error has occured, try again later",
            visiblity: "show",
          });
        }
      };
      const handleValidate = (e) =>{
        if (e.target.value.trim() === "") {
          setIsDisabled(true);
          setEmailError("Required")
        } else if (!validateEmail(email.trim())) {
          setIsDisabled(true);
          setEmailError("Enter a valid email address");
          
        } else {
          setIsDisabled(false);
          setEmail(e.target.value);
          setEmailError("")
        }
        setEmail(e.target.value);
      }
      const handleKey = (e)=>{
        exceptThisSymbols.includes(e.key) && e.preventDefault()
      }
  return (
    <div>
       <Toster
        type={toaster.type}
        title={toaster.title}
        text={toaster.text}
        visiblity={toaster.visiblity}
      />
      <style>
        {`
                body {
                    background-image: url('/assets/media/auth/bg4.jpg');
                }

                [data-bs-theme="dark"] body {
                    background-image: url('/assets/media/auth/bg4-dark.jpg');
                }
            `}
      </style>
      <div className="d-flex flex-column flex-root" id="kt_app_root">
      <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
                {/* <form
                  className="form w-100"
                  novalidate="novalidate"
                  id="kt_password_reset_form"
                  data-aos="zoom-out-down"
                  action="#"
                > */}
                  <div className="text-center mb-10">
                    {/* <img src={logoLogin} className="my-4" alt=""/> */}
                    <h1 className="text-dark fw-bolder mb-3"> {intl.formatMessage({id: 'forgotPassword?'})}</h1>

                    <div className="text-gray-500 fw-semibold fs-6">
                      Enter your email to reset your password.
                    </div>
                  </div>

                  <div className="fv-row mb-8">
                    <input
                      type="email"
                      placeholder={intl.formatMessage({id: 'email'})}
                      name="email"
                      autoComplete="off"
                      className="form-control bg-transparent p-6"
                     
                      onChange={handleValidate}
                      onKeyDown={
                       handleKey
                      }
                    />
                    {emailError&& (
                      <span className="position-absolute alert-danger mt-2 text-capitalize">
                        {emailError}
                      </span>
                    )}
                  </div>

                  <div className="d-flex flex-wrap justify-content-center mt-5 col-12">
                    <button
                      type="button"
                      id="kt_password_reset_submit"
                      className="btn btn-primary me-4 col-6"
                      onClick={() => forgotEmail()}
                      disabled={isDisabled}
                    >
                      <span
                        // type=""
                        // disabled={!email.isValid}
                        className="indicator-label"
                      >
                        Submit
                      </span>

                    
                    </button>
                    <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-secondary'
            // disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
                  
                  </div>
                {/* </form> */}
              </div>
      </div>
    </div>
  )
}
